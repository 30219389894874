import { createBrowserHistory } from 'history';
import { clearCurrentProject } from './routerFunctions';
import { locationParserForAmplitude } from './utils/historyToAnalitycsListener';

const history = createBrowserHistory();

history.listen(location => {
  let splitPathname = location.pathname.split('/');
  let rootPath = splitPathname[1];
  rootPath = rootPath === 'projects' && !!location.pathname.split('/')[2] ? 'currentProject' : rootPath;

  locationParserForAmplitude(splitPathname, location.pathname);

  if (
    rootPath === 'projects' ||
    rootPath === 'userslist' ||
    rootPath === 'system' ||
    rootPath === 'settings' ||
    rootPath === 'tagsettings' ||
    rootPath === 'calllists' ||
    rootPath === 'connectionslist'
  ) {
    if (!!localStorage['CURRENT_PROJECT_SHORT_NAME'] && localStorage['CURRENT_PROJECT_SHORT_NAME'] !== '') {
      clearCurrentProject();
    }
  }
});

export default history;
