var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ClientsApi, SessionsApi, MessagesApi, AdvancedStatsApi, SimpleStatsApi, OverviewStatsApi, AnalyticsTokenApi, FiltersApi, StateRoutesApi, ReportApi, LabelsApi, } from '../api/client';
import { axios as localAxios } from '../utils/pipes/functions';
import globalAxios from 'axios';
var ProjectStatsService = /** @class */ (function () {
    function ProjectStatsService(accountId, projectNam, axios) {
        var _this = this;
        if (axios === void 0) { axios = localAxios; }
        this.getOverviewCapacityTableXls = function (dateFrom1, dateTo1, dateFrom2, dateTo2, showUrls, timezone, language, options, botIds) { return __awaiter(_this, void 0, void 0, function () {
            var _a, data, headers;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getOverviewCapacityTableXlsx(this.accountId, dateFrom1, dateTo1, dateFrom2, dateTo2, this.projectShortName, botIds, showUrls, timezone, language, undefined, options)];
                    case 1:
                        _a = _b.sent(), data = _a.data, headers = _a.headers;
                        return [2 /*return*/, { data: data, headers: headers }];
                }
            });
        }); };
        this.getSessionsWithNewFilter = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.advancedStats.getMessageStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.createAnalyticsToken = function (projectShortName) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tokens.createAnalyticsToken(this.accountId, projectShortName || this.projectShortName)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getAnalyticsTokenByProjectShortName = function (projectShortName) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tokens.getAnalyticsTokenByProjectShortName(this.accountId, projectShortName || this.projectShortName)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.deleteAnalyticsTokenByProjectShortName = function (projectShortName) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tokens.deleteAnalyticsTokenByProjectShortName(this.accountId, projectShortName || this.projectShortName)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.getMessageWithNewFilters = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.advancedStats.getMessageStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getFilterSetId = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.filtersApi.createFilterSetId(this.accountId, filters, this.projectShortName)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getLogsByFilter2 = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.messages.getMessageDataByFilter(this.accountId, this.projectShortName, page, size, filtersDto)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getRoutesDataByFilter = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.routes.getStateRoutesDataByFilter(this.accountId, this.projectShortName, page, size, filtersDto)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getSessionsByFilter = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sessions.getSessionDataByFilter(this.accountId, this.projectShortName, page, size, undefined, filtersDto)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getSessionWithNewFilters = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.advancedStats.getSessionStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getSessionLabelWithNewFilters = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.advancedStats.getSessionLabelStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getLogLabelWithNewFilters = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.advancedStats.getLogLabelStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getSwitchedSessionCountByDay = function (dateFrom, dateTo, transferToOperatorMethod, botIds) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.simpleStats.getSwitchedSessionCountByDay(this.accountId, dateFrom, dateTo, transferToOperatorMethod, this.projectShortName, botIds)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getOverviewClientCapacityByInterval = function (dateFrom, dateTo, interval, botIds) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getOverviewClientCapacityByInterval(this.accountId, dateFrom, dateTo, interval, this.projectShortName, botIds)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getOverviewMessagesCapacityByInterval = function (dateFrom, dateTo, interval, botIds) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getOverviewMessagesCapacityByInterval(this.accountId, dateFrom, dateTo, interval, this.projectShortName, botIds)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getOverviewDialogsCapacityByInterval = function (dateFrom, dateTo, interval, botIds) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getOverviewSessionsCapacityByInterval(this.accountId, dateFrom, dateTo, interval, this.projectShortName, botIds)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getClientByFilter = function (page, size, filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.clients.getClientDataByFilter(this.accountId, this.projectShortName, page, size, filtersDto)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.createClientReport = function (filtersDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.report.createClientReport(this.accountId, this.projectShortName, undefined, undefined, filtersDto)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.createSessionReport = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.report.createSessionReport(this.accountId, this.projectShortName, options.language, options.timezone, options.createReportDto, options.options)];
                    case 1:
                        payload = _a.sent();
                        return [2 /*return*/, payload];
                }
            });
        }); };
        this.getMessagePageInSession = function (sessionId, questionId, botId) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.messages.getMessagePageInSession(this.accountId, sessionId, questionId, undefined, this.projectShortName, botId)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getMessagesForSession = function (sessionId, page, responseDataJsonPath, botId, startTime, hideEmptyMessages, options) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.messages.getMessagesForSession(this.accountId, sessionId, page, responseDataJsonPath, undefined, this.projectShortName, botId, startTime, hideEmptyMessages, options)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getMessagesForClient = function (botId, clientId, logCount, startTime, sort, page, channelType, options) {
            return _this.messages
                .getMessagesForClient(_this.accountId, botId, clientId, page, logCount, _this.projectShortName, startTime, undefined, sort, undefined, channelType, options)
                .then(function (_a) {
                var data = _a.data;
                return data;
            })
                .catch(function (reason) {
                if (!globalAxios.isCancel(reason))
                    return reason;
            });
        };
        this.blockClient = function (clientId, botId, blockClientDto) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.clients.blockClient(this.accountId, botId, clientId, blockClientDto)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.unblockClient = function (clientId, botId) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.clients.unblockClient(this.accountId, botId, clientId)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getTelephonyReport = function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var payload;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.report.createTelephonyReport(this.accountId, this.projectShortName, options.startTime, options.endTime, options.botIds, options.language, options.timezone)];
                        case 1:
                            payload = _a.sent();
                            return [2 /*return*/, payload];
                    }
                });
            });
        };
        this.createMessageReport = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.report.createMessageReport(this.accountId, this.projectShortName, options.language, options.timezone, options.createReportDto, options.options)];
                    case 1:
                        payload = _a.sent();
                        return [2 /*return*/, payload];
                }
            });
        }); };
        this.createRouteReport = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.report.createStateRoutesReport(this.accountId, this.projectShortName, options.language, options.timezone, options.createReportDto, options.options)];
                    case 1:
                        payload = _a.sent();
                        return [2 /*return*/, payload];
                }
            });
        }); };
        this.deleteReportGenerationTask = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.report.deleteReportGenerationTask(this.accountId, options.reportGenerationTaskId, options.options)];
                    case 1:
                        payload = _a.sent();
                        return [2 /*return*/, payload];
                }
            });
        }); };
        this.deleteCompletedReportGenerationTasks = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.report.deleteCompletedReportGenerationTasks(this.accountId, options)];
                    case 1:
                        payload = _a.sent();
                        return [2 /*return*/, payload];
                }
            });
        }); };
        this.getSessionLabelsStats = function (filters) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.advancedStats.getSessionLabelStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getTelephonyStats = function (dateFrom, dateTo, botIds) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.simpleStats.getTelephonyStats(this.accountId, dateFrom, dateTo, this.projectShortName, botIds)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getOverviewCapacityTable = function (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getOverviewCapacityTable(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getResultsGraphData = function (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds, locale, options) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getSessionsResultByInterval(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds, undefined, locale, options)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getSessionsResultTable = function (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds, locale, options) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getSessionsResultByIntervalTable(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds, undefined, locale, options)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        this.getSessionsResultByIntervalTableXlsx = function (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds, locale, options) { return __awaiter(_this, void 0, void 0, function () {
            var _a, data, headers;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.overviewStats.getSessionsResultByIntervalTableXlsx(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds, undefined, locale, options)];
                    case 1:
                        _a = _b.sent(), data = _a.data, headers = _a.headers;
                        return [2 /*return*/, {
                                data: data,
                                headers: headers,
                            }];
                }
            });
        }); };
        this.accountId = accountId;
        this.projectShortName = projectNam;
        this.simpleStats = new SimpleStatsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.advancedStats = new AdvancedStatsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.overviewStats = new OverviewStatsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.clients = new ClientsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.messages = new MessagesApi({}, ProjectStatsService.BASE_PATH, axios);
        this.sessions = new SessionsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.routes = new StateRoutesApi({}, ProjectStatsService.BASE_PATH, axios);
        this.report = new ReportApi({}, ProjectStatsService.BASE_PATH, axios);
        this.tokens = new AnalyticsTokenApi({}, ProjectStatsService.BASE_PATH, axios);
        this.filtersApi = new FiltersApi({}, ProjectStatsService.BASE_PATH, axios);
    }
    ProjectStatsService.BASE_PATH = '';
    return ProjectStatsService;
}());
export { ProjectStatsService };
var LabelsService = /** @class */ (function () {
    function LabelsService(accountId, projectNam, axios) {
        var _this = this;
        if (axios === void 0) { axios = localAxios; }
        this.setLogLabels = function (questionId, sessionId, labels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.labels.setMessageLabels(this.accountId, questionId, sessionId, labels);
                return [2 /*return*/];
            });
        }); };
        this.setSessionLabels = function (questionId, labels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.labels.setSessionLabels(this.accountId, questionId, labels);
                return [2 /*return*/];
            });
        }); };
        this.accountId = accountId;
        this.projectShortName = projectNam;
        this.labels = new LabelsApi({}, ProjectStatsService.BASE_PATH, axios);
    }
    LabelsService.BASE_PATH = '';
    return LabelsService;
}());
export { LabelsService };
