import React, { FC, useCallback, useMemo } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import classNames from 'classnames';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui';

import { t, tWithCheck } from 'localization';

import {
  ReactionsTagNames,
  TActivationTagNames,
  TagNames,
  TJBlock,
  TReactionsTagNames,
  TTagParameters,
} from 'modules/JGraph/utils/types';
import { CustomTagsStore$ } from 'reducers/JGraph.reducer/customTags.store';
import { tagParametersToObj } from 'reducers/JGraph.reducer/utils';
import { useCopyToClipboardBlock } from 'utils/hooks/CopyToClipboardFeature';
import { session } from 'modules/JGraph/utils/insertSessionFunction';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';

import { mapTypeToIcon } from '../../../parts/Activations';
import { AddingMenuType } from '../../hooks/EventsHook';
import { KIconsPaths } from '../../../parts/KIcons';
import { JGraphProviderSubject$ } from '../../../../contexts/JGraphContext';
import { useRightSideMenuContext } from '../../index';
import classes from './ReactionItem.module.scss';

export const mapIconToTagName: Record<TReactionsTagNames, string> = {
  [TagNames.a]: 'faText',
  [TagNames.image]: 'farImage',
  [TagNames.video]: 'farFilm',
  [TagNames.audio]: 'farMusic',
  [TagNames.random]: 'faRandom',
  [TagNames.buttons]: 'falHandPointer',
  [TagNames.inlineButtons]: 'falHandPointer',
  [TagNames.go]: 'faArrowRight',
  [TagNames.go_]: 'faArrowRight',
  [TagNames.script]: 'falBracketsCurly',
  [TagNames.if]: 'farBullseyeArrow',
  [TagNames.Email]: 'farEnvelope',
  [TagNames.HttpRequest]: 'farExchangeAlt',
};

export const getBlockToTextValue = (block: ReactionItemProps['block']) => {
  if (!block) return '';
  const params: Record<string, any> = {};
  block.tagParameters.forEach(tagParam => {
    params[tagParam.name] = tagParam.value;
  });
  switch (block.tagName) {
    case TagNames.a:
    case TagNames.script:
    case TagNames.go:
    case TagNames.go_:
    case TagNames.image:
      return block.tagValue;
    case TagNames.buttons:
      return block.tagParameters.map(param => param.name).join(', ');
    case TagNames.Email:
      return params['text'];
    case TagNames.HttpRequest:
      return params['url'];
    default:
      return '';
  }
};

export const DragHandler = SortableHandle(({ className }: { className?: string }) => {
  return (
    <div className={classNames('handler', className || '')} data-test-id='DragHandler'>
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13 14.5C13.8281 14.5 14.5 15.1719 14.5 16C14.5 16.8281 13.8281 17.5 13 17.5C12.1719 17.5 11.5 16.8281 11.5 16C11.5 15.1719 12.1719 14.5 13 14.5ZM11.5 11.25C11.5 12.0781 12.1719 12.75 13 12.75C13.8281 12.75 14.5 12.0781 14.5 11.25C14.5 10.4219 13.8281 9.75 13 9.75C12.1719 9.75 11.5 10.4219 11.5 11.25ZM11.5 20.75C11.5 21.5781 12.1719 22.25 13 22.25C13.8281 22.25 14.5 21.5781 14.5 20.75C14.5 19.9219 13.8281 19.25 13 19.25C12.1719 19.25 11.5 19.9219 11.5 20.75Z'
          fill='currentColor'
        />
        <path
          d='M19 14.5C19.8281 14.5 20.5 15.1719 20.5 16C20.5 16.8281 19.8281 17.5 19 17.5C18.1719 17.5 17.5 16.8281 17.5 16C17.5 15.1719 18.1719 14.5 19 14.5ZM17.5 11.25C17.5 12.0781 18.1719 12.75 19 12.75C19.8281 12.75 20.5 12.0781 20.5 11.25C20.5 10.4219 19.8281 9.75 19 9.75C18.1719 9.75 17.5 10.4219 17.5 11.25ZM17.5 20.75C17.5 21.5781 18.1719 22.25 19 22.25C19.8281 22.25 20.5 21.5781 20.5 20.75C20.5 19.9219 19.8281 19.25 19 19.25C18.1719 19.25 17.5 19.9219 17.5 20.75Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
});

export const buildIcon = (blockTagName: TActivationTagNames | 'systemIntent', className?: string) => {
  const paths = KIconsPaths[mapTypeToIcon[blockTagName]];
  if (paths) {
    return (
      <svg width={16} height={16} viewBox='0 0 16 16' className={className}>
        {paths.map(path => (
          <path key={`path-${path.path}`} d={path.path} fill={path.color} />
        ))}
      </svg>
    );
  }
  return null;
};

const ReactionItemValue: FC<{ block: ReactionItemProps['block'] }> = ({ block }) => {
  if (block) {
    switch (block.tagName) {
      case TagNames.intent:
      case TagNames.intent_:
        return (
          <div className={classes.value}>
            {tWithCheck(`ChooseReadyIntent ${block.tagValue}`) || hideRootSlashInPath(block.tagValue || '')}
          </div>
        );
      case TagNames.script:
      case TagNames.a:
        const tagParameters = tagParametersToObj(block.tagParameters);
        const text = block.tagValue?.trim();
        const ttsText = tagParameters['tts']?.value;
        const value = text || ttsText || '';
        return <div className={classes.value}>{value}</div>;
      case TagNames.random:
        return (
          <div>
            {block.jblocks.map(innerBlock => {
              const text = `${t(`ReactionItem ${innerBlock.tagName}`)}: ${getBlockToTextValue(innerBlock)}`;
              return (
                <div key={`tagName-${innerBlock.tagName}-${text}`} className={classes.value}>
                  {text}
                </div>
              );
            })}
          </div>
        );
      case TagNames.buttons:
      case TagNames.inlineButtons:
        return (
          <>
            {block.tagParameters.map(tagParam => (
              <div className={classes.value} key={`${tagParam.name}`}>
                {tagParam.name}{' '}
                <svg width='10' height='4' viewBox='0 0 10 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M7.148 3.3C7.268 3.044 7.384 2.82 7.496 2.628C7.616 2.436 7.732 2.276 7.844 2.148H0.848V1.644H7.844C7.732 1.508 7.616 1.344 7.496 1.152C7.384 0.96 7.268 0.74 7.148 0.492H7.568C8.072 1.076 8.6 1.508 9.152 1.788V2.004C8.6 2.276 8.072 2.708 7.568 3.3H7.148Z'
                    fill='#6A7178'
                  />
                </svg>{' '}
                {tagParam.transition || tagParam.url}
              </div>
            ))}
          </>
        );
      default:
        if (
          CustomTagsStore$.getValue()
            .map(value => value.tagName)
            .includes(block.tagName)
        ) {
          return (
            <>
              {(block.tagParameters as TTagParameters[])
                .filter(tagParam => tagParam.required)
                .map(tagParam => {
                  return (
                    <div
                      className={classes.value}
                      key={`${tagParam.name}`}
                      title={tWithCheck(`CustomTag:${block.tagName}:param:${tagParam.name}`) || tagParam.name}
                    >
                      <b>{tWithCheck(`CustomTag:${block.tagName}:param:${tagParam.name}`) || tagParam.name}</b>{' '}
                      <svg width='10' height='4' viewBox='0 0 10 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M7.148 3.3C7.268 3.044 7.384 2.82 7.496 2.628C7.616 2.436 7.732 2.276 7.844 2.148H0.848V1.644H7.844C7.732 1.508 7.616 1.344 7.496 1.152C7.384 0.96 7.268 0.74 7.148 0.492H7.568C8.072 1.076 8.6 1.508 9.152 1.788V2.004C8.6 2.276 8.072 2.708 7.568 3.3H7.148Z'
                          fill='#6A7178'
                        />
                      </svg>{' '}
                      <span>
                        {typeof tagParam.value === 'object'
                          ? JSON.stringify(tagParam.value)
                          : session.parse(String(tagParam.value))}
                      </span>
                    </div>
                  );
                })}
            </>
          );
        }
        return (
          <div className={classes.value}>
            {tWithCheck(`ReactionItemValue:${block.tagValue}`) || hideRootSlashInPath(block.tagValue || '')}
          </div>
        );
    }
  }
  return <div className={classes.value}>{t('ReactionItemValue:EnterValue')}</div>;
};

export type ReactionItemProps = {
  parentBlocks?: TJBlock[];
  block?: TJBlock;
  blockIndex?: number;
  addingType?: AddingMenuType;
  withDrag?: boolean;
  onClick?: () => unknown;
  setOpenDelete?: (index: number | undefined) => unknown;
  disableDelete?: boolean;
  disabled?: boolean;
};
export const ReactionItem: FC<ReactionItemProps> = ({
  block,
  blockIndex,
  addingType,
  withDrag,
  onClick,
  setOpenDelete,
  parentBlocks,
  disableDelete = false,
  disabled,
}) => {
  const { setAddingMenuType } = useRightSideMenuContext();
  const setOpenDeleteHandler = useCallback(() => {
    if (setOpenDelete) {
      setOpenDelete(blockIndex);
    }
  }, [blockIndex, setOpenDelete]);
  const copyBlockHandler = useCopyToClipboardBlock(parentBlocks, '', blockIndex);

  const isSystemIntent = useMemo(() => {
    if (!block) return false;
    if (!block.tagValue) return false;
    const JGContextValue = JGraphProviderSubject$.getValue();
    return JGContextValue.IntentsService.isSystemIntent(JGContextValue.currentBotLanguage, block.tagValue);
  }, [block]);

  if (block) {
    const isReactionIcon = ReactionsTagNames().includes(block.tagName as TReactionsTagNames);

    return (
      <div className={classes.ReactionItemContainer}>
        <div
          className={classNames(classes.ReactionItem, { [classes.active]: !disabled && onClick })}
          onClick={disabled ? () => {} : onClick}
          data-test-id={`ReactionItem:${block.tagName}`}
        >
          {withDrag && !disabled && <DragHandler className={classes.handler} />}
          <div className={classes.reactionIconContainer}>
            {isReactionIcon && (
              <Icon
                color='secondary'
                size='lg'
                className={classNames(classes.reactionIcon, classes[block.tagName] || '', {
                  [classes.violet]: !mapIconToTagName[block.tagName as TReactionsTagNames],
                })}
                name={mapIconToTagName[block.tagName as TReactionsTagNames] || 'farCube'}
              />
            )}
            {!isReactionIcon &&
              buildIcon(isSystemIntent ? 'systemIntent' : (block.tagName as TActivationTagNames), classes.reactionIcon)}
          </div>

          <div className={classes.nameAndValueContainer}>
            <div className={classes.name}>
              {tWithCheck(`CustomTag:${block.tagName}:caption`) ||
                tWithCheck(`${isReactionIcon ? 'ReactionItem' : 'ActivationItem'} ${block.tagName}`) ||
                hideRootSlashInPath(block.tagName)}
            </div>
            <ReactionItemValue block={block} />
          </div>
          {disabled || disableDelete ? null : (
            <DropdownButton
              className={classes.noBorder}
              direction='down'
              flat
              color='none'
              onClick={e => e.stopPropagation()}
            >
              <DropdownToggle outline iconButton>
                <Icon className='btn-icon' name='farEllipsisH' data-test-id='ReactionItem:dropdownToggle' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={copyBlockHandler} data-test-id='RightSideMenu:dropdownMenu:copy'>
                  {t('RightSideMenu:blockActions:Copy')}
                </DropdownItem>
                {/*<DropdownItem>{t('RightSideMenu:blockActions_duplicate')}</DropdownItem>*/}
                <DropdownItem danger onClick={setOpenDeleteHandler} data-test-id='ReactionItem:openDeleteModal'>
                  {t('RightSideMenu:blockActions_delete')}
                </DropdownItem>
              </DropdownMenu>
            </DropdownButton>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(classes.ReactionItem, classes.last)} onClick={() => setAddingMenuType(addingType)}>
      <Icon color='secondary' name='farPlus' />
      <div className={classes.name}>{t(`ReactionItem:add_${addingType}`)}</div>
    </div>
  );
};
