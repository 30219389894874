var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useCallback } from 'react';
import { Form, Modal as RsModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Spinner } from '../index';
import './Modal-fix.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import classNames from 'classnames';
export const Modal = (_a) => {
    var { isOpen, children, className, title, titleIcon, buttonCancelText = 'Отмена', buttonCancelColor = 'primary', buttonCancelSize = 'md', buttonCancelOutline = true, buttonCancelTestId = '', buttonCancelDisabled = false, buttonSubmitText = 'Сохранить', buttonSubmitColor = 'primary', buttonSubmitSize = 'md', buttonSubmitOutline = false, buttonSubmitTestId = false, buttonSubmitType = 'submit', onActionClick, onCancelClick, disableActionButtonAutoFocus = false, buttonSubmitDisabled = false, disableKeyboard = false, size = 'md', headerTag = 'h5', 'data-test-id': dataTestId = '', titleTestId = '', bodyTestId = '', footerTestId = '', noValidate = false, formClassName = '', customFooter, FixedBannerTop, FixedBannerBottom, dark, naturalScrollbar, zIndex, onClickCloseIcon, inProgress, centered, modalAutoFocus, calcBodyHeight, onOpened } = _a, restProps = __rest(_a, ["isOpen", "children", "className", "title", "titleIcon", "buttonCancelText", "buttonCancelColor", "buttonCancelSize", "buttonCancelOutline", "buttonCancelTestId", "buttonCancelDisabled", "buttonSubmitText", "buttonSubmitColor", "buttonSubmitSize", "buttonSubmitOutline", "buttonSubmitTestId", "buttonSubmitType", "onActionClick", "onCancelClick", "disableActionButtonAutoFocus", "buttonSubmitDisabled", "disableKeyboard", "size", "headerTag", 'data-test-id', "titleTestId", "bodyTestId", "footerTestId", "noValidate", "formClassName", "customFooter", "FixedBannerTop", "FixedBannerBottom", "dark", "naturalScrollbar", "zIndex", "onClickCloseIcon", "inProgress", "centered", "modalAutoFocus", "calcBodyHeight", "onOpened"]);
    const header = useRef(null);
    const container = useRef(null);
    const footer = useRef(null);
    const setHeight = useCallback(() => {
        let headerHeight = 0;
        let footerHeight = 0;
        if (header.current) {
            headerHeight = header.current.clientHeight;
        }
        if (footer.current) {
            footerHeight = footer.current.clientHeight;
        }
        let height = calcBodyHeight
            ? calcBodyHeight(headerHeight, footerHeight)
            : `calc( 100vh - 3.5rem - ${headerHeight}px - ${footerHeight}px)`;
        if (container.current && container.current.parentNode) {
            const current = container.current;
            current.style.maxHeight = height;
            if (current.children[0]) {
                current.children[0].style.maxHeight = height;
            }
        }
    }, [calcBodyHeight]);
    const onOpenedHandle = useCallback(() => {
        setHeight();
        onOpened === null || onOpened === void 0 ? void 0 : onOpened();
    }, [onOpened, setHeight]);
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onActionClick) {
            onActionClick(e);
        }
    };
    return (React.createElement(RsModal, Object.assign({ autoFocus: modalAutoFocus, keyboard: !disableKeyboard, isOpen: isOpen, className: classNames(className, 'just-ui-modal', { 'dark-theme': dark }), toggle: onClickCloseIcon || onCancelClick, onOpened: onOpenedHandle, size: size, "data-test-id": dataTestId, zIndex: zIndex, centered: centered }, restProps),
        title && (React.createElement(ModalHeader, { wrapTag: (_a) => {
                var { children } = _a, restProps = __rest(_a, ["children"]);
                return (React.createElement("div", Object.assign({ ref: header }, restProps, { "data-test-id": titleTestId }), children));
            }, tag: headerTag, toggle: onClickCloseIcon ? onClickCloseIcon : onCancelClick },
            titleIcon,
            title)),
        React.createElement(Form, { onSubmit: onSubmit, className: formClassName, noValidate: noValidate },
            React.createElement(ModalBody, { "data-test-id": bodyTestId },
                inProgress && React.createElement(Spinner, { size: '4x' }),
                FixedBannerTop && (React.createElement("div", { className: 'fixed-banner-top-container' },
                    React.createElement(FixedBannerTop, null))),
                React.createElement("div", { className: `modal-body-container ${naturalScrollbar ? 'natural-scrollbar' : ''}`, ref: container }, naturalScrollbar ? (children) : (React.createElement(PerfectScrollbar, { options: { maxScrollbarLength: 62 } }, children))),
                FixedBannerBottom ? (React.createElement("div", { className: 'fixed-banner-bottom-container' },
                    React.createElement(FixedBannerBottom, null))) : null),
            React.createElement(ModalFooter, { tag: (_a) => {
                    var { children } = _a, restProps = __rest(_a, ["children"]);
                    return (React.createElement("div", Object.assign({ ref: footer }, restProps, { "data-test-id": footerTestId }), children));
                } },
                customFooter,
                onActionClick || onCancelClick ? (React.createElement("div", { className: 'modal-base-buttons' },
                    onCancelClick ? (React.createElement(Button, { size: buttonCancelSize, color: buttonCancelColor, outline: buttonCancelOutline, onClick: onCancelClick, "data-test-id": buttonCancelTestId, disabled: buttonCancelDisabled, type: 'button' }, buttonCancelText)) : null,
                    onActionClick ? (React.createElement(Button, { size: buttonSubmitSize, color: buttonSubmitColor, disabled: buttonSubmitDisabled, outline: buttonSubmitOutline, autoFocus: !disableActionButtonAutoFocus, "data-test-id": buttonSubmitTestId, type: buttonSubmitType }, buttonSubmitText)) : null)) : null))));
};
// export default Modal;
