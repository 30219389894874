export var analyticsLocalization = {
    'Analytics: All': {
        eng: 'All',
        ru: 'Все',
        pt: 'Tudo',
        cn: '所有',
    },
    'Analytics: Additional Information': {
        eng: 'Additional Information',
        ru: 'Дополнительная информация',
        pt: 'Informações adicionais',
        cn: '附加信息',
    },
    'Analytics: Channel': {
        eng: 'Channels',
        ru: 'Каналы',
        pt: 'Canais',
        cn: '通道',
    },
    'Analytics: Sessions': {
        eng: 'Sessions',
        ru: 'Сессии',
        pt: 'Sessões',
        cn: '连接',
    },
    'Analytics: Messages': {
        eng: 'Messages',
        ru: 'Сообщения',
        pt: 'Mensagens',
        cn: '信息',
    },
    'Analytics: from': {
        eng: 'from',
        ru: 'от',
        pt: 'de',
        cn: '从',
    },
    'Analytics: to': {
        eng: 'to',
        ru: 'до',
        pt: 'para',
        cn: '到',
    },
    'Analytics: Cancel': {
        eng: 'Cancel',
        ru: 'Отмена',
        pt: 'Cancelar',
        cn: '取消',
    },
    'Analytics: Confirm': {
        eng: 'Confirm',
        ru: 'Подтвердить',
        pt: 'Confirmar',
        cn: '确认',
    },
    'Analytics: Filters': {
        eng: 'Filters',
        ru: 'Фильтры',
        pt: 'Filtros',
        cn: 'Filters',
    },
    'Analytics: sessions': {
        eng: '$[1] sessions',
        ru: '$[1] сессий',
        pt: '$[1] sessões',
        cn: '$[1] sessions',
    },
    'Analytics: one session': {
        eng: '$[1] session',
        ru: '$[1] сессия',
        pt: '$[1] sessão',
        cn: '$[1] session',
    },
    'Analytics: two sessions': {
        eng: '$[1] sessions',
        ru: '$[1] сессии',
        pt: '$[1] sessões',
        cn: '$[1] sessions',
    },
    'Analytics: messages': {
        eng: '$[1] phrases',
        ru: '$[1] фраз',
        pt: '$[1] frases',
        cn: '$[1] phrases',
    },
    'Analytics: one message': {
        eng: '$[1] phrase',
        ru: '$[1] фраза',
        pt: '$[1] frase',
        cn: '$[1] phrase',
    },
    'Analytics: two messages': {
        eng: '$[1] phrases',
        ru: '$[1] фразы',
        pt: '$[1] frases',
        cn: '$[1] phrases',
    },
    'Analytics: clients': {
        eng: '$[1] clients',
        ru: '$[1] клиентов',
        pt: '$[1] clientes',
        cn: '$[1] clients',
    },
    'Analytics: one client': {
        eng: '$[1] client',
        ru: '$[1] клиент',
        pt: '$[1] cliente',
        cn: '$[1] client',
    },
    'Analytics: two clients': {
        eng: '$[1] clients',
        ru: '$[1] клиента',
        pt: '$[1] clientes',
        cn: '$[1] clients',
    },
    'Analytics: routes': {
        eng: '$[1] routes',
        ru: '$[1] маршрутов',
        pt: '$[1] rotas',
        cn: '$[1] routes',
    },
    'Analytics: one route': {
        eng: '$[1] route',
        ru: '$[1] маршрут',
        pt: '$[1] rota',
        cn: '$[1] route',
    },
    'Analytics: two routes': {
        eng: '$[1] routes',
        ru: '$[1] маршрутa',
        pt: '$[1] rotas',
        cn: '$[1] routes',
    },
    'Analytics: states': {
        eng: '$[1] states',
        ru: '$[1] стейтов',
        pt: '$[1] estados',
        cn: '$[1] states',
    },
    'Analytics: one state': {
        eng: '$[1] state',
        ru: '$[1] стейт',
        pt: '$[1] estado',
        cn: '$[1] state',
    },
    'Analytics: two states': {
        eng: '$[1] states',
        ru: '$[1] стейтa',
        pt: '$[1] estados',
        cn: '$[1] states',
    },
    'Analytics: found': {
        eng: 'Found: $[1]',
        ru: 'Найдено: $[1]',
        pt: 'Encontrado: $[1]',
        cn: 'Found: $[1]',
    },
    'Analytics: found from': {
        eng: '$[1]–$[2] of $[3] shown',
        ru: 'Показано $[1]­–$[2] из $[3]',
        pt: '$[1]–$[2] de $[3] mostrados',
        cn: '$[1]–$[2] of $[3] shown',
    },
    'Analytics: Session Log': {
        eng: 'Download log',
        ru: 'Скачать лог',
        pt: 'Registro de downloads',
        cn: 'Download log',
    },
    'Analytics: download report': {
        eng: 'Download report',
        ru: 'Скачать отчет',
        pt: 'Baixar relatório',
        cn: 'Download report',
    },
    'Analytics: Show': {
        eng: 'Display',
        ru: 'Показать',
        pt: 'Mostrar',
        cn: 'Display',
    },
    'Analytics: To the page': {
        eng: 'To page',
        ru: 'К странице',
        pt: 'Para a página',
        cn: 'To page',
    },
    'Analytics: sessions tab': {
        eng: 'Sessions',
        ru: 'Сессии',
        pt: 'Sessões',
        cn: '会话',
    },
    'Analytics: messages tab': {
        eng: 'Phrases',
        ru: 'Фразы',
        pt: 'Frases',
        cn: '短语',
    },
    'Analytics: diagrams tab': {
        eng: 'Graphics',
        ru: 'Графики',
        pt: 'Gráficos',
        cn: '图形',
    },
    'Analytics: download reports clients': {
        eng: 'Download log',
        ru: 'Скачать лог',
        pt: 'Registro de downloads',
        cn: 'Download log',
    },
    'Analytics: download reports messages': {
        eng: 'Message log',
        ru: 'Лог сообщений',
        pt: 'Registro de mensagens',
        cn: 'Message log',
    },
    'Analytics: download reports sessions': {
        eng: 'Session log',
        ru: 'Лог сессий',
        pt: 'Registro de sessões',
        cn: 'Session log',
    },
    'Analytics: download reports routes': {
        eng: 'Route log',
        ru: 'Лог маршрутов',
        pt: 'Registro de rotas',
        cn: 'Route log',
    },
    'Analytics: sessionsTable head start': {
        eng: 'Start',
        ru: 'Начало',
        pt: 'Iniciar',
        cn: 'Start',
    },
    'Analytics: sessionsTable head bot': {
        eng: 'Bot',
        ru: 'Бот',
        pt: 'Bot',
        cn: 'Bot',
    },
    'Analytics: sessionsTable head sessionData': {
        eng: 'sessionData',
        ru: 'sessionData',
    },
    'Analytics: sessionsTable head userId': {
        eng: 'User ID',
        ru: 'ID пользователя',
        pt: 'ID de usuário',
        cn: 'User ID',
    },
    'Analytics: sessionsTable head callType': {
        eng: 'Call type',
        ru: 'Тип звонка',
        pt: 'Tipo de chamada',
        cn: 'Call type',
    },
    'Analytics: sessionsTable head lastPhrase': {
        eng: 'Last phrase',
        ru: 'Последняя фраза',
        pt: 'Última frase',
        cn: 'Last phrase',
    },
    'Analytics: sessionsTable head duration': {
        eng: 'Duration',
        ru: 'Длительность',
        pt: 'Duração',
        cn: 'Duration',
    },
    'Analytics: sessionsTable head phraseCount': {
        eng: 'Number of phrases',
        ru: 'Число фраз',
        pt: 'Número de frases',
        cn: 'Number of phrases',
    },
    'Analytics: sessionsTable head Mailing results': {
        eng: 'Call results',
        ru: 'Результаты звонка',
        pt: 'Resultados de chamada',
        cn: '呼叫结果',
    },
    'Analytics: sessionsTable head Session result': {
        eng: 'Session result',
        ru: 'Результат сессии',
        pt: 'Resultado de sessão',
        cn: 'Session result',
    },
    'Analytics: No dialogs found': {
        eng: 'No sessions found',
        ru: 'Сессий не найдено',
        pt: 'Nenhuma sessão encontrada',
        cn: '未找到对话',
    },
    'Analytics: messages stateLabel': {
        eng: 'State:',
        ru: 'Стейт:',
        pt: 'Estado:',
        cn: 'State:',
    },
    'Analytics: messages classLabel': {
        eng: 'Class:',
        ru: 'Класс:',
        pt: 'Classe:',
        cn: 'Class:',
    },
    'Analytics: messages client label': {
        eng: 'Client:',
        ru: 'Клиент:',
        pt: 'Cliente:',
        cn: 'Client:',
    },
    'Analytics: messages bot label': {
        eng: 'Bot:',
        ru: 'Бот:',
        pt: 'Bot:',
        cn: 'Bot:',
    },
    'Analytics: no messages founded': {
        eng: 'In $[1] ms',
        ru: 'За $[1] мс',
        pt: 'Em $[1] ms',
        cn: 'In $[1] ms',
    },
    'Analytics: messages time and processing time': {
        eng: 'in $[1] ms',
        ru: 'за $[1] мс',
        pt: 'em $[1] ms',
        cn: 'in $[1] ms',
    },
    'Analytics: messages view settings': {
        eng: 'View settings',
        ru: 'Настройка вида',
        pt: 'Exibir configurações',
        cn: 'View settings',
    },
    'Analytics: messages view settings show answers': {
        eng: 'Bot replies',
        ru: 'Ответы бота',
        pt: 'Respostas de bot',
        cn: 'Bot replies',
    },
    'Analytics: messages view settings show markup': {
        eng: 'Annotation',
        ru: 'Разметка',
        pt: 'Anotação',
        cn: 'Annotation',
    },
    'Analytics: messages compact view': {
        eng: 'Compact view',
        ru: 'Компактный режим',
        pt: 'Exibição compacta',
        cn: 'Compact view',
    },
    'Analytics: messages view settings show additional action': {
        eng: 'More actions',
        ru: 'Дополнительные действия',
        pt: 'Mais ações',
        cn: 'More actions',
    },
    'Analytics: messages add comment': {
        eng: 'Add comment',
        ru: 'Добавить комментарий',
        pt: 'Adicionar comentário',
        cn: 'Add comment',
    },
    'Analytics: messages comment': {
        eng: 'Comment',
        ru: 'Kомментарий',
        pt: 'Comentar',
        cn: 'Comment',
    },
    'Analytics: No messages found': {
        eng: 'No messages found',
        ru: 'Фраз не найдено',
        pt: 'Nenhuma mensagem encontrado',
        cn: '未找到短语',
    },
    'Analytics: messages intents': {
        eng: 'Add phrase to intent',
        ru: 'Добавить фразу в интент',
        pt: 'Adicionar frase à intenção',
        cn: 'Add phrase to intent',
    },
    'Analytics: client table name': {
        eng: 'Name',
        ru: 'Имя',
        pt: 'Nome',
        cn: 'Name',
    },
    'Analytics: client table messenger': {
        eng: 'Messenger',
        ru: 'Мессенджер',
        pt: 'Messenger',
        cn: 'Messenger',
    },
    'Analytics: client table addition date': {
        eng: 'Creation date',
        ru: 'Дата добавления',
        pt: 'Data de criação',
        cn: '创建日期',
    },
    'Analytics: client table last activity date': {
        eng: 'Last activity date',
        ru: 'Дата последней активности',
        pt: 'Data da última atividade',
        cn: '最后一次活动日期',
    },
    'Analytics: client table bot name': {
        eng: 'Bot name',
        ru: 'Бот',
        pt: 'Nome de bot',
        cn: '机器人名称',
    },
    'Analytics: client table is active': {
        eng: 'Active',
        ru: 'Активен',
        pt: 'Ativo',
        cn: '有效',
    },
    'Analytics: messages add tasks': {
        eng: 'Add task',
        ru: 'Добавить задачу',
        pt: 'Adicionar tarefa',
        cn: 'Add task',
    },
    'Analytics: current task label': {
        eng: 'Task',
        ru: 'Задача',
        pt: 'Tarefa',
        cn: 'Task',
    },
    'Analytics: messages add labels': {
        eng: 'Add labels',
        ru: 'Назначить метки',
        pt: 'Adicionar rótulos',
        cn: 'Add labels',
    },
    'Analytics: current labels label': {
        eng: 'Labels',
        ru: 'Mетки',
        pt: 'Rótulos',
        cn: 'Labels',
    },
    'Analytics: sessionId label': {
        eng: 'Session',
        ru: 'Сессия',
        pt: 'Sessão',
        cn: 'Session',
    },
    'Analytics: clientId label': {
        eng: 'Client',
        ru: 'Клиент',
        pt: 'Cliente',
        cn: 'Client',
    },
    'Analytics: botId label': {
        eng: 'Channel',
        ru: 'Канал',
        pt: 'Canal',
        cn: 'Channel',
    },
    'Analytics: sessionsTable head startTime': {
        eng: 'Start time',
        ru: 'Время начала',
        pt: 'Hora de início',
        cn: 'Start time',
    },
    'Analytics: back button': {
        eng: 'Return to list',
        ru: 'Вернуться к списку',
        pt: 'Voltar para a lista',
        cn: 'Return to list',
    },
    'Analytics: label new': {
        eng: 'New',
        ru: 'Добавить',
        pt: 'Novo',
        cn: 'New',
    },
    'Analytics: filter placeholder': {
        eng: 'Filter name',
        ru: 'Название фильтра',
        pt: 'Nome do filtro',
        cn: 'Filter name',
    },
    'Analytics: Save changes': {
        eng: 'Save changes',
        ru: 'Сохранить изменения',
        pt: 'Salvar alterações',
        cn: 'Save changes',
    },
    'Analytics: channel help text': {
        eng: 'Client and bot communication channel',
        ru: 'Канал общения клиента и бота',
        pt: 'Canal de comunicação entre cliente e bot',
        cn: 'Client and bot communication channel',
    },
    'Analytics: channel placeholder': {
        eng: 'Enter channel type',
        ru: 'Введите тип канала',
        pt: 'Inserir tipo de canal',
        cn: 'Enter channel type',
    },
    'Analytics: to session': {
        eng: 'To session',
        ru: 'В ceссию',
        pt: 'Para sessão',
        cn: 'To session',
    },
    'Analytics: reset choosed labels': {
        eng: 'Clear selection',
        ru: 'Снять выделение',
        pt: 'Limpar seleção',
        cn: 'Clear selection',
    },
    'Analytics: choose all labels': {
        eng: 'Select all filtered',
        ru: 'Выбрать все найденные',
        pt: 'Selecionar todos filtrados',
        cn: 'Select all filtered',
    },
    'Analytics: choose label founded': {
        eng: 'selected',
        ru: 'выбрано',
        pt: 'selecionados',
        cn: 'selected',
    },
    'Analytics: set mass labels': {
        eng: 'Set labels',
        ru: 'Назначить метки',
        pt: 'Definir rótulos',
        cn: 'Set labels',
    },
    'Analytics: messages event': {
        eng: 'Event',
        ru: 'Событие',
        pt: 'Evento',
        cn: 'Event',
    },
    'Analytics:Livechat:Finished': {
        eng: 'The chat with the agent has finished',
        ru: 'Диалог с оператором завершен',
    },
    'Analytics: routes tab': {
        eng: 'Routes',
        ru: 'Маршруты',
        pt: 'Rotas',
        cn: 'Routes',
    },
    'Analytics: routesTable route': {
        eng: 'Route',
        ru: 'Маршрут',
        pt: 'Rota',
        cn: 'Route',
    },
    'Analytics: routesTable result': {
        eng: 'Result',
        ru: 'Результат',
        pt: 'Resultado',
        cn: 'Result',
    },
    'Analytics: routesTable sessionsCount': {
        eng: 'Sessions',
        ru: 'Сессий',
        pt: 'Sessões',
        cn: 'Sessions',
    },
    'Analytics: routes close button': {
        eng: 'Close',
        ru: 'Закрыть',
        pt: 'Fechar',
        cn: 'Close',
    },
    'Analytics: No routes found': {
        eng: 'No routes found',
        ru: 'Маршрутов не найдено',
        pt: 'Nenhuma rota encontrada',
        cn: 'No routes found',
    },
    'Analytics: ClientsDialogs head client': {
        eng: 'Client',
        ru: 'Клиент',
        pt: 'Cliente',
        cn: 'Client',
    },
    'Analytics: ClientsDialogs head last session': {
        eng: 'First activity',
        ru: 'Первая сессия',
        pt: 'Primeira atividade',
        cn: 'First activity',
    },
    'Analytics: ClientsDialogs head last activity': {
        eng: 'Last activity',
        ru: 'Последняя активность',
        pt: 'Última atividade',
        cn: 'Last activity',
    },
    'Analytics: ClientsDialogs load more': {
        eng: 'Load more',
        ru: 'Загрузить еще',
        pt: 'Carregar mais',
        cn: 'Load more',
    },
    'Analytics: ClientsDialogsLabel': {
        eng: 'Dialogs',
        ru: 'Диалоги',
        pt: 'Diálogos',
        cn: 'Dialogs',
    },
    'Analytics: hide empty message label': {
        eng: 'Hide empty messages',
        ru: 'Скрывать пустые сообщения',
        pt: 'Ocultar mensagens vazias',
        cn: 'Hide empty messages',
    },
    'Analytics: ClientDialog: log tab': {
        eng: 'Chat history',
        ru: 'История чата',
        pt: 'Histórico de chat',
        cn: 'Chat history',
    },
    'Analytics: ClientDialog: user tab': {
        eng: 'Client data',
        ru: 'Данные клиента',
        pt: 'Dados de cliente',
        cn: 'Client data',
    },
    'Analytics: ClientDialog: bot id label': {
        eng: 'Bot ID',
        ru: 'ID бота',
        pt: 'ID de bot',
        cn: 'Bot ID',
    },
    'Analytics: ClientDialog: userName label': {
        eng: 'User name',
        ru: 'Имя пользователя',
        pt: 'Nome de usuário',
        cn: 'User name',
    },
    'Analytics: ClientDialog: messanger label': {
        eng: 'Channel',
        ru: 'Канал',
        pt: 'Canal',
        cn: 'Channel',
    },
    'Analytics: ClientDialog: firstMessage label': {
        eng: 'First activity',
        ru: 'Первая сессия (время добавления)',
        pt: 'Primeira atividade',
        cn: 'First activity',
    },
    'Analytics: ClientDialog: last visit label': {
        eng: 'Last activity',
        ru: 'Последняя активность',
        pt: 'Última atividade',
        cn: 'Last activity',
    },
    'Analytics: ClientDialog: reason block label': {
        eng: 'Block reason',
        ru: 'Причина блокировки',
        pt: 'Motivo de bloqueio',
        cn: 'Block reason',
    },
    'Analytics: ClientDialog: blocked by label': {
        eng: 'Blocked by',
        ru: 'Кем заблокирован',
        pt: 'Bloqueado por',
        cn: 'Blocked by',
    },
    'Analytics: ClientDialog: blocked date label': {
        eng: 'Last activity',
        ru: 'Последняя активность',
        pt: 'Última atividade',
        cn: 'Last activity',
    },
    'Analytics: ClientDialog: unblock user button label': {
        eng: 'Unblock client',
        ru: 'Разблокировать',
        pt: 'Desbloquear cliente',
        cn: 'Unblock client',
    },
    'Analytics: ClientDialog: block user button label': {
        eng: 'Block client',
        ru: 'Заблокировать',
        pt: 'Bloquear cliente',
        cn: 'Block client',
    },
    'Analytics: ClientDialog: block user modal title': {
        eng: 'Block the client',
        ru: 'Заблокировать клиента',
        pt: 'Bloquear o cliente',
        cn: 'Block the client',
    },
    'Analytics: ClientDialog: block user reason label': {
        eng: 'Reason',
        ru: 'Причина',
        pt: 'Motivo',
        cn: 'Reason',
    },
    'Analytics: ClientDialog: block user reason placeholder': {
        eng: 'The client violated…',
        ru: 'Что нарушил клиент',
        pt: 'O cliente violou…',
        cn: 'The client violated…',
    },
    'Analytics: ClientDialog: required field error': {
        eng: 'Required field',
        ru: 'Обязательно для заполнения',
        pt: 'Campo obrigatório',
        cn: 'Required field',
    },
    'Analytics: ClientDialog: block user message label': {
        eng: 'Message to client',
        ru: 'Сообщение клиенту',
        pt: 'Mensagem para o cliente',
        cn: 'Message to client',
    },
    'Analytics: ClientDialog: block user message placeholder': {
        eng: 'You were blocked because…',
        ru: 'Вы были заблокированы, потому что…',
        pt: 'Você foi bloqueado porque…',
        cn: 'You were blocked because…',
    },
    'Analytics: ClientDialog: unblock user modal title': {
        eng: 'Unblock the client',
        ru: 'Разблокировать клиента',
        pt: 'Desbloquear o cliente',
        cn: 'Unblock the client',
    },
    'Analytics: ClientDialog: unblock user modal description': {
        eng: 'The client will be able to communicate with the bot and agents again',
        ru: 'Клиент сможет снова общаться с ботом и оператором',
        pt: 'O cliente não conseguirá se comunicar com o bot e os agentes novamente',
        cn: 'The client will be able to communicate with the bot and agents again',
    },
    'Analytics: ClientDialog: user blocked label': {
        eng: 'Blocked',
        ru: 'Заблокирован',
        pt: 'Bloqueado',
        cn: 'Blocked',
    },
    'Analytics: ClientDialog: no user placeholder': {
        eng: 'You don’t have any clients yet \nor none were found due to the filters applied',
        ru: 'У вас еще нет клиентов \nили их не нашлось по выбранным фильтрам',
        pt: 'Você ainda não tem nenhum cliente \nou nenhum foi encontrado por causa dos filtros aplicados',
        cn: 'You don’t have any clients yet \nor none were found due to the filters applied',
    },
    'Analytics:ClientDialog:Connecting_to_a_chat_with_an_operator': {
        eng: 'Connecting to the chat with an agent',
        ru: 'Подключение к диалогу с оператором',
        pt: 'Conectando o chat ao agente',
    },
    'Analytics:ClientDialog:Operator_is_connected': {
        eng: '$[1] ($[2]) has joined the chat',
        ru: 'Оператор $[1] ($[2]) подключился',
        pt: '$[1] ($[2]) se juntou ao chat',
    },
    'Analytics:ClientDialog:The_operator_ended_the_dialogue': {
        eng: 'The agent has closed the chat',
        ru: 'Оператор завершил диалог',
        pt: 'O agente fechou a chat',
    },
    'Analytics:ClientDialog:The_client_has_completed_the_dialogue': {
        eng: 'The customer has closed the chat',
        ru: 'Клиент завершил диалог',
        pt: 'O cliente fechou o chat',
    },
    'Analytics:ClientDialog:Chat_is_transferred_to': {
        eng: 'The chat is assigned to the group: $[1]',
        ru: 'Диалог переведен на группу: $[1]',
        pt: 'O chat é atribuído ao grupo: $[1]',
    },
    'Analytics:ClientDialog:Chat_with_the_operator_is_complete': {
        eng: 'The chat with the agent has finished',
        ru: 'Диалог с оператором завершен',
        pt: 'O chat com o agente foi finalizado',
    },
    'Analytics:ClientDialog:Switching_to_another_operator': {
        eng: 'Switching to another agent',
        ru: 'Переключаем на другого оператора',
        pt: 'Mudar para outro agente',
    },
    'Analytics:ClientDialog:The_client_is_blocked_in_operator_chat': {
        eng: 'The customer is blocked by the agent',
        ru: 'Клиент заблокирован оператором',
        pt: 'O cliente está bloqueado pelo agente',
    },
    'Analytics:ClientDialog:The_client_is_unblocked_in_operator_chat': {
        eng: 'The customer is unblocked by the agent',
        ru: 'Клиент разблокирован оператором',
        pt: 'O cliente está desbloqueado pelo agente',
    },
    'Analytics: create list': {
        eng: 'Create client list',
        ru: 'Создать список клиентов',
        pt: 'Criar lista de clientes',
        cn: 'Create client list',
    },
    'Analytics: create list modal title': {
        eng: 'New client list',
        ru: 'Новый список клиентов',
        pt: 'Nova lista de clientes',
        cn: 'New client list',
    },
    'Analytics: create list modal label': {
        eng: 'List name',
        ru: 'Название списка',
        pt: 'Nome da lista',
        cn: 'List name',
    },
    'Analytics: create list modal input placeholder': {
        eng: 'My list',
        ru: 'Мой список',
        pt: 'Minha lista',
        cn: 'My list',
    },
    'Analytics: create list modal submit': {
        eng: 'Create',
        ru: 'Создать',
        pt: 'Criar',
        cn: 'Create',
    },
    'Analytics: create list button pophover': {
        eng: 'To create a list, select a channel type in the filter.',
        ru: 'Чтобы создать список, выберите один из типов канала в фильтре.',
        pt: 'Para criar uma lista, selecione um tipo de canal no filtro.',
        cn: 'To create a list, select a channel type in the filter.',
    },
    'Analytics: required field': {
        eng: 'Required',
        ru: 'Обязательно для заполнения',
        pt: 'Obrigatório',
        cn: 'Required',
    },
    'Analytics: clients list modal created label': {
        eng: 'The <a href="/calllists?page=0&size=20" target="_blank">$[1]</a> client list is loading…',
        ru: 'Список клиентов <a href="/calllists?page=0&size=20" target="_blank">$[1]</a> загружается…',
        pt: 'A lista de clientes <a href="/calllists?page=0&size=20" target="_blank">$[1]</a> está sendo carregada…',
        cn: 'The <a href="/calllists?page=0&size=20" target="_blank">$[1]</a> client list is loading…',
    },
    'Analytics:ClientsList:Modal:Created:Label:Zenflow': {
        eng: 'The $[1] client list is loading…',
        ru: 'Список клиентов $[1] загружается…',
        pt: 'A lista de clientes $[1] está sendo carregada…',
    },
    'Analytics:ClientsList:Button:Zenflow': {
        eng: 'Create client list',
        ru: 'Создать список для рассылки',
    },
    'Analytics:ClientsList:Download:Button:Zenflow': {
        eng: 'Download',
        ru: 'Скачать',
    },
    'Analytics:GoTo:TextCampaigns': {
        eng: 'Go to text campaigns',
        ru: 'К созданию рассылки',
    },
    'Analytics: No data specified': {
        eng: 'No data specified',
        ru: 'Данные не указаны',
        pt: 'Nenhum dado especificado',
        cn: '数据未指定',
    },
    'Analytics:ClientsDialogs:SENT': {
        eng: 'Sent',
        ru: 'Отправлено',
        pt: 'Enviado',
    },
    'Analytics:ClientsDialogs:DELIVERED': {
        eng: 'Delivered',
        ru: 'Доставлено',
        pt: 'Entregue',
    },
    'Analytics:ClientsDialogs:NOT_DELIVERED': {
        eng: 'Not delivered',
        ru: 'Не доставлено',
    },
    'Analytics:ClientsDialogs:READ': {
        eng: 'Read',
        ru: 'Прочитано',
        pt: 'Lido',
    },
    'Analytics:ClientsDialogs:NOT_SENT': {
        eng: 'Not sent',
        ru: 'Не отправлено',
        pt: 'Não enviado',
    },
    'Analytics:ClientsDialogs:EventHangup': {
        eng: 'The client hung up',
        ru: 'Абонент повесил трубку',
    },
    'Analytics:ClientsDialogs:EventBotHangup': {
        eng: 'The bot hung up',
        ru: 'Бот повесил трубку',
    },
    'Analytics:FiltersPanel:Selected': {
        eng: 'selected',
        ru: 'выбрано',
        pt: 'selecionado',
    },
    'Analytics:FiltersPanel:NotSelected': {
        eng: 'not selected',
        ru: 'не выбрано',
        pt: 'não selecionado',
    },
    'Analytics:FiltersPanel:Empty': {
        eng: 'empty',
        ru: 'пусто',
        pt: 'vazio',
    },
    'Analystics:Clients:List:Modal': {
        eng: 'Client lists are uploaded when <a href="$[1]">text campaigns</a> are created and are available for download.',
        ru: 'Списки подгружаются при создании <a href="$[1]">рассылок</a> и доступны для скачивания.',
    },
    'Analystics:Clients:List:Error:Title': {
        eng: 'Error',
        ru: 'Ошибка',
    },
    'Analystics:Clients:List:Error:Description': {
        eng: 'An error occurred while creating the client list. Try again or select different filters.',
        ru: 'При создании списка клиентов произошла ошибка. Попробуйте еще раз или выберите другие фильтры.',
    },
    'Analystics:Clients:List:CnangeFilters': {
        eng: 'Change filters',
        ru: 'Изменить фильтры',
    },
    'Analystics:Clients:List:TryAgain': {
        eng: 'Please try again',
        ru: 'Попробовать еще раз',
    },
    'Analystics:Clients:List:Created': {
        eng: 'Client list <a href="$[1]">$[2]</a> created',
        ru: 'Список клиентов <a href="$[1]">$[2]</a> создан',
    },
};
