import React, { createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AllowedAccountListItem } from '../api/client';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import ProjectGroupsService from '../service/ProjectGroupService';
import { isInternal } from '../../../isAccessFunction';
import { isDev } from '../../../pipes/pureFunctions';
import { Spinner } from '@just-ai/just-ui';

const cookies = new Cookies();

export type ProjectGroupsContextType = {
  currentProjectGroupId: string | null;
  setProjectGroupId: (id: number | null) => void;
  projectGroupList: AllowedAccountListItem[];
  setProjectGroups: (projectGroups: AllowedAccountListItem[]) => void;
  currentProjectGroupObject: AllowedAccountListItem;
  projectShortName: string;
  getProjectGroups: () => void;
  ProjectGroupsService: ProjectGroupsService;
  setIsLeaveGroupModalOpen: (isOpen: boolean) => void;
  isLeaveGroupModalOpen: boolean;
};

interface ProjectGroupsContextProviderType {
  accountId: number;
  projectShortName: string;
  currentUser: any;
}

export const ProjectGroupsContext = createContext({} as ProjectGroupsContextType);

const ProjectGroupsContextProvider: FunctionComponent<ProjectGroupsContextProviderType> = ({
  children,
  accountId,
  projectShortName,
  currentUser,
}) => {
  const ProjectGroupServiceInstance = useMemo(() => new ProjectGroupsService(), []);
  const [currentProjectGroupId, setProjectGroupId] = useState<string | null>(cookies.get('SELECTED_ACCOUNT'));
  const [projectGroupList, setProjectGroups] = useState<AllowedAccountListItem[]>([]);
  const [isLeaveGroupModalOpen, setIsLeaveGroupModalOpen] = useState(false);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    if (accountId && String(accountId) !== cookies.get('SELECTED_ACCOUNT')) {
      setProjectGroupId(String(accountId));
    }
  }, [accountId]);

  const getProjectGroups = useCallback(async () => {
    if (!isInternal() && currentUser?.id) {
      const data = await ProjectGroupServiceInstance.getAllowedAccounts(currentUser.id);
      const defaultAccount = data.find(account => account.default);
      if (!isDev() && !defaultAccount && !currentUser.account) {
        window.location.href = '/c/select-project-group';
      }
      setProjectGroups(data);
    }
    setFetching(false);
  }, [ProjectGroupServiceInstance, currentUser]);

  const setCurrentProjectGroup = useCallback(
    (rawId: number | null) => {
      let id = rawId;
      if (rawId === null) {
        const defaultProjectGroup = projectGroupList.find(group => group.default);
        if (defaultProjectGroup) {
          id = defaultProjectGroup.id as number;
          getProjectGroups();
        } else {
          window.location.href = '/c/select-project-group';
        }
      }
      if (id !== null) {
        cookies.set('SELECTED_ACCOUNT', String(id), { path: '/' });
        setProjectGroupId(String(id));
        const event = new Event('changeProjectGroupCookie');
        window.dispatchEvent(event);
      }
    },
    [projectGroupList, getProjectGroups]
  );

  useEffect(() => {
    getProjectGroups();
  }, [getProjectGroups]);

  const currentProjectGroupObject = useMemo(
    () =>
      projectGroupList.find(group =>
        currentProjectGroupId ? group.id === Number(currentProjectGroupId) : group.default
      )!,
    [currentProjectGroupId, projectGroupList]
  );

  return (
    <ProjectGroupsContext.Provider
      value={{
        currentProjectGroupId,
        setProjectGroupId: setCurrentProjectGroup,
        projectGroupList,
        setProjectGroups,
        projectShortName,
        currentProjectGroupObject,
        getProjectGroups,
        ProjectGroupsService: ProjectGroupServiceInstance,
        setIsLeaveGroupModalOpen,
        isLeaveGroupModalOpen,
      }}
    >
      {fetching ? <Spinner size='4x' /> : children}
    </ProjectGroupsContext.Provider>
  );
};

export const useProjectGroupsContext = () => useContext(ProjectGroupsContext);

function mapStateToProps(state: any) {
  return {
    accountId: state.CurrentAccountReducer.account?.id,
    projectShortName: state.CurrentProjectsReducer.currentProject,
    currentUser: state.CurrentUserReducer.currentUser,
  };
}

export default connect(mapStateToProps, null)(ProjectGroupsContextProvider);
