import React, {
  FunctionComponent,
  useEffect,
  useState,
  KeyboardEvent,
  useCallback,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { InputGroup, Icon, Tooltip } from '@just-ai/just-ui';
import ValidationErrorPanel from '@just-ai/nlu-modules/dist/views/FAQTreePage/components/IntentFaqEditView/components/ValidationErrorPanel';
import { uniqueId } from 'lodash';
import { assignValidationErrorToPhrase, normalize, useToggle } from '../../utils';
import { IntentItem, IntentItemType } from '../../model';
import ClickAwayListener from '../../../../components/ClickAwayListener';
import { toIntentItem } from 'modules/Caila/utils/intents';
import { useAppContext } from 'modules/Caila/components/AppContext';
import { t } from 'localization';
import TableWithVirtualList, { RowComponentType } from 'components/TableWithVirtualList';
import { SimilarPhraseData } from 'modules/Caila/api/client';
import { AccountInfoData } from 'modules/Caila/CailagateApi/client/api';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';
import { AddingPhraseInput } from './IntentItemAddingInput';

export interface CustomIntentItem extends IntentItem {
  index: number;
  id: string;
  error?: string;
}

const DEBOUNCE_SAVE_TIMEOUT = 500;

const convertToPhraseTextArray = (value: string) => value.split('\n').filter(phrase => phrase !== '');

const toggleIntentItemType = (item: CustomIntentItem) => ({
  ...item,
  type: item.type === IntentItemType.pattern ? IntentItemType.phrase : IntentItemType.pattern,
});

export interface RowComponentPayload {
  openedItem: number | null;
  setOpenedItem: (index: number | null) => unknown;
  onDelete: (indexes: number[]) => void;
  editDisabled?: boolean;
}
export const RowComponent: RowComponentType<CustomIntentItem, RowComponentPayload> = React.memo(
  ({ index, data, onChange, payload }) => {
    const [isOpened, setOpen, setClose] = useToggle(payload?.openedItem === index);
    const [openedText, setOpenedText] = useState(data.text);
    const [caretPos, setCaretPos] = useState(0);
    const iconRef = useRef(null);
    const editBlockRef = useRef<HTMLDivElement>(null);
    const onKeyUp = ({ key }: KeyboardEvent<HTMLDivElement>) => {
      if (['Enter', 'Escape'].includes(key)) {
        if (isOpened || data.text !== openedText) {
          onChange({ ...data, text: openedText });
        }
        close();
      }
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (!isOpened || data.text === openedText) return;
        onChange({ ...data, text: openedText });
      }, DEBOUNCE_SAVE_TIMEOUT);
      return () => clearTimeout(timeout);
    }, [data, isOpened, onChange, openedText]);

    const open = useCallback(() => {
      if (payload && payload.openedItem !== index && !payload.editDisabled) {
        setOpen();
        setOpenedText(data.text);
        payload.setOpenedItem(index);
      }
    }, [data.text, index, payload, setOpen]);

    const close = useCallback(() => {
      setClose();
      payload && payload.setOpenedItem(null);
    }, [setClose, payload]);

    const handleInputChange = useCallback(
      (value: string, name?: string, event?: React.ChangeEvent<HTMLInputElement>) => {
        setOpenedText(value);
        if (event?.target.selectionEnd) {
          setCaretPos(event.target.selectionEnd);
        }
      },
      []
    );

    const handleChangePhraseType = useCallback(
      event => {
        event.stopPropagation();
        if (payload?.editDisabled) return;
        onChange(toggleIntentItemType(data));
        //this is needed to prevent click firing when hitting enter to close the edit panel
        event.preventDefault();
      },
      [data, onChange, payload?.editDisabled]
    );

    const handleDeleteClick = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        if (payload?.editDisabled) return;
        payload?.onDelete && payload.onDelete([index]);
      },
      [index, payload]
    );

    const handleInputFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (caretPos) {
          event.target?.setSelectionRange(caretPos, caretPos);
        } else {
          event.target?.setSelectionRange(event.target.value.length, event.target.value.length);
        }
      },
      [caretPos]
    );

    const onKeyPress = useCallback((event: KeyboardEvent) => {
      if (event.key !== 'Enter') return;
      event.preventDefault();
    }, []);

    return (
      <div className='intents-item-edit__table-row justui-table__cell' key={`${data.index}-${data.text}`}>
        <div className='intents-item-edit__table-row--wrapper' onClick={open} tabIndex={0}>
          {isOpened ? (
            <ClickAwayListener handleClickOut={close}>
              <div className='intents-item-edit__table-row--edit' ref={editBlockRef} onKeyUp={onKeyUp}>
                <InputGroup
                  className='intents-page__edit-phrase-block edit-phrase-block'
                  Prepend={
                    <button
                      disabled={payload?.editDisabled}
                      className='btn'
                      type='button'
                      name={data.type === IntentItemType.phrase ? 'farText' : 'faAt'}
                      onMouseDown={event => {
                        onChange(toggleIntentItemType(data));
                        //this is needed to prevent click firing when hitting enter to close the edit panel
                        event.preventDefault();
                      }}
                      title={t(
                        'IntentItemsEdit:' + (data.type === IntentItemType.phrase ? 'buttonPhrase' : 'buttonPattern')
                      )}
                      data-test-id='IntentsPage.PhrasesEdit.Type'
                    >
                      <Icon name={data.type === IntentItemType.phrase ? 'farText' : 'faAt'} />
                    </button>
                  }
                  Append={
                    <button
                      disabled={payload?.editDisabled}
                      className='btn'
                      type='button'
                      color='secondary'
                      onClick={handleDeleteClick}
                      data-test-id='IntentsPage.PhrasesEdit.AddPhraseButton'
                    >
                      <Icon name='farTrashAlt' />
                    </button>
                  }
                >
                  <TextAreaLikeElement
                    autoFocus
                    className='textarea-container form-control'
                    value={openedText}
                    richValue={openedText}
                    placeholder={t('IntentItemsEdit:textPlaceholder')}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    dataTestId='IntentsPage.PhrasesEdit.PhraseInput'
                    onKeyDown={onKeyPress}
                    append={
                      data.error === 'validation' && (
                        <>
                          <div className='phrase-row__error' ref={iconRef}>
                            <Icon name='faExclamationCircle' color='danger' />
                          </div>
                          <Tooltip target={iconRef} placement='top'>
                            {t('PhraseBlocks:validationTooltip')}
                          </Tooltip>
                        </>
                      )
                    }
                  />
                </InputGroup>
              </div>
            </ClickAwayListener>
          ) : (
            <div className='intents-item-edit__table-row--view' onClick={open}>
              <div onClick={handleChangePhraseType} className='intents-item-edit__table-row__change-btn'>
                <Icon
                  name={data.type === IntentItemType.phrase ? 'farText' : 'faAt'}
                  title={t(
                    'IntentItemsEdit:' + (data.type === IntentItemType.phrase ? 'buttonPhrase' : 'buttonPattern')
                  )}
                  data-test-value={data.type === IntentItemType.phrase ? 'phrase' : 'pattern'}
                  data-test-id='IntentsPage.PhrasesEdit.PhraseTypeIcon'
                  color='secondary'
                />
              </div>
              <span className='phrase-text' data-test-id='IntentsPage.PhrasesEdit.PhraseText'>
                {data.text}
              </span>
              <div className='intents-item-edit__table-row__delete-btn' onClickCapture={handleDeleteClick}>
                <Icon
                  name='farTrashAlt'
                  title={t('Remove')}
                  data-test-id='IntentsPage.PhrasesEdit.DeleteIcon'
                  color='secondary'
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

interface IntentItemsEditProps {
  items: IntentItem[];
  onAddPhrases: (phrases: IntentItem[], softValidate?: boolean) => unknown;
  onDeleteItems: (items: number[]) => unknown;
  onChangeItem: (item: IntentItem | IntentItem[], index?: number, softValidate?: boolean) => unknown;
  clearValError: () => void;
  editDisabled?: boolean;
  valErrorData?: SimilarPhraseData[];
  handleValErrorLinks: (path: string) => void;
}
const IntentItemsEdit: FunctionComponent<IntentItemsEditProps> = ({
  items,
  onAddPhrases,
  onDeleteItems,
  onChangeItem,
  editDisabled,
  valErrorData,
  handleValErrorLinks,
  clearValError,
}) => {
  const { currentProject, appConfig, IntentsService } = useAppContext();
  const [newItemText, setNewItemText] = useState('');
  const [openedItem, setOpenedItem] = useState<number | null>(null);
  const [paraphraseCache, setParaphraseCache] = useState('');
  const [paraphraseLoading, setParaphraseLoading] = useState(false);
  const [paraphraseVariants, setParaphraseVariants] = useState<string[]>([]);
  const [filter, setFilter] = useState('');
  const [allItems, setAllItems] = useState<CustomIntentItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<CustomIntentItem[]>(allItems);
  const [itemType, setItemType] = useState<IntentItemType>(IntentItemType.phrase);

  const [paraphraseData, setParaphraseData] = useState<AccountInfoData>();

  const [dynamicStickyHeaderOffset, setDynamicStickyHeaderOffset] = useState(0);

  const addingPhraseInputRef = useRef<HTMLDivElement | null>(null);

  //no need for deps array here for constant update on an input element height
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setDynamicStickyHeaderOffset(addingPhraseInputRef.current?.clientHeight || 39);
  });

  const isValErrorInNewItem = useMemo(() => {
    return valErrorData?.some(phrase => phrase.origin === newItemText);
  }, [valErrorData, newItemText]);

  const rerollRef = useRef<HTMLElement | null>(null);
  const loadingRef = useRef<HTMLDivElement | null>(null);
  const iconRef = useRef(null);

  const paraphraseButtonCondition = useMemo(
    () =>
      itemType === IntentItemType.phrase &&
      !paraphraseLoading &&
      !isValErrorInNewItem &&
      appConfig.paraphrase?.enabled &&
      (currentProject?.language?.toLowerCase() === 'ru' || currentProject?.language?.toLowerCase() === 'en'),
    [appConfig.paraphrase?.enabled, currentProject?.language, isValErrorInNewItem, itemType, paraphraseLoading]
  );

  useEffect(() => {
    rerollRef.current = document.getElementById('paraphraseButton');
    if (paraphraseButtonCondition && !paraphraseData) {
      const getParaphraseData = async () => {
        const data = await IntentsService.getCailagateAccount();
        setParaphraseData(data);
      };
      getParaphraseData();
    }
  }, [IntentsService, paraphraseButtonCondition, paraphraseData]);

  const addPhrases = useCallback(
    async (event?: React.SyntheticEvent<HTMLButtonElement, MouseEvent>, softValidate?: boolean) => {
      event && event.stopPropagation();
      if (!newItemText) return;
      const prevScrollY = window.scrollY;
      const phrasesArray: IntentItem[] = convertToPhraseTextArray(newItemText).map(text => {
        return toIntentItem(text, itemType);
      });
      await onAddPhrases(phrasesArray, softValidate);
      setOpenedItem(null);
      setNewItemText('');
      setDynamicStickyHeaderOffset(39);
      window.scrollY = prevScrollY;
    },
    [itemType, newItemText, onAddPhrases]
  );

  const onKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return;
      event.preventDefault();
      addPhrases();
    },
    [addPhrases]
  );

  const onDelete = useCallback(
    (indexes: number[]) => {
      const itemsToDelete = filteredItems.filter((_item, i) => indexes.includes(filteredItems.length - 1 - i));
      const indexesToDelete: number[] = itemsToDelete ? itemsToDelete.map(item => item.index) : [];
      setOpenedItem(null);
      onDeleteItems(indexesToDelete);
    },
    [filteredItems, onDeleteItems]
  );

  const updateFilter = useCallback(
    () => setFilteredItems(allItems.filter(item => normalize(item.text).includes(normalize(filter)))),
    [allItems, filter]
  );

  useEffect(() => {
    updateFilter();
  }, [filter, updateFilter]);

  useEffect(() => {
    if (!valErrorData || !valErrorData.length) {
      return setAllItems(
        items.map((item, i) => {
          return { ...item, index: i, id: (item as CustomIntentItem).id || uniqueId(String(i)), error: undefined };
        })
      );
    }
    const updatedItems = assignValidationErrorToPhrase(valErrorData, items);
    return setAllItems(
      updatedItems.map((item, i) => {
        return { ...item, index: i, id: (item as CustomIntentItem).id || uniqueId(String(i)) };
      })
    );
  }, [items, valErrorData]);

  useEffect(() => {
    filter ? updateFilter() : setFilteredItems([...allItems]);
  }, [allItems, filter, updateFilter]);

  const paraphraseClick = useCallback(async () => {
    if (!appConfig.paraphrase.enabled) return;
    if (paraphraseVariants.length > 0) {
      const localVariants = [...paraphraseVariants];
      setNewItemText(localVariants.shift()!);
      setParaphraseVariants(localVariants);
      return;
    }
    setParaphraseLoading(true);
    try {
      const paraphraserResponse = await IntentsService.getParaphrasedItems(
        paraphraseCache,
        currentProject?.language?.toLowerCase()
      );
      const phrasesArr = paraphraserResponse.texts_list[0].values;
      setNewItemText(phrasesArr.shift() || '');
      setParaphraseVariants(phrasesArr);
    } catch (error) {
      console.error('Error during paraphrasing', error);
    } finally {
      setParaphraseLoading(false);
    }
  }, [IntentsService, appConfig, currentProject?.language, paraphraseCache, paraphraseVariants]);

  const handleTextInput = useCallback(
    (value: string) => {
      setNewItemText(value);
      if (isValErrorInNewItem) {
        clearValError();
      }
      setParaphraseVariants([]);
      setParaphraseCache(value.trim());
    },
    [clearValError, isValErrorInNewItem]
  );

  const onChangeCallback = useCallback((item: CustomIntentItem) => onChangeItem(item, item.index), [onChangeItem]);

  const payloadMemo = useMemo(() => {
    return {
      openedItem,
      setOpenedItem,
      onDelete,
      editDisabled,
    };
  }, [onDelete, openedItem, editDisabled]);

  const tooltipContent = useMemo(() => {
    if (!appConfig.paraphrase.enabled) return t('IntentItemsEdit:tooltipParaphraseUnavailable');
    return paraphraseVariants.length > 0
      ? t('IntentItemsEdit:tooltipParaphraseAnother')
      : t('IntentItemsEdit:tooltipParaphraseInfo');
  }, [appConfig.paraphrase.enabled, paraphraseVariants.length]);

  return (
    <div data-test-id='IntentsPage.PhrasesEdit'>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{ flexShrink: 0 }}>{t('IntentItemsEdit:pageTitle')}</h2>
      </div>
      <AddingPhraseInput
        ref={addingPhraseInputRef}
        switchType={() =>
          setItemType(itemType === IntentItemType.phrase ? IntentItemType.pattern : IntentItemType.phrase)
        }
        itemType={itemType}
        paraphraseLoading={paraphraseLoading}
        editDisabled={editDisabled}
        addPhrases={addPhrases}
        paraphraseButtonCondition={paraphraseButtonCondition}
        paraphraseClick={paraphraseClick}
        newItemText={newItemText}
        appConfig={appConfig}
        rerollRef={rerollRef}
        tooltipContent={tooltipContent}
        onChange={handleTextInput}
        onKeyDown={onKeyPress}
        valErrorInNewItem={Boolean(isValErrorInNewItem)}
        iconRef={iconRef}
        loadingRef={loadingRef}
      />
      {isValErrorInNewItem && (
        <div className='my-4'>
          <ValidationErrorPanel
            usageArea='intent'
            type='questions'
            updateWithoutValidation={() => {
              addPhrases(undefined, false);
            }}
            handleLinkToAnswer={handleValErrorLinks}
            validationErrorData={valErrorData}
          />
        </div>
      )}
      {items.length ? (
        <>
          <div className='intents-item-edit__items-table'>
            <TableWithVirtualList<CustomIntentItem, RowComponentPayload>
              data={[...filteredItems].reverse()}
              onDelete={onDelete}
              selectedLabel={t('IntentItemsEdit:selectedLabel')}
              discardLabel={t('IntentItemsEdit:discardLabel')}
              deleteLabel={t('IntentItemsEdit:deleteLabel')}
              stickyHeader
              stickyHeaderOffset={dynamicStickyHeaderOffset + 12}
              onChange={onChangeCallback}
              rowComponentPayload={payloadMemo}
              RowComponent={RowComponent}
              data-test-id='IntentsPage.PhrasesEdit.Table'
              externalFilter
              externalFilterChange={setFilter}
              externalFilterValue={filter}
              tableName='intents-records-table'
              seacrhButtonColor='secondary'
              maxTableHeight={750}
              withVirtualList
            />
          </div>
          {!!valErrorData && !!valErrorData.length && !isValErrorInNewItem && (
            <div className='mt-4'>
              <ValidationErrorPanel
                usageArea='intent'
                type='questions'
                updateWithoutValidation={() => {
                  onChangeItem(filteredItems, undefined, false);
                }}
                handleLinkToAnswer={handleValErrorLinks}
                validationErrorData={valErrorData}
              />
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            color: 'var(--grey-500)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span style={{ marginTop: 20 }}>{t('IntentItemsEdit:emptyList')}</span>
        </div>
      )}
    </div>
  );
};

export default IntentItemsEdit;
