import React, { FC, useMemo } from 'react';
import { CustomTagData } from '../../../../../Editor/api/client';
import { TJBlock } from '../../../../utils/types';
import { StringField } from './String.field';
import { tWithCheck } from '../../../../../../localization';
import { StringArray } from './StringArray';
import { NameValueArray } from './NameValueArray';
import { JsonField } from './Json.field';
import { BoolField } from './Bool.field';
import { StateField } from './State.field';
import { HtmlField } from './Html.field';
import Banner, { BannerType } from 'modules/JGraph/components/Banner';
import { isEuroInstance } from '../../../../../../isAccessFunction';
import { useRightSideMenuContext } from '../../index';
import { ParametersField } from './Parameters';

export const CommonCustomTagSettings: FC<{ descriptor: CustomTagData; block: TJBlock }> = ({ descriptor, block }) => {
  const { editMenuScreen } = useRightSideMenuContext();

  const textDescription = useMemo(() => {
    return tWithCheck(
      `CustomTag:${block.tagName}:description`,
      isEuroInstance() ? 'https://help.platform.tovie.ai' : 'https://help.just-ai.com'
    );
  }, [block.tagName]);

  return (
    <div className='JGraph-RightSideMenu-commonContainer' key={editMenuScreen.path + block.tagName}>
      {textDescription && (
        <Banner
          type={BannerType.INFO}
          content={<div dangerouslySetInnerHTML={{ __html: textDescription }} />}
          className='margin-bottom-4x'
        />
      )}
      {descriptor.parameters?.map(tagParam => {
        const label = tWithCheck(`CustomTag:${block.tagName}:param:${tagParam.name!}`);
        const description = tWithCheck(`CustomTag:${block.tagName}:param:${tagParam.name!}:description`);
        switch (tagParam.type) {
          case 'integer':
          case 'string': {
            return (
              <StringField
                key={tagParam.name!}
                name={tagParam.name!}
                label={label}
                type={tagParam.type === 'integer' ? 'number' : 'text'}
                description={description}
              />
            );
          }
          case 'intents':
          case 'stringArray': {
            return <StringArray key={tagParam.name!} name={tagParam.name!} label={label} description={description} />;
          }
          case 'nameValueList': {
            return (
              <NameValueArray key={tagParam.name!} name={tagParam.name!} label={label} description={description} />
            );
          }
          case 'parameters': {
            return (
              <ParametersField key={tagParam.name!} name={tagParam.name!} label={label} description={description} />
            );
          }
          case 'json': {
            return <JsonField key={tagParam.name!} name={tagParam.name!} label={label} description={description} />;
          }
          case 'bool': {
            return <BoolField key={tagParam.name!} name={tagParam.name!} label={label} description={description} />;
          }
          case 'state': {
            return <StateField key={tagParam.name!} name={tagParam.name!} label={label} description={description} />;
          }
          case 'html': {
            return <HtmlField key={tagParam.name!} name={tagParam.name!} label={label} description={description} />;
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  );
};
