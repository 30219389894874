import {
  LOAD_FEATURES_FULFILLED,
  LOAD_FEATURES_PENDING,
  LOAD_FEATURES_REJECTED,
  LOAD_DEFAULT_FEATURES_PENDING,
  LOAD_DEFAULT_FEATURES_REJECTED,
  LOAD_DEFAULT_FEATURES_FULFILLED,
  SAVE_PROJECT_FEATURE_FULFILLED,
} from '../../constants/features.actions';

const initialState = {
  projectFeatures: [],
  defaultProjectFeatures: [],
  systemFeatures: null,

  basicFetching: false,
  defaultFetching: false,
};
export default function FeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FEATURES_PENDING: {
      return {
        ...state,
        basicFetching: true,
      };
    }
    case LOAD_FEATURES_REJECTED: {
      return {
        ...state,
        basicFetching: false,
      };
    }
    case LOAD_FEATURES_FULFILLED: {
      return {
        ...state,
        projectFeatures: action.payload.data.projectFeatures,
        systemFeatures: action.payload.data.systemFeatures,
        basicFetching: false,
      };
    }
    case LOAD_DEFAULT_FEATURES_PENDING: {
      return {
        ...state,
        defaultFetching: true,
      };
    }
    case LOAD_DEFAULT_FEATURES_REJECTED: {
      return {
        ...state,
        defaultFetching: false,
      };
    }
    case LOAD_DEFAULT_FEATURES_FULFILLED: {
      return {
        ...state,
        defaultProjectFeatures: action.payload.data,
        defaultFetching: false,
      };
    }

    case SAVE_PROJECT_FEATURE_FULFILLED: {
      let defaultProjectFeatures = [...state.defaultProjectFeatures];
      const featureIndex = defaultProjectFeatures.findIndex(feature => feature.id === action.payload.config.id);
      if (featureIndex !== -1) {
        defaultProjectFeatures[featureIndex].enabled = action.payload.config.enabled;
      }
      return {
        ...state,
        defaultProjectFeatures: defaultProjectFeatures,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
