import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ChannelsReducer from './channels.reducer';
import EditChannelReducer from './editChannel.reducer';
import LivechatChannelsReducer from './livechatChannels.reducer';
import EditLivechatChannelReducer from './editLivechatChannel.reducer';
import DeployHistoryReducer from './deployHistory.reducer';
import ClientsListReducer from './clientsList.reducers/';
import CurrentClientReducer from './currentClient.reducers/';
import UsersListReducer from './userslist.reducers/';
import DialogsReducer from './dialogs.reducer/';
import DialogsDetailReducer from './dialogsDetail.reducer/';
import SystemDialogsSettingsReducer from './SystemDialogsSettingsReducer.reducer/';
import TasksReducer from './tasks.reducer/';
import AccountsReducer from './accounts.reducer/';
import SipTrunkReducer from './sipTrunkList.reducer';
import NewsListReducer from './newsList.reducers/';
import NewsItemReducer from './newsItem.reducers/';
import ProjectsReducer from './projects.reducer';
import ProjectReducer from './project.reducer';
import EditProjectsReducer from './editProject.reducer';
import CurrentProjectsReducer from './currentProject.reducer';
import PageFlowReducer from './pageflow.reducer/';
import CurrentUserReducer from './currentUser.reducer';
import GlobalAlert from './globalAlert.reducer/';
import RegisterReducer from './register.reducer';
import FeaturesReducer from './features.reducer';
import PhrasesReducer from './phrases.reducer';
import AnalyticsFilterReducer from './analyticsFilter.reducer';
import ProjectReportFilterReducer from './projectReportFilter.reducer';
import DiagramAnalyticsReducer from './dialogsAnalitycs.reducer/';
import DeployInfoReducer from './deployInfo.reducer';
import PasswordRestoreReducer from './passwordRestore.reducer';
import NewPasswordReducer from './newPassword.reducer';
import UserLogReducer from './userLog.reducer';
import CallListsReducer from './callLists.reducer';
import CallListReducer from './callList.reducer';
import CallTaskListReducer from './callTaskList.reducer';
import CallTaskReducer from './callTask.reducer';
import CallTaskReportReducer from './callTaskReport.reducer';
import CurrentAccountReducer from './currentAccount.reducer';
import AccountManagerReducer from './accountManager.reducer';
import KeysAndPlansReducer from './keysAndPlans.reducer';
import AppConfigReducer from './appConfig.reducer';
import SalesRequestReducer from './salesRequest.reducer';
import IntegrationsReducer from './integrations.reducer';
import JGraphReducer from './JGraph.reducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ClientsListReducer,
    CurrentClientReducer,
    UsersListReducer,
    NewsListReducer,
    NewsItemReducer,
    ChannelsReducer,
    EditChannelReducer,
    LivechatChannelsReducer,
    EditLivechatChannelReducer,
    ProjectsReducer,
    EditProjectsReducer,
    CurrentProjectsReducer,
    PageFlowReducer,
    CurrentUserReducer,
    DeployHistoryReducer,
    DialogsReducer,
    DialogsDetailReducer,
    SystemDialogsSettingsReducer,
    ProjectReducer,
    TasksReducer,
    GlobalAlert,
    RegisterReducer,
    AccountsReducer,
    FeaturesReducer,
    PhrasesReducer,
    AnalyticsFilterReducer,
    ProjectReportFilterReducer,
    DiagramAnalyticsReducer,
    DeployInfoReducer,
    PasswordRestoreReducer,
    NewPasswordReducer,
    CallListsReducer,
    CallListReducer,
    SipTrunkReducer,
    CallTaskListReducer,
    CallTaskReducer,
    CallTaskReportReducer,
    CurrentAccountReducer,
    UserLogReducer,
    KeysAndPlansReducer,
    AccountManagerReducer,
    AppConfigReducer,
    SalesRequestReducer,
    IntegrationsReducer,
    JGraphReducer,
  });

export default rootReducer;
