import { useState, useCallback } from 'react';
const initialState = {
    result: null,
    loading: false,
    error: null,
};
export default function usePromiseProcessing(promiseFunc, config = {}) {
    const [{ result, error, loading }, setPromiseInfo] = useState(initialState);
    const deps = config.deps || [];
    const run = useCallback((isOptimistic) => (...args) => {
        setPromiseInfo(prev => (Object.assign(Object.assign({}, prev), { loading: !isOptimistic })));
        return promiseFunc(...args)
            .then(data => {
            setPromiseInfo({
                result: data,
                loading: false,
                error: null,
            });
            config.onSuccess && config.onSuccess(data);
            return data;
        })
            .catch(error => {
            setPromiseInfo({
                result: null,
                loading: false,
                error: error.errorMessage || error.message,
            });
            config.onError && config.onError(error);
            if (config.throwOnError)
                throw error;
            return error;
        })
            .then(data => {
            config.onFinish && config.onFinish();
            return data;
        });
    }, 
    // eslint-disable-next-line
    [...deps]);
    const func = useCallback(run(false), [run]);
    const optimistic = useCallback(run(true), [run]);
    const reset = useCallback(() => setPromiseInfo(initialState), []);
    return [
        {
            result,
            loading,
            error,
            reset,
        },
        func,
        optimistic,
    ];
}
