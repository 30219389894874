export var projectoverviewLocalization = {
    'ProjectOverview: tab volumes': {
        eng: 'Volumes',
        ru: 'Объемы',
        pt: 'Volumes',
    },
    'ProjectOverview: tab results': {
        eng: 'Results',
        ru: 'Результаты',
        pt: 'Resultados',
    },
    'ProjectOverview: tab goals': {
        eng: 'Business goals',
        ru: 'Бизнес-цели',
        pt: 'Metas comerciais',
    },
    'ProjectOverview: noInfo title clients': {
        eng: 'Volumes',
        ru: 'Объемы',
        pt: 'Volumes',
    },
    'ProjectOverview: noInfo message clients': {
        eng: 'Client, dialog, and message statistics will appear here',
        ru: 'Когда появится трафик, здесь будет статистика по количеству клиентов, диалогов и сообщений',
        pt: 'O cliente, o diálogo e as estatísticas de mensagem aparecerão aqui',
    },
    'ProjectOverview: noInfo buttonLabel clients': {
        eng: 'Connect to a channel',
        ru: 'Подключить к каналу',
        pt: 'Conectar-se a um canal',
    },
    'ProjectOverview: noInfo helpTitle clients': {
        eng: 'How to analyze traffic volumes?',
        ru: 'Как анализировать объемы трафика?',
        pt: 'Como analisar os volumes de tráfego?',
    },
    'ProjectOverview: volumes title': {
        eng: 'Volumes',
        ru: 'Объемы',
        pt: 'Volumes',
    },
    'ProjectOverview: title clients': {
        eng: 'Total number of clients',
        ru: 'Число клиентов',
        pt: 'Número total de clientes',
    },
    'ProjectOverview: title messages': {
        eng: 'Total number of messages',
        ru: 'Число сообщений',
        pt: 'Número total de mensagens',
    },
    'ProjectOverview: title dialogs': {
        eng: 'Total number of dialogs',
        ru: 'Число диалогов',
        pt: 'Número total de diálogos',
    },
    'ProjectOverview: interval select DAY': {
        eng: 'Daily',
        ru: 'По дням',
        pt: 'Diário',
    },
    'ProjectOverview: interval select WEEK': {
        eng: 'Weekly',
        ru: 'По неделям',
        pt: 'Semanal',
    },
    'ProjectOverview: interval select MONTH': {
        eng: 'Monthly',
        ru: 'По месяцам',
        pt: 'Mensal',
    },
    'ProjectOverview: interval select HOUR': {
        eng: 'Hourly',
        ru: 'По часам',
        pt: 'Por hora',
    },
    'ProjectOverview: legend old clients': {
        eng: 'Returning',
        ru: 'Вернувшиеся',
        pt: 'Antigos',
    },
    'ProjectOverview: legend new clients': {
        eng: 'New',
        ru: 'Новые',
        pt: 'Novos',
    },
    'ProjectOverview: legend new messages': {
        eng: 'Recognized',
        ru: 'Распознанные',
        pt: 'Reconhecidas',
    },
    'ProjectOverview: legend old messages': {
        eng: 'Unrecognized',
        ru: 'Нераспознанные',
        pt: 'Não reconhecidas',
    },
    'ProjectOverview: legend old dialogs': {
        eng: 'Empty',
        ru: 'Пустой',
        pt: 'Vazio',
    },
    'ProjectOverview: legend new dialogs': {
        eng: 'Not empty',
        ru: 'Не пустой',
        pt: 'Não vazio',
    },
    'ProjectOverview: tab option clients': {
        eng: 'Clients',
        ru: 'Клиенты',
        pt: 'Clientes',
    },
    'ProjectOverview: tab option messages': {
        eng: 'Messages',
        ru: 'Сообщения',
        pt: 'Mensagens',
    },
    'ProjectOverview: tab option dialogs': {
        eng: 'Sessions',
        ru: 'Диалоги',
        pt: 'Sessões',
    },
    'ProjectOverview: tooltip clients': {
        eng: 'The chart illustrates the ratio of new and returning clients who talked to the bot during the time period.\n\nA client is a unique channel user.\nNew clients only talked to the bot once.\nReturning clients talked to the bot at least twice.',
        ru: 'График показывает соотношение новых и повторных клиентов бота за период времени.\n\nКлиент — уникальный пользователь в пределах канала.\nНовый — пользователь, впервые поговоривший с ботом.\nВернувшийся — пользователь, ранее уже обращавшийся к боту.',
        pt: 'O gráfico ilustra a taxa de clientes novos e antigos que conversaram com o bot durante o período de tempo.\n\nUm cliente é um usuário de canal único.\nNovos clientes só conversaram com o bot uma vez.\nOs clientes antigos conversaram com o bot pelo menos duas vezes.',
    },
    'ProjectOverview: tooltip messages': {
        eng: 'The chart illustrates the ratio of recognized and unrecognized messages.\n\nA message is considered recognized if it was not empty, not a /start command, and it did not trigger the noMatch event.',
        ru: 'График показывает соотношение распознанных и нераспознанных сообщений.\n\nРаспознанное сообщение — сообщение пользователя (не пустое, не /start), которое не сопровождается событием noMatch.',
        pt: 'O gráfico ilustra a taxa de mensagens reconhecidas e não reconhecidas.\n\nUma mensagem é considerada reconhecida se não tiver ficado em branco, não contiver um comando start/ e não tiver acionado o evento noMatch.',
    },
    'ProjectOverview: tooltip dialogs': {
        eng: 'The chart illustrates the ratio of dialogs that were and were not empty.\n\nA dialog is a unique session.\nA dialog is empty if it does not contain any messages from the client.\nA message is either regular text input (not empty and not a /start command) or fileEvent.',
        ru: 'График показывает соотношение пустых и не пустых диалогов.\n\nДиалог — это сценарная сессия.\nПустой диалог — не содержащий ни одного сообщения клиента.\nПод сообщением понимается событие fileEvent или текстовая фраза (не пустая и не /start).',
        pt: 'O gráfico ilustra a taxa de diálogos que estavam e não estavam vazios.\n\nUm diálogo é uma sessão única.\nUm diálogo está vazio se não contiver uma mensagem do cliente.\nUma mensagem é uma entrada regular de texto (não vazia e não é um comando /start) ou um fileEvent.',
    },
    'ProjectOverview: noInfo client helpLink': {
        eng: '/docs/en/platform_ux/volumes',
        ru: '/docs/ru/platform_ux/volumes',
    },
    'ProjectOverview: year one': {
        eng: 'year',
        ru: 'год',
        pt: 'ano',
    },
    'ProjectOverview: year several': {
        eng: 'years',
        ru: 'лет',
        pt: 'anos',
    },
    'ProjectOverview: year some': {
        eng: 'years',
        ru: 'года',
        pt: 'anos',
    },
    'ProjectOverview: month one': {
        eng: 'month',
        ru: 'месяц',
        pt: 'mês',
    },
    'ProjectOverview: month some': {
        eng: 'months',
        ru: 'месяца',
        pt: 'meses',
    },
    'ProjectOverview: month several': {
        eng: 'months',
        ru: 'месяцев',
        pt: 'meses',
    },
    'ProjectOverview: day one': {
        eng: 'day',
        ru: 'день',
        pt: 'dia',
    },
    'ProjectOverview: day some': {
        eng: 'days',
        ru: 'дня',
        pt: 'dias',
    },
    'ProjectOverview: day several': {
        eng: 'days',
        ru: 'дней',
        pt: 'dias',
    },
    'ProjectOverview:volumeTable rowLabel date and time': {
        eng: 'Date and time',
        ru: 'Дата и время',
        pt: 'Data e hora',
    },
    'ProjectOverview: label volume table': {
        eng: 'Period comparison by volumes',
        ru: 'Сравнение периодов по объемам',
        pt: 'Comparação de período por volumes',
    },
    'ProjectOverview: tooltip volume table': {
        eng: 'The table contains the total number of sessions, dialogs, and messages at the start and end of two periods.',
        ru: 'Таблица содержит значение метрик (число клиентов, диалогов и сообщений) на начало и конец двух периодов.',
        pt: 'A tabela contém o número total de sessões, diálogos e mensagens no início e no fim de dois períodos.',
    },
    'ProjectOverview: export table button label': {
        eng: 'Table export',
        ru: 'Экспорт таблицы',
        pt: 'Exportação da tabela',
    },
    'ProjectOverview: noInfo helpLink clients': {
        eng: '/docs/en/platform_ux/volumes',
        ru: '/docs/ru/platform_ux/volumes',
    },
    'ProjectOverview: legend system messages': {
        eng: 'System',
        ru: 'Системные',
        pt: 'Sistema',
    },
    'ProjectOverview: noInfo title results': {
        eng: 'Results',
        ru: 'Результаты',
        pt: 'Resultados',
    },
    'ProjectOverview: noInfo message results': {
        eng: 'To be able to analyze result statistics, set session results in the script',
        ru: 'Чтобы анализировать статистику по результатам, добавьте запись результатов в сценарии',
        pt: 'Para conseguir analisar estatísticas de resultado, defina os resultados de sessão no script',
    },
    'ProjectOverview: noInfo helpTitle results': {
        eng: 'How to set session results?',
        ru: 'Как записать результат диалога?',
        pt: 'Como definir resultados de sessão?',
    },
    'ProjectOverview: noInfo helpLink results': {
        eng: '/docs/en/platform_ux/results',
        ru: '/docs/ru/platform_ux/results',
    },
    'ProjectOverview: title results': {
        eng: 'Results',
        ru: 'Результаты',
        pt: 'Resultados',
    },
    'ProjectOverview: tooltip results': {
        eng: 'A dialog result is the status of client–bot interaction recorded in the script.\n\nTo display results, add labels to screens or <b>$analytics.setSessionResult</b> in the Code block in your script.\nThe result is fixed as soon as a client ends the dialog on such a screen. <a href="$[1]">Learn more in the documentation</a>',
        ru: 'Результат диалога — зафиксированный в сценарии статус диалога клиента с ботом.\n\nЧтобы результаты отображались, в сценарии добавьте на экраны метки или <b>$analytics.setSessionResult</b> в блоке Код.\nРезультат фиксируется, если клиент заканчивает диалог на таком экране. <a href="$[1]">Подробнее в документации</a>',
        pt: 'O resultado de um diálogo é o status da interação entre cliente e bot gravada no script.\n\nPara exibir resultados, adicione rótulos a telas ou <b>$analytics.setSessionResult</b> no bloco de código no seu script.\nO resultado é fixado assim que o cliente terminar o diálogo em tal tela.<a href="$[1]">Saiba mais na documentação</a>',
    },
    'ProjectOverview: announcement without link': {
        eng: 'To display results, add labels to screens or <b>$analytics.setSessionResult</b> in the Code block in your script. <a href="$[1]">Learn more in the documentation</a>',
        ru: 'Чтобы результаты отображались, в сценарии добавьте на экраны метки или <b>$analytics.setSessionResult</b> в блоке Код. <a href="$[1]">Подробнее в документации</a>',
        pt: 'Para exibir resultados, adicione rótulos a telas ou <b>$analytics.setSessionResult</b> no bloco de código no seu script.<a href="$[1]">Saiba mais na documentação</a>',
    },
    'ProjectOverview: announcement': {
        eng: 'No results are set for any of the selected dialogs. Use <b>$analytics.setSessionResult</b> in the script. Read more in $[1]',
        ru: 'Все выбранные диалоги не содержат результатов. Добавьте <b>$analytics.setSessionResult</b> в код сценария. Читайте подробнее в $[1]',
        pt: 'Nenhum resultado está definido para qualquer um dos diálogos selecionados. Use <b>$analytics.setSessionResult</b> no script. Leia mais em $[1]',
    },
    'ProjectOverview: label results table': {
        eng: 'Period comparison by results',
        ru: 'Сравнение периодов по результатам',
        pt: 'Comparação de período por resultados',
    },
    'ProjectOverview: tooltip results table': {
        eng: 'The total number of sessions finished with all possible results during both periods. $[1]',
        ru: 'Количество сессий, завершившихся с каждым из результатов, в первом и втором периоде. $[1]',
        pt: 'O número total de sessões finalizou com todos os resultados possíveis durante os dois períodos. $[1]',
    },
    'ProjectOverview: tooltip results button text': {
        eng: 'How to set results',
        ru: 'Как зафиксировать результат',
        pt: 'Como definir resultados',
    },
    'ProjectOverview: tooltip results button link': {
        eng: '/docs/en/JS_API/built_in_services/analytics/setSessionResult',
        ru: '/docs/ru/JS_API/built_in_services/analytics/setSessionResult',
    },
    'AimylogicBotOverview:NoChannels:Title': {
        eng: 'There will be a graph with the number of clients',
        ru: 'Здесь будет график по количеству клиентов у бота',
        pt: 'Haverá um gráfico com o número de clientes',
    },
    'AimylogicBotOverview:NoChannels:Button': {
        eng: 'Connect channel',
        ru: 'Подключить канал',
        pt: 'Conectar canal',
    },
};
