import {
  AccountInvitationEndpointApi,
  AccountsEndpointV2Api,
  AccountUserEndpointApi,
  InviteUserDto,
  RolesEndpointApi,
  UpdateAccountAsOwnerDto,
  UserEndpointApi,
  UserSearchEndpointApi,
} from '../api/client';
import { axios } from '../../../pipes/functions';

export default class ProjectGroupsService {
  static readonly BASE_PATH = '';
  private UserEndpointApi: UserEndpointApi;
  private AccountsEndpointApi: AccountsEndpointV2Api;
  private AccountInvitationEndpointApi: AccountInvitationEndpointApi;
  private UserSearchEndpointApi: UserSearchEndpointApi;
  private AccountUserEndpointApi: AccountUserEndpointApi;
  private RolesEndpointApi: RolesEndpointApi;

  constructor() {
    this.UserEndpointApi = new UserEndpointApi({}, ProjectGroupsService.BASE_PATH, axios);
    this.AccountsEndpointApi = new AccountsEndpointV2Api({}, ProjectGroupsService.BASE_PATH, axios);
    this.AccountInvitationEndpointApi = new AccountInvitationEndpointApi({}, ProjectGroupsService.BASE_PATH, axios);
    this.UserSearchEndpointApi = new UserSearchEndpointApi({}, ProjectGroupsService.BASE_PATH, axios);
    this.AccountUserEndpointApi = new AccountUserEndpointApi({}, ProjectGroupsService.BASE_PATH, axios);
    this.RolesEndpointApi = new RolesEndpointApi({}, ProjectGroupsService.BASE_PATH, axios);
  }

  getAllowedAccounts = async (userId: number) => {
    return (await this.UserEndpointApi.getAllowedAccounts(userId)).data;
  };

  createProjectGroup = async (fullName: string, gRecaptchaResponse: string) => {
    return (
      await this.AccountsEndpointApi.createAccount(
        { fullName },
        {
          headers: { 'g-recaptcha-response': gRecaptchaResponse },
        }
      )
    ).data;
  };

  getAccount = async (accountId: number) => {
    return (await this.AccountsEndpointApi.getAccount(accountId)).data;
  };

  updateProjectGroup = async (accountId: number, { fullName, enabled }: UpdateAccountAsOwnerDto) => {
    return (await this.AccountsEndpointApi.updateAccount(accountId, { fullName, enabled })).data;
  };

  inviteUser = async ({ login, roles, accountId }: InviteUserDto, gRecaptchaResponse: string) => {
    return (
      await this.AccountInvitationEndpointApi.inviteUser(
        { login, roles, accountId },
        {
          headers: { 'g-recaptcha-response': gRecaptchaResponse },
        }
      )
    ).data;
  };

  getInvitationsByUser = async (userId: number) => {
    return (await this.AccountInvitationEndpointApi.getInvitationsByUser(userId)).data;
  };

  acceptInvitationByUserId = async (userId: number, accountId: number) => {
    return (await this.AccountInvitationEndpointApi.acceptInvitationByUserId(userId, accountId)).data;
  };

  acceptInvitationByToken = async (userId: number, token: string) => {
    return (await this.AccountInvitationEndpointApi.acceptInvitationByToken(userId, token)).data;
  };

  deleteUserFromAccount = async (accountId: number, userId: number) => {
    return (await this.AccountUserEndpointApi.deleteUserFromAccount(accountId, userId)).data;
  };

  deleteInvitation = async (invitationId: number) => {
    return (await this.AccountInvitationEndpointApi.deleteInvitation(invitationId)).data;
  };

  findAccountUsers = async ({
    accountId,
    page,
    size,
    searchText,
  }: {
    accountId: number;
    page: number;
    size: number;
    searchText: string;
  }) => {
    return (await this.UserSearchEndpointApi.findAccountUsers(accountId, page, size, searchText)).data;
  };

  findInvitations = async ({
    accountId,
    page,
    size,
    searchText,
  }: {
    accountId: number;
    page: number;
    size: number;
    searchText: string;
  }) => {
    return (await this.AccountInvitationEndpointApi.findByLoginOrFullNameInAccount(accountId, page, size, searchText))
      .data;
  };

  changeAccountRoles = async (accountId: number, userId: number, roles: string[]) => {
    return (await this.AccountUserEndpointApi.changeAccountRoles(accountId, userId, { roles: roles })).data;
  };

  changeInvitationRoles = async (invitationId: number, roles: string[]) => {
    return (await this.AccountInvitationEndpointApi.changeInvitationRoles(invitationId, { roles: roles })).data;
  };

  getRolesAvailableToAccount = async (accountId: number) => {
    const { data } = await this.RolesEndpointApi.getRolesAvailableToAccount(accountId);
    return data.filter((role: { name: string; editable: boolean }) => role.editable);
  };

  checkIsCaptchaNeeded = async (userId: number, actionType: string) => {
    return await this.UserEndpointApi.isCaptchaNeeded(userId, actionType);
  };
}
