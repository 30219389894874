import React, { FC, ReactElement } from 'react';
import { FormFeedback, InputText, TextareaAutosize } from '@just-ai/just-ui';

import { t } from 'localization';
import { withController } from './Controller';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';

interface StringFieldProps extends CommonFieldProps {
  type?: 'text' | 'number';
  multiline?: boolean;
  multilineRowsMin?: number;
  multilineRowsMax?: number;
  RightSideLabelElement?: ReactElement;
}

const StringFieldView: FC<StringFieldProps> = ({
  name,
  label,
  isRequired,
  value,
  onChange,
  onBlur,
  invalid,
  type = 'text',
  description,
  multiline = false,
  multilineRowsMin = 2,
  multilineRowsMax = 5,
  RightSideLabelElement,
}) => {
  return (
    <div className='form-group form-group-fix overflow-hidden'>
      <CommonLabel
        name={name}
        label={label}
        description={description}
        isRequired={isRequired}
        RightSideElement={RightSideLabelElement}
      />
      {multiline ? (
        <TextareaAutosize
          rowsMin={multilineRowsMin}
          rowsMax={multilineRowsMax}
          id='text'
          value={value}
          onChange={onChange}
        />
      ) : (
        <InputText name={name} onChange={onChange} value={value} invalid={invalid} onBlur={onBlur} type={type} />
      )}

      {isRequired && (
        <FormFeedback tag='div' valid={false}>
          {t('Required Field')}
        </FormFeedback>
      )}
    </div>
  );
};

export const StringField = withController(StringFieldView);
