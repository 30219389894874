export const errorsLocalization = {
  'Errors:You cannot create more JAICF projects with JAICP Cloud environment type.': {
    eng: 'You cannot create more JAICF projects in JAICP Cloud',
    ru: 'Вы не можете создать больше JAICF проектов в облаке JAICP',
  },
  'Errors:ECONNABORTED': {
    eng: 'Error: timeout of request exceeded',
    ru: 'Ошибка: превышен тайм-аут запроса',
    cn: '错误：请求超时',
  },
  'Errors:UnknownError': {
    eng: 'Unknown Error!',
    ru: 'Неизвестная ошибка!',
    cn: '未知错误！',
  },
  'Errors:uploadDialogMultipleFilesError': {
    eng: 'Upload only one file.',
    ru: 'Выбрано более одного файла.',
    cn: '仅上传一个文件',
  },
  'Errors:caila.common.common_error': {
    eng: 'Something went wrong. Please, contact the technical support about this error.',
    ru: 'Что-то пошло не так. Пожалуйста, обратитесь в службу технической поддержки по поводу этой ошибки.',
    cn: '出错。请联系技术支持。',
  },
  'Errors:caila.db_constraints__intents.intent_with_provided_path_already_exists': {
    eng: 'Intent with provided path already exists',
    ru: 'Интент с таким названием уже существует',
    cn: '于该路径的意图已存在',
  },
  'Errors:caila.intents.intent_with_provided_path_already_exists': {
    eng: 'Intent with provided path already exists',
    ru: 'Интент с таким названием уже существует',
    cn: '于该路径的意图已存在',
  },
  'Errors:caila.intents.shared_intent_with_provided_path_already_exists': {
    eng: 'Shared intent with provided path already exists',
    ru: 'Общий интент с таким названием уже существует',
    cn: '于该路径的意图已存在',
  },
  'Errors:caila.db_constraints__projects.project_with_provided_id_does_not_exist': {
    eng: 'Project with provided ID does not exist',
    ru: 'Проект с указанным ID не существует',
    cn: '带该ID的项目不存在',
  },
  'Errors:caila.db_constraints__projects.project_with_provided_id_already_exists': {
    eng: 'Project with provided ID already exists',
    ru: 'Проект с указанным ID уже существует',
    cn: '带该ID的项目已存在',
  },
  'Errors:caila.db_constraints__projects.project_with_provided_path_already_exists': {
    eng: 'This account already contains project with provided name and folder',
    ru: 'Эта учетная запись уже содержит проект с указанным именем и папкой',
    cn: '此帐户已包含具有该名称和文件夹的项目',
  },
  'Errors:caila.db_constraints__entities.entity_with_provided_name_already_exists': {
    eng: 'Entity with provided name already exists',
    ru: 'Сущность с таким названием уже существует',
    cn: '已存在该名称的实体',
  },
  'Errors:caila.db_constraints__records.record_with_provided_value_already_exists': {
    eng: 'Record with provided value already exists',
    ru: 'Запись с указанным значением уже существует',
    cn: '已存在该值的记录',
  },
  'Errors:caila.common.argument_type_mismatch': {
    eng: 'Invalid type for variable $[1]. Required type: $[2]',
    ru: 'Неверный тип для переменной $[1]. Обязательный тип: $[2]',
    cn: '变量$[1]的类型无效。要求类型：$[2]',
  },
  'Errors:caila.common.exception_handling_error': {
    eng: 'An error occurred during the exception handling',
    ru: 'Произошла ошибка во время обработки исключения',
    cn: '异常处理期间出错',
  },
  'Errors:caila.common.resource_not_found': {
    eng: 'Resource for url $[1] is not found',
    ru: 'Ресурс для URL $[1] не найден',
    cn: '找不到url$[1]的资源',
  },
  'Errors:caila.access.user_not_authorized': {
    eng: 'No authenticated user found',
    ru: 'Аутентифицированный пользователь не найден',
    cn: '找不到已通过身份验证的用户',
  },
  'Errors:caila.access.wrong_account_id': {
    eng: 'Request path contains account ID that differs from ID of authenticated account',
    ru: 'Путь запроса содержит идентификатор учетной записи, который отличается от идентификатора аутентифицированной учетной записи',
    cn: '所请求路径包含与已验证帐户不同的ID',
  },
  'Errors:caila.access.api_usage_not_permitted': {
    eng: 'Current user does not have CailaPub feature',
    ru: 'Текущий пользователь не имеет фичи CailaPub',
    cn: '当前用户没有CailaPub功能',
  },
  'Errors:caila.projects.access_key_not_found': {
    eng: 'Access key for project with ID [$[1]] not found',
    ru: 'Ключ доступа для проекта с идентификатором [$[1]] не найден',
    cn: '找不到ID为$[1]的项目的访问密钥',
  },
  'Errors:caila.projects.project_with_provided_id_already_exists': {
    eng: 'Project with ID $[1] already exists',
    ru: 'Проект с идентификатором $[1] уже существует',
    cn: 'ID为$[1]的项目已存在',
  },
  'Errors:caila.projects.project_with_provided_id_not_found': {
    eng: 'Project with ID $[1] not found',
    ru: 'Проект с идентификатором $[1] не найден',
    cn: '找不到ID为$[1]的项目',
  },
  'Errors:caila.projects.import_project_data_must_not_be_null': {
    eng: 'Project field in import data must not be null',
    ru: 'Поле проекта в данных импорта не должно быть пустым',
    cn: '导入数据中的项目字段不能为空',
  },
  'Errors:caila.projects.name_must_not_be_empty': {
    eng: 'Project name must not be empty',
    ru: 'Название проекта не должно быть пустым',
    cn: '项目名称不能为空',
  },
  'Errors:botadmin.validation.not_empty_field': {
    eng: 'The $[1] field must not be empty',
    ru: 'Поле $[1] не должно быть пустым',
  },
  'Errors:botadmin.validation.max_length_field': {
    eng: 'The maximum number of characters for the "$[1]" field is 255',
    ru: 'Максимальное количество символов в поле "$[1]": 255',
  },
  'Errors:caila.projects.project_folder_must_start_with_slash': {
    eng: 'Project folder must start with the / character',
    ru: 'Папка проекта должна начинаться с символа /',
    cn: '项目文件夹的开头必须是个斜杠',
  },
  'Errors:caila.projects.project_id_invalid': {
    eng: 'Project ID inside json object must be null or must be equal to the path parameter',
    ru: 'Идентификатор проекта внутри объекта json должен быть нулевым или равным параметру пути',
    cn: 'json对象中的项目ID必须为空或等于路径参数',
  },
  'Errors:caila.projects.settings_project_id_empty': {
    eng: 'Project ID in project settings must not be empty',
    ru: 'Идентификатор проекта в настройках проекта не должен быть пустым',
    cn: '项目设置中的项目ID不能为空',
  },
  'Errors:caila.projects.settings_account_id_empty': {
    eng: 'Account ID in project settings must not be empty',
    ru: 'Идентификатор аккаунта в настройках проекта не должен быть пустым',
    cn: '项目设置中的帐户ID不能为空',
  },
  'Errors:caila.projects.settings_invalid_timezone': {
    eng: 'Project timezone $[1] is invalid',
    ru: 'Часовой пояс проекта $[1] недействителен',
    cn: '项目时区$[1]无效',
  },
  'Errors:caila.projects.error_deleting': {
    eng: 'Could not delete project. Retry deleting it.',
    ru: 'Не удалось удалить проект. Попробуйте удалить ещё раз.',
  },
  'Errors:caila.projects.invalid_language_for_spelling_correction': {
    eng: 'Spelling correction is not supported for selected project language. Turn off spelling correction',
    ru: 'Исправление орфографии не поддерживается для выбранного языка проекта. Выключите опцию "Исправление орфографии"',
  },
  'Errors:caila.intents.intent_id_in_path_differs_from_body_id': {
    eng: 'Intent ID in path differs from ID in request body',
    ru: 'ID интента в пути отличается от ID в теле запроса',
    cn: '路径中的意图ID与请求正文中的ID不一致',
  },
  'Errors:caila.intents.enabled_must_not_be_empty': {
    eng: 'Intent enabled field must not be null',
    ru: 'Поле с интентом не должно быть пустым',
    cn: '启用意图的字段不能为空',
  },
  'Errors:caila.intents.path_must_not_be_empty': {
    eng: 'Intent path field must not be empty',
    ru: 'Поле Intent Path не должно быть пустым',
    cn: '意图路径字段不能为空',
  },
  'Errors:caila.intents.path_must_start_with_slash': {
    eng: 'Intent path must start with the / character',
    ru: 'Путь интента должен начинаться с символа /',
    cn: '意图路径开头必须是一个斜杠',
  },
  'Errors:caila.intents.intent_with_provided_id_not_found': {
    eng: 'Intent with ID $[1] not found',
    ru: 'Интент с ID $[1] не найден',
    cn: '找不到ID为$[1]的意图',
  },
  'Errors:caila.entities.entity_with_provided_name_already_exists': {
    eng: 'Entity with provided name already exists',
    ru: 'Сущность с таким именем уже существует',
  },
  'Errors:caila.entities.entity_name_same_as_system_entity_name': {
    eng: 'Entity name must not be same as system entity name',
    ru: 'Имя сущности совпадает с именем существующей системной сущности',
  },
  'Errors:caila.entities.shared_entity_with_provided_name_already_exists': {
    eng: 'Shared entity with provided name already exists',
    ru: 'Общая сущность с таким именем уже существует',
  },
  'Errors:caila.entities.entity_with_provided_id_not_found': {
    eng: 'Entity with ID $[1] not found',
    ru: 'Сущность с ID $[1] не найдена',
    cn: '找不到ID为$[1]的实体',
  },
  'Errors:caila.entities.entity_upload_dictionary_invalid_format': {
    eng: 'Uploaded entity zb-csv invalid format: $[1]',
    ru: 'Неверный формат загруженного объекта zb-csv: $[1]',
    cn: '上传的实体zb-csv格式无效：$[1]',
  },
  'Errors:caila.entities.entity_upload_dictionary_duplicate_ids': {
    eng: 'Uploaded entity zb-csv contains duplicate ID: $[1]',
    ru: 'Загруженная сущность zb-csv содержит дублирующий идентификатор: $[1]',
    cn: '上传的实体zb-csv包含重复id:$[1]',
  },
  'Errors:caila.entities.entity_upload_dictionary_invalid_json_value': {
    eng: 'Error when loading a JSON object from file: $[1]',
    ru: 'Ошибка при загрузке JSON-объекта из файла: $[1]',
  },
  'Errors:caila.entities.entity_record_with_provided_id_not_found': {
    eng: 'Entity record with ID $[1] not found',
    ru: 'Запись сущности с ID $[1] не найдена',
    cn: '找不到ID为$[1]的实体记录',
  },
  'Errors:caila.entities.system_entity_name_invalid': {
    eng: 'System entity with name $[1] does not exist',
    ru: 'Системная сущность с именем $[1] не существует',
    cn: '名称为$[1]的系统实体不存在',
  },
  'Errors:caila.entities.entity_name_invalid': {
    eng: 'Entity name contains invalid characters',
    ru: 'Имя сущности содержит недопустимые символы',
  },
  'Errors:caila.entities.too_much_entity_search_results': {
    eng: 'The limit on the number of recognized entities is exceeded',
    ru: 'Превышен лимит на количество распознанных сущностей во фразе',
  },
  'Errors:caila.inference.analyze_data_must_not_be_null': {
    eng: 'Data must be specified',
    ru: 'Данные должны быть указаны',
    cn: '必须指定数据',
  },
  'Errors:caila.inference.phrase_text_must_be_specified': {
    eng: 'Text must be specified',
    ru: 'Текст должен быть указан',
    cn: '必须指定文本',
  },
  'Errors:caila.inference.inference_multiple_phrases_must_not_be_null': {
    eng: 'Phrases must be specified',
    ru: 'Фразы должны быть указаны',
    cn: '必须指定短语',
  },
  'Errors:caila.inference.classification_timeout': {
    eng: 'Intent classification request exceeded timeout $[1] ms',
    ru: 'Запрос на классификацию интентов превысил лимит ожидания $[1] мс',
  },
  'Errors:caila.inference.pattern_ner_timeout': {
    eng: 'NER request exceeded timeout $[1] ms',
    ru: 'Запрос на извлечение сущностей (NER) превысил лимит ожидания $[1] мс',
  },
  'Errors:caila.inference.too_many_requests': {
    eng: 'You have sent too many requests. Please wait and try again later.',
    ru: 'Вы отправили слишком много запросов. Пожалуйста, подождите и повторите позже.',
  },
  'Errors:caila.inference.training_too_many_requests': {
    eng: 'You have sent too many requests for project training. Please wait and train the project later.',
    ru: 'Вы отправили слишком много запросов на переобучение проекта. Пожалуйста, подождите и обучите проект позже.',
  },
  'Errors:caila.nlp.language_not_supported': {
    eng: 'Project language: $[1] is not supported.',
    ru: 'Язык проекта: $[1] не поддерживается.',
    cn: '不支持项目语言：$[1]。',
  },
  'Errors:caila.nlp.markup_engines_content_invalid': {
    eng: 'language-default-settings markup-engines content is invalid',
    ru: 'language-default-settings markup-engines содержание недействительно',
    cn: '语言默认设置标记引擎内容无效',
  },
  'Errors:caila.nlp.nlp_engine_should_not_be_null': {
    eng: 'language-default-settings file content is invalid. Nlp-engine should not be null',
    ru: 'language-default-settings содержимое файла недействительно. NLP-engine не должен быть NULL',
    cn: '语言默认设置文件内容无效。NLP引擎不应为空',
  },
  'Errors:caila.nlp.failed_to_markup_entities_for_language': {
    eng: 'Failed to markup entities for language $[1]',
    ru: 'Не удалось разметить сущности для языка $[1]',
    cn: '无法标记语言$[1]的实体',
  },
  'Errors:caila.nlp.failed_to_convert_entity_markup_to_phrase_markup': {
    eng: 'Failed to convert entity markup to phrase markup',
    ru: 'Не удалось преобразовать разметку сущности в разметку фразы',
    cn: '无法将实体标记转换为短语标记',
  },
  'Errors:caila.patterns_extraction.patterns_not_allowed_in_entities_and_intents': {
    eng: 'Patterns are not allowed in entities and intents',
    ru: 'В сущностях и интентах не доспускаются шаблоны',
    cn: '实体中不允许使用模式',
  },
  'Errors:caila.patterns_extraction.star_ref_not_allowed': {
    eng: 'Star references are not allowed in entities',
    ru: 'Символ * нельзя использовать в начале или конце паттернов в сущностях',
    cn: '实体中不允许使用星号引用',
  },
  'Errors:caila.patterns_extraction.linearization_failed': {
    eng: 'Failed to linearize entities: $[1]',
    ru: 'Не удалось разрешить зависимости между сущностями: $[1]. Проверьте, что сущности, на которые ссылается $[1], существуют',
    cn: '无法线性化实体：$[1]',
  },
  'Errors:caila.extractors.zb_extractor_not_found_for_language': {
    eng: 'ZB extractor not found for language $[1]',
    ru: 'ZB экстрактор не найден для языка $[1]',
    cn: '找不到语言$[1]的ZB提取器',
  },
  'Errors:caila.markup.invalid_project_language': {
    eng: 'Invalid project language',
    ru: 'Неверный язык проекта',
    cn: '无效的项目语言',
  },
  'Errors:caila.markup.markup_too_long': {
    eng: 'This phrase is too long: $[1] > $[2]. To process it, shorten its length.',
    ru: 'Слишком длинная фраза: $[1] > $[2]. Сократите ее.',
  },
  'Errors:caila.classification.model_not_ready': {
    eng: 'Model not ready',
    ru: 'Модель не готова',
    cn: '模型未就绪',
  },
  'Errors:caila.classification.failed_to_register_classifier': {
    eng: 'Failed to register classifier for project: $[1]',
    ru: 'Не удалось зарегистрировать классификатор для проекта: $[1]',
    cn: '未能注册项目$[1]的分类器',
  },
  'Errors:caila.classification.failed_to_load_model': {
    eng: 'Failed to load model with ID $[1] for project $[2]',
    ru: 'Не удалось загрузить модель с идентификатором $[1] для проекта $[2]',
    cn: '无法为项目$[2]加载id为$[1]的模型',
  },
  'Errors:caila.classification.predict_failed': {
    eng: 'CAILA classification failed with message: $[1]',
    ru: 'Не удалось классифицировать CAILA с сообщением: $[1]',
    cn: 'CAILA分类失败，消息为$[1]',
  },
  'Errors:caila.log_labeling.phrases_exceed_max_amount': {
    eng: 'Failed to create log labeling session: phrases amount exceeds 10.000',
    ru: 'Не удалось создать сессию разметки логов: количество фраз превышает 10.000',
    cn: '无法创建日志标记会话：短语数量超过10000',
  },
  'Errors:caila.log_labeling.log_labeling_session_with_provided_id_not_found': {
    eng: 'Log labeling session with ID $[1] not found',
    ru: 'Сессия разметки логов с ID $[1] не найдена',
    cn: '找不到ID为$[1]的日志标记会话',
  },
  'Errors:caila.import.entities_invalid_content': {
    eng: 'Invalid file content to import entities',
    ru: 'Недопустимое содержимое файла для импорта сущностей',
  },
  'Errors:caila.import.intents_invalid_content': {
    eng: 'Invalid file content to import intents',
    ru: 'Недопустимое содержимое файла для импорта интентов',
  },
  'Errors:caila.extended_settings.tokenizer_engine.invalid_language_for_engine': {
    eng: 'Invalid markup engine for selected project language',
    ru: 'Токенизатор не совместим с заданным языком проекта. Укажите другой токенизатор.',
  },
  'Errors:caila.extended_settings.tokenizer_invalid': {
    eng: 'Invalid tokenizer engine $[1]',
    ru: 'Указан неизвестный токенизатор $[1]',
  },
  'Errors:caila.extended_settings.lucene_analyzer_invalid': {
    eng: 'Invalid Lucene analyzer $[1]',
    ru: 'Указан неизвестный или неподдерживаемый анализатор Lucene $[1]',
  },
  'Errors:caila.extended_settings.cnn_settings_invalid': {
    eng: 'Invalid CNN settings $[1]',
    ru: 'Ошибка в настройках CNN-классификатора $[1]',
  },
  'Errors:caila.extended_settings.classicml_settings_invalid': {
    eng: 'Invalid Classic ML settings $[1]',
    ru: 'Ошибка в настройках классификатора Classic ML $[1]',
  },
  'Errors:caila.extended_settings.sts_settings_invalid': {
    eng: 'Invalid STS settings $[1]',
    ru: 'Ошибка в настройках STS-классификатора $[1]',
  },
  'Errors:caila.extended_settings.logreg_settings_invalid': {
    eng: 'Invalid logreg settings $[1]',
    ru: 'Ошибка в параметрах логистической регрессии (Classic ML) $[1]',
  },
  'Errors:caila.patterns.patterns_invalid': {
    eng: 'Errors were found in patterns \n $[1]',
    ru: 'В паттернах обнаружены ошибки \n $[1]',
  },
  'Errors:editorbe.storage.cannot_auto_merge': {
    eng: 'Cannot apply auto-merge, choose another strategy for conflict resolution.',
    ru: 'Невозможно выполнить автослияние. Выберите другую стратегию разрешения конфликтов.',
  },
  'Errors:editorbe.scenarios.state_already_exists': {
    eng: 'State already exists',
    ru: 'Стейт с таким именем уже существует',
  },
  'Errors:editorbe.repository.error.default': {
    eng: 'Cannot complete the operation with the repository. Make sure repository details are correct. Check repository access settings.',
    ru: 'Невозможно выполнить операцию с репозиторием. Убедитесь, что данные репозитория указаны верно. Проверьте настройки доступа к репозиторию.',
  },
  'Errors:editorbe.templates.template_not_found': {
    eng: 'Cannot clone the repository. Make sure the repository details are correct. Check the repository access settings.',
    ru: 'Невозможно склонировать репозиторий. Убедитесь, что данные репозитория указаны верно. Проверьте настройки доступа к репозиторию.',
  },
  'Errors:editorbe.templates.failed_to_clone_remote_template': {
    eng: 'Cannot clone the repository. Make sure the repository details are correct. Check the repository access settings.',
    ru: 'Невозможно склонировать репозиторий. Убедитесь, что данные репозитория указаны верно. Проверьте настройки доступа к репозиторию.',
  },
  'Errors:editorbe.repository.error.not_authorized': {
    eng: 'Cannot access the external repository. Make sure the authentication details are correct and the user has the permissions required for the operation.',
    ru: 'Внешний репозиторий недоступен. Убедитесь, что данные аутентификации указаны верно и пользователь имеет права на выполняемую операцию.',
  },
  'Errors:editorbe.repository.error.invalid_remote': {
    eng: 'Cannot access the external repository. Make sure the repository URL is correct.',
    ru: 'Внешний репозиторий недоступен. Убедитесь, что URL репозитория указан верно.',
  },
  'Errors:editorbe.repository.error.invalid_branch': {
    eng: 'The branch is missing or not accessible. Make sure the branch exists and check access permissions.',
    ru: 'Ветка отсутствует или недоступна. Убедитесь в наличии ветки и проверьте права доступа.',
  },
  'Errors:chatwidget.error.title': {
    eng: 'Chat widget',
    ru: 'Чат-виджет',
  },
  'Errors:chatwidget.load.error': {
    eng: 'Failed to open the chat widget',
    ru: 'Не удалось открыть чат-виджет',
  },
  'Errors:editorbe.repository.error.repo_oversize': {
    eng: 'The project should not exceed $[1] MB',
    ru: 'Проект не должен превышать $[1] Мб',
  },
  'Errors:editorbe.repository.error.secret_key_does_not_match': {
    eng: 'The key you have entered does not match the key in the project repository.',
    ru: 'Введенный ключ не совпадает с ключом в репозитории проекта.',
  },
  'Errors:editorbe.export_import.failed_to_import_new_version_project': {
    eng: 'Importing project error',
    ru: 'Ошибка при импорте проекта',
  },
  'Errors:editorbe.storage.file_modified_on_server': {
    eng: 'The file was modified on the server',
    ru: 'Файл изменён на сервере',
    cn: '服务器上的文件已经被修改',
  },
  'Errors:editorbe.storage.cannot_save_file': {
    eng: 'Error saving file',
    ru: 'Ошибка при сохранении файла',
  },
  'Errors:editorbe.storage.cannot_update_with_unsaved_changes': {
    eng: 'Save file and resolve conflicts before pulling',
    ru: 'Сначала сохраните ваши текущие изменения и разрешите конфликты',
  },
  'Errors:editorbe.scenarios.graph_disabled_scenario': {
    eng: 'Graph editor disabled for this file',
    ru: 'Графический редактор отключен для этого файла',
  },
  'Errors:editorbe.search.incorrect_query': {
    eng: 'Search query should contain 3 to 20 characters.',
    ru: 'Поисковый запрос должен содержать от 3 до 20 символов.',
  },
  'Errors:editorbe.search.incorrect_regex_query': {
    eng: 'Please check your regular expression.',
    ru: 'Проверьте правильность регулярного выражения.',
  },
  'Errors:editorbe.search.regex_and_whole_word_in_query_true': {
    eng: 'You can only search in either regular expression or whole word mode at a time.',
    ru: 'Используйте только поиск в режиме регулярных выражений или совпадений по целому слову.',
  },
  'Errors:editorbe.import.caila_import_failed': {
    eng: 'Errors were found while importing project file',
    ru: 'Обнаружены ошибки при импорте файла проекта',
  },
  'Errors:error': {
    eng: 'Error',
    ru: 'Ошибка',
    cn: '错误',
  },
  'Errors:blockWithThisIDAlreadyExist': {
    eng: 'Such a block ID already exists',
    ru: 'Блок с таким ID уже существует',
    cn: '这个板块ID已经存在',
  },
  'Errors:Not Found': {
    eng: 'Internal API error',
    ru: 'Внутренняя ошибка API',
  },
  'Errors:Disable spellchecker': {
    eng: 'Spelling correction is disabled',
    ru: 'Исправление орфографии отключено',
  },
  'Errors:editorbe.validation.branch_does_not_exists': {
    eng: 'The specified branch does not exist',
    ru: 'Указанная ветка не существует',
  },
  'Errors:caila.faq.import.wrong_enabled_value': {
    eng: '! Critical: Invalid cell value in the Enabled column: $[2] row: $[1]. Possible values: TRUE, FALSE; 1, 0.',
    ru: '! Критическая: Недопустимое значение ячейки в столбце Enabled: столбец: $[2] строка: $[1]. Возможные значения: TRUE, FALSE; 1, 0.',
  },
  'Errors:caila.faq.import.file_too_large': {
    eng: 'File size cannot exceed $[1] MB.',
    ru: 'Размер файла не должен превышать $[1] Мб.',
  },
  'Errors:caila.faq.import.max_intent_number_exceeded': {
    eng: '! Critical: Maximum number of rows: $[1].',
    ru: '! Критическая: Максимальное количество строк: $[1].',
  },
  'Errors:caila.faq.import.required_header_missing': {
    eng: '! Critical: Required headers missing: $[1].',
    ru: '! Критическая: Отсутствуют обязательные столбцы: $[1].',
  },
  'Errors:caila.faq.import.empty_question_and_group': {
    eng: '! Critical: Values in the Question and Group columns cannot be missing at the same time: column: $[2] row: $[1].',
    ru: '! Критическая: Значения в столбцах Question и Group не могут отсутствовать одновременно: столбец: $[2] строка: $[1].',
  },
  'Errors:caila.faq.import.empty_question': {
    eng: '! Critical: Cell values in the Question column cannot be empty, except if you want to create an empty group. In this case, clear all the other cells in this row as well: column: $[2] row: $[1].',
    ru: '! Критическая: Значение ячейки в столбце Question не может быть пустым, если только вы не хотите создать пустую группу. В таком случае также очистите все остальные ячейки в этой строке: столбец: $[2] строка: $[1].',
  },
  'Errors:caila.faq.import.duplicated_header_value': {
    eng: '! Critical: Column headers must be unique: $[1].',
    ru: '! Критическая: Названия столбцов должны быть уникальными: $[1].',
  },
  'Errors:caila.faq.import.extension_not_supported': {
    eng: '! Critical: File format or file extension is not valid. Verify that the file has not been corrupted and that the file extension matches the format of the file.',
    ru: '! Критическая: Недопустимый формат или расширение файла. Убедитесь, что файл не поврежден и что расширение файла соответствует формату файла.',
  },
  'Errors:caila.faq.import.cannot_parse_file': {
    eng: '! Critical: File format or file extension is not valid. Verify that the file has not been corrupted and that the file extension matches the format of the file.',
    ru: '! Критическая: Недопустимый формат или расширение файла. Убедитесь, что файл не поврежден и что расширение файла соответствует формату файла.',
  },
  'Errors:caila.faq.import.invalid_header': {
    eng: '“$[1]” is not a valid column header. Possible headers: Group, Question, Enabled, Alternative phrases-Text, Alternative phrases-Patterns, Answer.',
    ru: 'Недопустимый заголовок для столбца: “$[1]”. Возможные заголовки: Group, Question, Enabled, Alternative phrases-Text, Alternative phrases-Patterns, Answer.',
  },
  'Errors:caila.faq.import.header_cannot_be_empty': {
    eng: 'Header cannot be empty: $[1]. The column will not be imported.',
    ru: 'Заголовок не может быть пустым: $[1]. Столбец не будет импортирован.',
  },
  'Errors:caila.faq.import.wrong_pattern': {
    eng: 'Invalid pattern: $[4] row: $[1] column: $[2] line: $[3].',
    ru: 'Ошибка в паттерне: $[4] ряд: $[1] столбец: $[2] строка: $[3].',
  },
  'Errors:caila.faq.import.empty_answer_type': {
    eng: 'The type is not specified for the answer (row $[1], column $[2]). The default value will be used: text',
    ru: 'Для ответа не указан тип (строка $[1], столбец $[2]). Будет использовано значение по умолчанию: text.',
  },
  'Errors:caila.faq.import.no_answer_type_column': {
    eng: 'Answer type column is empty. Default value: text.',
    ru: 'Не заполнен столбец Answer type. Значение по умолчанию: text.',
  },
  'Errors:caila.faq.import.wrong_answer_type': {
    eng: 'Wrong answer type value: row $[1], column $[2].',
    ru: 'Неподдерживаемый тип ответа: строка $[1], столбец $[2].',
  },
  'Errors:caila.faq.import.max_replies_number_exceeded': {
    eng: '! Critical: The maximum number of messages in a reply exceeded ($[1]): row $[2].',
    ru: '! Критическая: Превышено максимальное количество сообщений в ответе ($[1]): строка $[2].',
  },
  'Errors:caila.classification.too_much_phrases_for_sts': {
    eng: 'Too many phrases for the STS algorithm: $[1] > $[2]. To process them, select another classification algorithm in the project settings.',
    ru: 'Превышен лимит фраз для классификатора STS: $[1] > $[2]. Чтобы обработать их, смените алгоритм классификатора в настройках проекта.',
  },
  'Errors:validation:the_text_is_too_short': {
    eng: 'The text is too short.',
    ru: 'Текст слишком короткий.',
  },
  'Errors:validation:the_text_is_too_long': {
    eng: 'The text is too long.',
    ru: 'Текст слишком длинный.',
  },
  'Errors:validation:the_number_should_be_greater': {
    eng: 'The number should be greater.',
    ru: 'Число должно быть больше.',
  },
  'Errors:validation:the_number_should_be_less': {
    eng: 'The number should be less.',
    ru: 'Число должно быть меньше.',
  },
  'Errors:Channels:isLoadingFile': {
    eng: 'File is loading. Please wait',
    ru: 'Файл загружается. Пожалуйста подождите',
    cn: '正在加载文件。请稍候',
  },
};
