// tslint:disable
//@ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AccessTokenData
 */
export interface AccessTokenData {
  /**
   *
   * @type {string}
   * @memberof AccessTokenData
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenData
   */
  creationDate: string;
}
/**
 *
 * @export
 * @interface AccountFeatureData
 */
export interface AccountFeatureData {
  /**
   *
   * @type {Array<FeatureData>}
   * @memberof AccountFeatureData
   */
  features: Array<FeatureData>;
}
/**
 *
 * @export
 * @interface AccountInfoData
 */
export interface AccountInfoData {
  /**
   *
   * @type {number}
   * @memberof AccountInfoData
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof AccountInfoData
   */
  shortName: string;
  /**
   *
   * @type {AccountLimitsData}
   * @memberof AccountInfoData
   */
  limits: AccountLimitsData;
  /**
   *
   * @type {string}
   * @memberof AccountInfoData
   */
  language?: LanguageEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LanguageEnum {
  RU = 'RU',
  EN = 'EN',
}

/**
 *
 * @export
 * @interface AccountLimitsData
 */
export interface AccountLimitsData {
  /**
   *
   * @type {number}
   * @memberof AccountLimitsData
   */
  baseModelInstanceLimit: number;
  /**
   *
   * @type {number}
   * @memberof AccountLimitsData
   */
  derivedModelInstanceLimit: number;
  /**
   *
   * @type {string}
   * @memberof AccountLimitsData
   */
  cpuLimit: string;
  /**
   *
   * @type {string}
   * @memberof AccountLimitsData
   */
  memoryLimit: string;
  /**
   *
   * @type {string}
   * @memberof AccountLimitsData
   */
  ephemeralDiskLimit: string;
  /**
   *
   * @type {string}
   * @memberof AccountLimitsData
   */
  gpuLimit: string;
  /**
   *
   * @type {number}
   * @memberof AccountLimitsData
   */
  predictPerSec: number;
  /**
   *
   * @type {number}
   * @memberof AccountLimitsData
   */
  fitPerMinute: number;
}
/**
 *
 * @export
 * @interface Check
 */
export interface Check {
  /**
   *
   * @type {string}
   * @memberof Check
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof Check
   */
  critical: boolean;
  /**
   *
   * @type {Status}
   * @memberof Check
   */
  status: Status;
}
/**
 *
 * @export
 * @interface ConfigCreateUpdateData
 */
export interface ConfigCreateUpdateData {
  /**
   *
   * @type {string}
   * @memberof ConfigCreateUpdateData
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConfigCreateUpdateData
   */
  _default?: boolean;
  /**
   *
   * @type {string}
   * @memberof ConfigCreateUpdateData
   */
  config: string;
}
/**
 *
 * @export
 * @interface CrossValidationRequestData
 */
export interface CrossValidationRequestData {
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  baseModelAccountId?: number;
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  baseModelId?: number;
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  targetModelAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof CrossValidationRequestData
   */
  targetModelName?: string;
  /**
   *
   * @type {object}
   * @memberof CrossValidationRequestData
   */
  fitConfig?: object;
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  fitConfigId?: number;
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  datasetId: number;
  /**
   *
   * @type {object}
   * @memberof CrossValidationRequestData
   */
  predictConfig?: object;
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  predictConfigId?: number;
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  parts: number;
  /**
   *
   * @type {number}
   * @memberof CrossValidationRequestData
   */
  minIntentSizeToDivide: number;
}
/**
 *
 * @export
 * @interface DataImageInfoData
 */
export interface DataImageInfoData {
  /**
   *
   * @type {DataImageInfoPK}
   * @memberof DataImageInfoData
   */
  id?: DataImageInfoPK;
  /**
   *
   * @type {string}
   * @memberof DataImageInfoData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataImageInfoData
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof DataImageInfoData
   */
  sourcePath: string;
}
/**
 *
 * @export
 * @interface DataImageInfoPK
 */
export interface DataImageInfoPK {
  /**
   *
   * @type {number}
   * @memberof DataImageInfoPK
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof DataImageInfoPK
   */
  imageId: number;
}
/**
 *
 * @export
 * @interface DataImageMountData
 */
export interface DataImageMountData {
  /**
   *
   * @type {number}
   * @memberof DataImageMountData
   */
  dmId?: number;
  /**
   *
   * @type {number}
   * @memberof DataImageMountData
   */
  dataImageId: number;
  /**
   *
   * @type {string}
   * @memberof DataImageMountData
   */
  dataImage?: string;
  /**
   *
   * @type {string}
   * @memberof DataImageMountData
   */
  dataImageName?: string;
  /**
   *
   * @type {string}
   * @memberof DataImageMountData
   */
  sourcePath?: string;
  /**
   *
   * @type {string}
   * @memberof DataImageMountData
   */
  targetPath: string;
}
/**
 *
 * @export
 * @interface DatasetInfoData
 */
export interface DatasetInfoData {
  /**
   *
   * @type {DatasetPK}
   * @memberof DatasetInfoData
   */
  id?: DatasetPK;
  /**
   *
   * @type {string}
   * @memberof DatasetInfoData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DatasetInfoData
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetInfoData
   */
  dataType?: string;
}
/**
 *
 * @export
 * @interface DatasetInfoWithContentData
 */
export interface DatasetInfoWithContentData {
  /**
   *
   * @type {DatasetPK}
   * @memberof DatasetInfoWithContentData
   */
  id?: DatasetPK;
  /**
   *
   * @type {string}
   * @memberof DatasetInfoWithContentData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DatasetInfoWithContentData
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetInfoWithContentData
   */
  dataType?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetInfoWithContentData
   */
  content?: string;
}
/**
 *
 * @export
 * @interface DatasetPK
 */
export interface DatasetPK {
  /**
   *
   * @type {number}
   * @memberof DatasetPK
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof DatasetPK
   */
  datasetId: number;
}
/**
 *
 * @export
 * @interface EventData
 */
export interface EventData {
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  createdTimestamp?: string;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  reason?: string;
  /**
   *
   * @type {EventSource}
   * @memberof EventData
   */
  source?: EventSource;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  firstSeenTimestamp?: string;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  lastSeenTimestamp?: string;
  /**
   *
   * @type {number}
   * @memberof EventData
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  kind?: string;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  fieldPath?: string;
  /**
   *
   * @type {string}
   * @memberof EventData
   */
  namespace: string;
}
/**
 *
 * @export
 * @interface EventSource
 */
export interface EventSource {
  /**
   *
   * @type {string}
   * @memberof EventSource
   */
  component?: string;
  /**
   *
   * @type {string}
   * @memberof EventSource
   */
  host?: string;
}
/**
 *
 * @export
 * @interface ExternalConnectionInfoData
 */
export interface ExternalConnectionInfoData {
  /**
   *
   * @type {ModelInfoData}
   * @memberof ExternalConnectionInfoData
   */
  model: ModelInfoData;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionInfoData
   */
  connectionToken?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalConnectionInfoData
   */
  activeConnections?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionInfoData
   */
  externalUrl?: string;
}
/**
 *
 * @export
 * @interface FeatureData
 */
export interface FeatureData {
  /**
   *
   * @type {string}
   * @memberof FeatureData
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof FeatureData
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface FitConfigData
 */
export interface FitConfigData {
  /**
   *
   * @type {FitConfigPK}
   * @memberof FitConfigData
   */
  id: FitConfigPK;
  /**
   *
   * @type {string}
   * @memberof FitConfigData
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof FitConfigData
   */
  _default: boolean;
  /**
   *
   * @type {string}
   * @memberof FitConfigData
   */
  config: string;
}
/**
 *
 * @export
 * @interface FitConfigPK
 */
export interface FitConfigPK {
  /**
   *
   * @type {number}
   * @memberof FitConfigPK
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof FitConfigPK
   */
  modelId: number;
  /**
   *
   * @type {number}
   * @memberof FitConfigPK
   */
  configId: number;
}
/**
 *
 * @export
 * @interface FitRequestData
 */
export interface FitRequestData {
  /**
   *
   * @type {object}
   * @memberof FitRequestData
   */
  config?: object;
  /**
   *
   * @type {number}
   * @memberof FitRequestData
   */
  configId?: number;
  /**
   *
   * @type {object}
   * @memberof FitRequestData
   */
  dataset?: object;
  /**
   *
   * @type {number}
   * @memberof FitRequestData
   */
  datasetId?: number;
}
/**
 *
 * @export
 * @interface HealthCheckResult
 */
export interface HealthCheckResult {
  /**
   *
   * @type {boolean}
   * @memberof HealthCheckResult
   */
  ok: boolean;
  /**
   *
   * @type {string}
   * @memberof HealthCheckResult
   */
  status: string;
  /**
   *
   * @type {Array<Check>}
   * @memberof HealthCheckResult
   */
  checks: Array<Check>;
}
/**
 *
 * @export
 * @interface ImageInfoData
 */
export interface ImageInfoData {
  /**
   *
   * @type {ImageInfoPK}
   * @memberof ImageInfoData
   */
  id?: ImageInfoPK;
  /**
   *
   * @type {string}
   * @memberof ImageInfoData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ImageInfoData
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof ImageInfoData
   */
  taskType?: string;
}
/**
 *
 * @export
 * @interface ImageInfoPK
 */
export interface ImageInfoPK {
  /**
   *
   * @type {number}
   * @memberof ImageInfoPK
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof ImageInfoPK
   */
  imageId: number;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  file: any;
}
/**
 *
 * @export
 * @interface InstanceEnvironmentData
 */
export interface InstanceEnvironmentData {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof InstanceEnvironmentData
   */
  environment: { [key: string]: string };
}
/**
 *
 * @export
 * @interface InstanceEventData
 */
export interface InstanceEventData {
  /**
   *
   * @type {Array<EventData>}
   * @memberof InstanceEventData
   */
  events: Array<EventData>;
}
/**
 *
 * @export
 * @interface InstancesStatusData
 */
export interface InstancesStatusData {
  /**
   *
   * @type {number}
   * @memberof InstancesStatusData
   */
  internalRequested: number;
  /**
   *
   * @type {number}
   * @memberof InstancesStatusData
   */
  internalActive: number;
  /**
   *
   * @type {number}
   * @memberof InstancesStatusData
   */
  internalInError: number;
  /**
   *
   * @type {number}
   * @memberof InstancesStatusData
   */
  externalActive: number;
}
/**
 *
 * @export
 * @interface JobStatusData
 */
export interface JobStatusData {
  /**
   *
   * @type {number}
   * @memberof JobStatusData
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof JobStatusData
   */
  jobId: number;
  /**
   *
   * @type {string}
   * @memberof JobStatusData
   */
  jobType: string;
  /**
   *
   * @type {string}
   * @memberof JobStatusData
   */
  state: string;
  /**
   *
   * @type {boolean}
   * @memberof JobStatusData
   */
  done: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JobStatusData
   */
  error?: boolean;
  /**
   *
   * @type {string}
   * @memberof JobStatusData
   */
  errorMessage?: string;
}
/**
 *
 * @export
 * @interface ManagementRequestData
 */
export interface ManagementRequestData {
  /**
   *
   * @type {string}
   * @memberof ManagementRequestData
   */
  contacts: string;
  /**
   *
   * @type {string}
   * @memberof ManagementRequestData
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface Measurement
 */
export interface Measurement {
  /**
   *
   * @type {number}
   * @memberof Measurement
   */
  ts: number;
  /**
   *
   * @type {number}
   * @memberof Measurement
   */
  v: number;
}
/**
 *
 * @export
 * @interface ModelBatchesData
 */
export interface ModelBatchesData {
  /**
   *
   * @type {number}
   * @memberof ModelBatchesData
   */
  batchSize: number;
  /**
   *
   * @type {number}
   * @memberof ModelBatchesData
   */
  timeWaitMs: number;
}
/**
 *
 * @export
 * @interface ModelCreateUpdateData
 */
export interface ModelCreateUpdateData {
  /**
   *
   * @type {string}
   * @memberof ModelCreateUpdateData
   */
  modelName: string;
  /**
   *
   * @type {number}
   * @memberof ModelCreateUpdateData
   */
  imageId?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCreateUpdateData
   */
  trainingModelAccountId?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCreateUpdateData
   */
  trainingModelId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelCreateUpdateData
   */
  trainingType?: TrainingTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ModelCreateUpdateData
   */
  trainingDatasetId?: number;
  /**
   *
   * @type {number}
   * @memberof ModelCreateUpdateData
   */
  trainingFitConfigId?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelCreateUpdateData
   */
  _public?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelCreateUpdateData
   */
  config?: string;
  /**
   *
   * @type {string}
   * @memberof ModelCreateUpdateData
   */
  env?: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelCreateUpdateData
   */
  fittable?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum TrainingTypeEnum {
  SingleContainer = 'singleContainer',
  MultipleFit = 'multipleFit',
}

/**
 *
 * @export
 * @interface ModelDefaults
 */
export interface ModelDefaults {
  /**
   *
   * @type {ModelTimeoutsData}
   * @memberof ModelDefaults
   */
  timeouts: ModelTimeoutsData;
  /**
   *
   * @type {ModelRetriesData}
   * @memberof ModelDefaults
   */
  retries: ModelRetriesData;
  /**
   *
   * @type {ModelBatchesData}
   * @memberof ModelDefaults
   */
  batches: ModelBatchesData;
  /**
   *
   * @type {ModelLimitsData}
   * @memberof ModelDefaults
   */
  limits: ModelLimitsData;
  /**
   *
   * @type {string}
   * @memberof ModelDefaults
   */
  resourceGroup: string;
}
/**
 *
 * @export
 * @interface ModelInfoData
 */
export interface ModelInfoData {
  /**
   *
   * @type {ModelInfoPK}
   * @memberof ModelInfoData
   */
  id: ModelInfoPK;
  /**
   *
   * @type {string}
   * @memberof ModelInfoData
   */
  modelAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof ModelInfoData
   */
  modelName: string;
  /**
   *
   * @type {number}
   * @memberof ModelInfoData
   */
  imageId?: number;
  /**
   *
   * @type {ImageInfoData}
   * @memberof ModelInfoData
   */
  image?: ImageInfoData;
  /**
   *
   * @type {number}
   * @memberof ModelInfoData
   */
  trainingDatasetId?: number;
  /**
   *
   * @type {DatasetInfoData}
   * @memberof ModelInfoData
   */
  trainingDataset?: DatasetInfoData;
  /**
   *
   * @type {number}
   * @memberof ModelInfoData
   */
  trainingFitConfigId?: number;
  /**
   *
   * @type {FitConfigData}
   * @memberof ModelInfoData
   */
  trainingFitConfig?: FitConfigData;
  /**
   *
   * @type {string}
   * @memberof ModelInfoData
   */
  taskType?: string;
  /**
   *
   * @type {number}
   * @memberof ModelInfoData
   */
  trainingModelAccountId?: number;
  /**
   *
   * @type {number}
   * @memberof ModelInfoData
   */
  trainingModelId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelInfoData
   */
  trainingType?: TrainingTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof ModelInfoData
   */
  _public?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelInfoData
   */
  config?: string;
  /**
   *
   * @type {string}
   * @memberof ModelInfoData
   */
  env?: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelInfoData
   */
  fittable: boolean;
  /**
   *
   * @type {Array<PersistentVolumeData>}
   * @memberof ModelInfoData
   */
  persistentVolumes: Array<PersistentVolumeData>;
  /**
   *
   * @type {Array<DataImageMountData>}
   * @memberof ModelInfoData
   */
  dataImageMounts: Array<DataImageMountData>;
  /**
   *
   * @type {string}
   * @memberof ModelInfoData
   */
  resourceGroup?: string;
  /**
   *
   * @type {ModelTimeoutsData}
   * @memberof ModelInfoData
   */
  timeouts: ModelTimeoutsData;
  /**
   *
   * @type {ModelLimitsData}
   * @memberof ModelInfoData
   */
  resourceLimits: ModelLimitsData;
  /**
   *
   * @type {ModelRetriesData}
   * @memberof ModelInfoData
   */
  retriesConfig: ModelRetriesData;
  /**
   *
   * @type {ModelBatchesData}
   * @memberof ModelInfoData
   */
  batchesConfig: ModelBatchesData;
}

/**
 *
 * @export
 * @interface ModelInfoPK
 */
export interface ModelInfoPK {
  /**
   *
   * @type {number}
   * @memberof ModelInfoPK
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof ModelInfoPK
   */
  modelId: number;
}
/**
 *
 * @export
 * @interface ModelInstance
 */
export interface ModelInstance {
  /**
   *
   * @type {ModelInstancePK}
   * @memberof ModelInstance
   */
  id: ModelInstancePK;
  /**
   *
   * @type {string}
   * @memberof ModelInstance
   */
  connectionToken: string;
  /**
   *
   * @type {Date}
   * @memberof ModelInstance
   */
  started: Date;
  /**
   *
   * @type {string}
   * @memberof ModelInstance
   */
  type: TypeEnum;
  /**
   *
   * @type {string}
   * @memberof ModelInstance
   */
  kubeType: KubeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ModelInstance
   */
  resourceName?: string;
  /**
   *
   * @type {string}
   * @memberof ModelInstance
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof ModelInstance
   */
  customData?: string;
  /**
   *
   * @type {Date}
   * @memberof ModelInstance
   */
  deleteTimestamp?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum TypeEnum {
  InitialCheck = 'initialCheck',
  SingleFit = 'singleFit',
  SingleFitPool = 'singleFitPool',
  MultipleFit = 'multipleFit',
  Predict = 'predict',
}
/**
 * @export
 * @enum {string}
 */
export enum KubeTypeEnum {
  Deployment = 'deployment',
  Pod = 'pod',
  External = 'external',
}

/**
 *
 * @export
 * @interface ModelInstanceData
 */
export interface ModelInstanceData {
  /**
   *
   * @type {number}
   * @memberof ModelInstanceData
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ModelInstanceData
   */
  name?: string;
  /**
   *
   * @type {StatusInfo}
   * @memberof ModelInstanceData
   */
  statusInfo: StatusInfo;
  /**
   *
   * @type {number}
   * @memberof ModelInstanceData
   */
  restartCount: number;
  /**
   *
   * @type {string}
   * @memberof ModelInstanceData
   */
  lastRestartTimestamp?: string;
  /**
   *
   * @type {string}
   * @memberof ModelInstanceData
   */
  createdTimestamp?: string;
}
/**
 *
 * @export
 * @interface ModelInstanceListData
 */
export interface ModelInstanceListData {
  /**
   *
   * @type {Array<ModelInstanceData>}
   * @memberof ModelInstanceListData
   */
  instances: Array<ModelInstanceData>;
}
/**
 *
 * @export
 * @interface ModelInstancePK
 */
export interface ModelInstancePK {
  /**
   *
   * @type {number}
   * @memberof ModelInstancePK
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof ModelInstancePK
   */
  modelId: number;
  /**
   *
   * @type {number}
   * @memberof ModelInstancePK
   */
  instanceId: number;
}
/**
 *
 * @export
 * @interface ModelLimitsData
 */
export interface ModelLimitsData {
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  cpuRequest: string;
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  cpuLimit: string;
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  memoryRequest: string;
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  memoryLimit: string;
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  ephemeralDiskRequest: string;
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  ephemeralDiskLimit: string;
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  gpuRequest: string;
  /**
   *
   * @type {string}
   * @memberof ModelLimitsData
   */
  gpuLimit: string;
}
/**
 *
 * @export
 * @interface ModelRetriesData
 */
export interface ModelRetriesData {
  /**
   *
   * @type {number}
   * @memberof ModelRetriesData
   */
  maxRetries: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ModelRetriesData
   */
  timeoutsMs: Array<number>;
}
/**
 *
 * @export
 * @interface ModelShortStatusData
 */
export interface ModelShortStatusData {
  /**
   *
   * @type {InstancesStatusData}
   * @memberof ModelShortStatusData
   */
  instances: InstancesStatusData;
  /**
   *
   * @type {JobStatusData}
   * @memberof ModelShortStatusData
   */
  lastJob?: JobStatusData;
}
/**
 *
 * @export
 * @interface ModelTimeoutsData
 */
export interface ModelTimeoutsData {
  /**
   *
   * @type {number}
   * @memberof ModelTimeoutsData
   */
  podStartTimeoutSec: number;
  /**
   *
   * @type {number}
   * @memberof ModelTimeoutsData
   */
  predictTimeoutSec: number;
}
/**
 *
 * @export
 * @interface PageDataImageInfoData
 */
export interface PageDataImageInfoData {
  /**
   *
   * @type {number}
   * @memberof PageDataImageInfoData
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageDataImageInfoData
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageDataImageInfoData
   */
  size?: number;
  /**
   *
   * @type {Array<DataImageInfoData>}
   * @memberof PageDataImageInfoData
   */
  content?: Array<DataImageInfoData>;
  /**
   *
   * @type {number}
   * @memberof PageDataImageInfoData
   */
  number?: number;
  /**
   *
   * @type {Sort}
   * @memberof PageDataImageInfoData
   */
  sort?: Sort;
  /**
   *
   * @type {PageableObject}
   * @memberof PageDataImageInfoData
   */
  pageable?: PageableObject;
  /**
   *
   * @type {boolean}
   * @memberof PageDataImageInfoData
   */
  first?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PageDataImageInfoData
   */
  last?: boolean;
  /**
   *
   * @type {number}
   * @memberof PageDataImageInfoData
   */
  numberOfElements?: number;
  /**
   *
   * @type {boolean}
   * @memberof PageDataImageInfoData
   */
  empty?: boolean;
}
/**
 *
 * @export
 * @interface PageImageInfoData
 */
export interface PageImageInfoData {
  /**
   *
   * @type {number}
   * @memberof PageImageInfoData
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageImageInfoData
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageImageInfoData
   */
  size?: number;
  /**
   *
   * @type {Array<ImageInfoData>}
   * @memberof PageImageInfoData
   */
  content?: Array<ImageInfoData>;
  /**
   *
   * @type {number}
   * @memberof PageImageInfoData
   */
  number?: number;
  /**
   *
   * @type {Sort}
   * @memberof PageImageInfoData
   */
  sort?: Sort;
  /**
   *
   * @type {PageableObject}
   * @memberof PageImageInfoData
   */
  pageable?: PageableObject;
  /**
   *
   * @type {boolean}
   * @memberof PageImageInfoData
   */
  first?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PageImageInfoData
   */
  last?: boolean;
  /**
   *
   * @type {number}
   * @memberof PageImageInfoData
   */
  numberOfElements?: number;
  /**
   *
   * @type {boolean}
   * @memberof PageImageInfoData
   */
  empty?: boolean;
}
/**
 *
 * @export
 * @interface PageModelInfoData
 */
export interface PageModelInfoData {
  /**
   *
   * @type {number}
   * @memberof PageModelInfoData
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof PageModelInfoData
   */
  totalElements?: number;
  /**
   *
   * @type {number}
   * @memberof PageModelInfoData
   */
  size?: number;
  /**
   *
   * @type {Array<ModelInfoData>}
   * @memberof PageModelInfoData
   */
  content?: Array<ModelInfoData>;
  /**
   *
   * @type {number}
   * @memberof PageModelInfoData
   */
  number?: number;
  /**
   *
   * @type {Sort}
   * @memberof PageModelInfoData
   */
  sort?: Sort;
  /**
   *
   * @type {PageableObject}
   * @memberof PageModelInfoData
   */
  pageable?: PageableObject;
  /**
   *
   * @type {boolean}
   * @memberof PageModelInfoData
   */
  first?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PageModelInfoData
   */
  last?: boolean;
  /**
   *
   * @type {number}
   * @memberof PageModelInfoData
   */
  numberOfElements?: number;
  /**
   *
   * @type {boolean}
   * @memberof PageModelInfoData
   */
  empty?: boolean;
}
/**
 *
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
  /**
   *
   * @type {number}
   * @memberof PageableObject
   */
  offset?: number;
  /**
   *
   * @type {Sort}
   * @memberof PageableObject
   */
  sort?: Sort;
  /**
   *
   * @type {number}
   * @memberof PageableObject
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageableObject
   */
  pageSize?: number;
  /**
   *
   * @type {boolean}
   * @memberof PageableObject
   */
  paged?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PageableObject
   */
  unpaged?: boolean;
}
/**
 *
 * @export
 * @interface PersistentVolumeData
 */
export interface PersistentVolumeData {
  /**
   *
   * @type {number}
   * @memberof PersistentVolumeData
   */
  pvId?: number;
  /**
   *
   * @type {string}
   * @memberof PersistentVolumeData
   */
  mountPath: string;
  /**
   *
   * @type {number}
   * @memberof PersistentVolumeData
   */
  sizeInGb: number;
  /**
   *
   * @type {string}
   * @memberof PersistentVolumeData
   */
  storageClass: string;
}
/**
 *
 * @export
 * @interface PredictConfigData
 */
export interface PredictConfigData {
  /**
   *
   * @type {PredictConfigPK}
   * @memberof PredictConfigData
   */
  id: PredictConfigPK;
  /**
   *
   * @type {string}
   * @memberof PredictConfigData
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof PredictConfigData
   */
  _default: boolean;
  /**
   *
   * @type {string}
   * @memberof PredictConfigData
   */
  config: string;
}
/**
 *
 * @export
 * @interface PredictConfigPK
 */
export interface PredictConfigPK {
  /**
   *
   * @type {number}
   * @memberof PredictConfigPK
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof PredictConfigPK
   */
  modelId: number;
  /**
   *
   * @type {number}
   * @memberof PredictConfigPK
   */
  configId: number;
}
/**
 *
 * @export
 * @interface PredictRequestData
 */
export interface PredictRequestData {
  /**
   *
   * @type {object}
   * @memberof PredictRequestData
   */
  config?: object;
  /**
   *
   * @type {object}
   * @memberof PredictRequestData
   */
  data: object;
}
/**
 *
 * @export
 * @interface ResourceGroupData
 */
export interface ResourceGroupData {
  /**
   *
   * @type {number}
   * @memberof ResourceGroupData
   */
  groupId?: number;
  /**
   *
   * @type {string}
   * @memberof ResourceGroupData
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ResourceGroupData
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof ResourceGroupData
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof ResourceGroupData
   */
  tolerations: string;
  /**
   *
   * @type {string}
   * @memberof ResourceGroupData
   */
  affinity: string;
  /**
   *
   * @type {boolean}
   * @memberof ResourceGroupData
   */
  _default?: boolean;
}
/**
 *
 * @export
 * @interface ResourceGroupsData
 */
export interface ResourceGroupsData {
  /**
   *
   * @type {Array<ResourceGroupData>}
   * @memberof ResourceGroupsData
   */
  groups: Array<ResourceGroupData>;
}
/**
 *
 * @export
 * @interface S3CredentialsData
 */
export interface S3CredentialsData {
  /**
   *
   * @type {string}
   * @memberof S3CredentialsData
   */
  s3Url: string;
  /**
   *
   * @type {string}
   * @memberof S3CredentialsData
   */
  accessKey: string;
  /**
   *
   * @type {string}
   * @memberof S3CredentialsData
   */
  secretKey: string;
  /**
   *
   * @type {string}
   * @memberof S3CredentialsData
   */
  bucketName: string;
}
/**
 *
 * @export
 * @interface Sort
 */
export interface Sort {
  /**
   *
   * @type {boolean}
   * @memberof Sort
   */
  empty?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Sort
   */
  sorted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Sort
   */
  unsorted?: boolean;
}
/**
 *
 * @export
 * @interface StatLogData
 */
export interface StatLogData {
  /**
   *
   * @type {number}
   * @memberof StatLogData
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof StatLogData
   */
  recordId: number;
  /**
   *
   * @type {number}
   * @memberof StatLogData
   */
  userId?: number;
  /**
   *
   * @type {number}
   * @memberof StatLogData
   */
  imageId?: number;
  /**
   *
   * @type {number}
   * @memberof StatLogData
   */
  modelId?: number;
  /**
   *
   * @type {number}
   * @memberof StatLogData
   */
  jobId?: number;
  /**
   *
   * @type {string}
   * @memberof StatLogData
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof StatLogData
   */
  level: LevelEnum;
  /**
   *
   * @type {string}
   * @memberof StatLogData
   */
  code: string;
  /**
   *
   * @type {object}
   * @memberof StatLogData
   */
  args: object;
  /**
   *
   * @type {string}
   * @memberof StatLogData
   */
  message: string;
}

/**
 * @export
 * @enum {string}
 */
export enum LevelEnum {
  INFO = 'INFO',
  ERROR = 'ERROR',
}

/**
 *
 * @export
 * @interface Status
 */
export interface Status {
  /**
   *
   * @type {boolean}
   * @memberof Status
   */
  ok: boolean;
  /**
   *
   * @type {string}
   * @memberof Status
   */
  message: string;
}
/**
 *
 * @export
 * @interface StatusInfo
 */
export interface StatusInfo {
  /**
   *
   * @type {string}
   * @memberof StatusInfo
   */
  totalStatus: TotalStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof StatusInfo
   */
  isDeleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StatusInfo
   */
  isConnectedToAllGates: boolean;
  /**
   *
   * @type {string}
   * @memberof StatusInfo
   */
  podStatus?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TotalStatusEnum {
  RUNNING = 'RUNNING',
  WAITING = 'WAITING',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
}

/**
 *
 * @export
 * @interface TaskTypeInfo
 */
export interface TaskTypeInfo {
  /**
   *
   * @type {string}
   * @memberof TaskTypeInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TaskTypeInfo
   */
  displayName: string;
}

/**
 * AccessTokenEndpointApi - axios parameter creator
 * @export
 */
export const AccessTokenEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} token
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccessToken(accountId: number, token: string, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling checkAccessToken.'
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling checkAccessToken.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/token/{token}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'token'}}`, encodeURIComponent(String(token)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccessToken(accountId: number, name?: string, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createAccessToken.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} token
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccessToken(accountId: number, token: string, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteAccessToken.'
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling deleteAccessToken.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/token/{token}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'token'}}`, encodeURIComponent(String(token)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccessTokens(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listAccessTokens.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccessTokenEndpointApi - functional programming interface
 * @export
 */
export const AccessTokenEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} token
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccessToken(
      accountId: number,
      token: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenData> {
      const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).checkAccessToken(
        accountId,
        token,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccessToken(
      accountId: number,
      name?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenData> {
      const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).createAccessToken(
        accountId,
        name,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} token
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccessToken(
      accountId: number,
      token: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).deleteAccessToken(
        accountId,
        token,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccessTokens(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessTokenData>> {
      const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).listAccessTokens(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccessTokenEndpointApi - factory interface
 * @export
 */
export const AccessTokenEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} token
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkAccessToken(
      accountId: number,
      token: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<AccessTokenData> {
      return AccessTokenEndpointApiFp(configuration).checkAccessToken(
        accountId,
        token,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccessToken(
      accountId: number,
      name?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<AccessTokenData> {
      return AccessTokenEndpointApiFp(configuration).createAccessToken(
        accountId,
        name,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} token
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccessToken(accountId: number, token: string, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return AccessTokenEndpointApiFp(configuration).deleteAccessToken(
        accountId,
        token,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAccessTokens(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<AccessTokenData>> {
      return AccessTokenEndpointApiFp(configuration).listAccessTokens(
        accountId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AccessTokenEndpointApi - object-oriented interface
 * @export
 * @class AccessTokenEndpointApi
 * @extends {BaseAPI}
 */
export class AccessTokenEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {string} token
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessTokenEndpointApi
   */
  public checkAccessToken(accountId: number, token: string, CAILA_API_KEY?: string, options?: any) {
    return AccessTokenEndpointApiFp(this.configuration).checkAccessToken(
      accountId,
      token,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [name]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessTokenEndpointApi
   */
  public createAccessToken(accountId: number, name?: string, CAILA_API_KEY?: string, options?: any) {
    return AccessTokenEndpointApiFp(this.configuration).createAccessToken(
      accountId,
      name,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} token
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessTokenEndpointApi
   */
  public deleteAccessToken(accountId: number, token: string, CAILA_API_KEY?: string, options?: any) {
    return AccessTokenEndpointApiFp(this.configuration).deleteAccessToken(
      accountId,
      token,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessTokenEndpointApi
   */
  public listAccessTokens(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return AccessTokenEndpointApiFp(this.configuration).listAccessTokens(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * AccountEndpointApi - axios parameter creator
 * @export
 */
export const AccountEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpConfig(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling dumpConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/dump/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ensureAccount(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/account`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getS3Credentials(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getS3Credentials.'
        );
      }
      const localVarPath = `/api/mlpgate/account/s3/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreConfig(accountId: number, body: string, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling restoreConfig.'
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling restoreConfig.');
      }
      const localVarPath = `/api/mlpgate/account/dump/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AccountInfoData} accountInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountData(accountInfoData: AccountInfoData, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountInfoData' is not null or undefined
      if (accountInfoData === null || accountInfoData === undefined) {
        throw new RequiredError(
          'accountInfoData',
          'Required parameter accountInfoData was null or undefined when calling updateAccountData.'
        );
      }
      const localVarPath = `/api/mlpgate/account`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountInfoData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountInfoData !== undefined ? accountInfoData : {})
        : accountInfoData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountEndpointApi - functional programming interface
 * @export
 */
export const AccountEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpConfig(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).dumpConfig(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ensureAccount(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
      const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).ensureAccount(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getS3Credentials(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3CredentialsData> {
      const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).getS3Credentials(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreConfig(
      accountId: number,
      body: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).restoreConfig(
        accountId,
        body,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {AccountInfoData} accountInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountData(
      accountInfoData: AccountInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
      const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).updateAccountData(
        accountInfoData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountEndpointApi - factory interface
 * @export
 */
export const AccountEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpConfig(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<string> {
      return AccountEndpointApiFp(configuration).dumpConfig(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ensureAccount(CAILA_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
      return AccountEndpointApiFp(configuration).ensureAccount(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getS3Credentials(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<S3CredentialsData> {
      return AccountEndpointApiFp(configuration).getS3Credentials(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreConfig(accountId: number, body: string, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return AccountEndpointApiFp(configuration).restoreConfig(
        accountId,
        body,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {AccountInfoData} accountInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountData(
      accountInfoData: AccountInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<AccountInfoData> {
      return AccountEndpointApiFp(configuration).updateAccountData(
        accountInfoData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AccountEndpointApi - object-oriented interface
 * @export
 * @class AccountEndpointApi
 * @extends {BaseAPI}
 */
export class AccountEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEndpointApi
   */
  public dumpConfig(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return AccountEndpointApiFp(this.configuration).dumpConfig(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEndpointApi
   */
  public ensureAccount(CAILA_API_KEY?: string, options?: any) {
    return AccountEndpointApiFp(this.configuration).ensureAccount(CAILA_API_KEY, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEndpointApi
   */
  public getS3Credentials(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return AccountEndpointApiFp(this.configuration).getS3Credentials(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} body
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEndpointApi
   */
  public restoreConfig(accountId: number, body: string, CAILA_API_KEY?: string, options?: any) {
    return AccountEndpointApiFp(this.configuration).restoreConfig(
      accountId,
      body,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {AccountInfoData} accountInfoData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEndpointApi
   */
  public updateAccountData(accountInfoData: AccountInfoData, CAILA_API_KEY?: string, options?: any) {
    return AccountEndpointApiFp(this.configuration).updateAccountData(
      accountInfoData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * AdminEndpointApi - axios parameter creator
 * @export
 */
export const AdminEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccount.'
        );
      }
      const localVarPath = `/api/mlpgate/admin/account/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountFeatures(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountFeatures.'
        );
      }
      const localVarPath = `/api/mlpgate/admin/account/{accountId}/features`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(name?: string, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/admin/account`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroups(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/admin/resource-groups`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountFeatureData} accountFeatureData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFeatures(
      accountId: number,
      accountFeatureData: AccountFeatureData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateFeatures.'
        );
      }
      // verify required parameter 'accountFeatureData' is not null or undefined
      if (accountFeatureData === null || accountFeatureData === undefined) {
        throw new RequiredError(
          'accountFeatureData',
          'Required parameter accountFeatureData was null or undefined when calling updateFeatures.'
        );
      }
      const localVarPath = `/api/mlpgate/admin/account/{accountId}/features`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountFeatureData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountFeatureData !== undefined ? accountFeatureData : {})
        : accountFeatureData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountLimitsData} accountLimitsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLimits(
      accountId: number,
      accountLimitsData: AccountLimitsData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateLimits.'
        );
      }
      // verify required parameter 'accountLimitsData' is not null or undefined
      if (accountLimitsData === null || accountLimitsData === undefined) {
        throw new RequiredError(
          'accountLimitsData',
          'Required parameter accountLimitsData was null or undefined when calling updateLimits.'
        );
      }
      const localVarPath = `/api/mlpgate/admin/account/{accountId}/limits`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountLimitsData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountLimitsData !== undefined ? accountLimitsData : {})
        : accountLimitsData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResourceGroupsData} resourceGroupsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResourceGroups(
      resourceGroupsData: ResourceGroupsData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'resourceGroupsData' is not null or undefined
      if (resourceGroupsData === null || resourceGroupsData === undefined) {
        throw new RequiredError(
          'resourceGroupsData',
          'Required parameter resourceGroupsData was null or undefined when calling updateResourceGroups.'
        );
      }
      const localVarPath = `/api/mlpgate/admin/resource-groups`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof resourceGroupsData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(resourceGroupsData !== undefined ? resourceGroupsData : {})
        : resourceGroupsData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminEndpointApi - functional programming interface
 * @export
 */
export const AdminEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
      const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccount(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountFeatures(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFeatureData> {
      const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccountFeatures(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(
      name?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountInfoData>> {
      const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccounts(
        name,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroups(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupData>> {
      const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getResourceGroups(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountFeatureData} accountFeatureData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFeatures(
      accountId: number,
      accountFeatureData: AccountFeatureData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).updateFeatures(
        accountId,
        accountFeatureData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountLimitsData} accountLimitsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLimits(
      accountId: number,
      accountLimitsData: AccountLimitsData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
      const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).updateLimits(
        accountId,
        accountLimitsData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ResourceGroupsData} resourceGroupsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResourceGroups(
      resourceGroupsData: ResourceGroupsData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).updateResourceGroups(
        resourceGroupsData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AdminEndpointApi - factory interface
 * @export
 */
export const AdminEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
      return AdminEndpointApiFp(configuration).getAccount(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountFeatures(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<AccountFeatureData> {
      return AdminEndpointApiFp(configuration).getAccountFeatures(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(name?: string, CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<AccountInfoData>> {
      return AdminEndpointApiFp(configuration).getAccounts(name, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroups(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupData>> {
      return AdminEndpointApiFp(configuration).getResourceGroups(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountFeatureData} accountFeatureData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFeatures(
      accountId: number,
      accountFeatureData: AccountFeatureData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return AdminEndpointApiFp(configuration).updateFeatures(
        accountId,
        accountFeatureData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountLimitsData} accountLimitsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLimits(
      accountId: number,
      accountLimitsData: AccountLimitsData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<AccountInfoData> {
      return AdminEndpointApiFp(configuration).updateLimits(
        accountId,
        accountLimitsData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {ResourceGroupsData} resourceGroupsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResourceGroups(
      resourceGroupsData: ResourceGroupsData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return AdminEndpointApiFp(configuration).updateResourceGroups(
        resourceGroupsData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AdminEndpointApi - object-oriented interface
 * @export
 * @class AdminEndpointApi
 * @extends {BaseAPI}
 */
export class AdminEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminEndpointApi
   */
  public getAccount(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return AdminEndpointApiFp(this.configuration).getAccount(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminEndpointApi
   */
  public getAccountFeatures(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return AdminEndpointApiFp(this.configuration).getAccountFeatures(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [name]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminEndpointApi
   */
  public getAccounts(name?: string, CAILA_API_KEY?: string, options?: any) {
    return AdminEndpointApiFp(this.configuration).getAccounts(name, CAILA_API_KEY, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminEndpointApi
   */
  public getResourceGroups(CAILA_API_KEY?: string, options?: any) {
    return AdminEndpointApiFp(this.configuration).getResourceGroups(CAILA_API_KEY, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {AccountFeatureData} accountFeatureData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminEndpointApi
   */
  public updateFeatures(
    accountId: number,
    accountFeatureData: AccountFeatureData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return AdminEndpointApiFp(this.configuration).updateFeatures(
      accountId,
      accountFeatureData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {AccountLimitsData} accountLimitsData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminEndpointApi
   */
  public updateLimits(accountId: number, accountLimitsData: AccountLimitsData, CAILA_API_KEY?: string, options?: any) {
    return AdminEndpointApiFp(this.configuration).updateLimits(
      accountId,
      accountLimitsData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {ResourceGroupsData} resourceGroupsData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminEndpointApi
   */
  public updateResourceGroups(resourceGroupsData: ResourceGroupsData, CAILA_API_KEY?: string, options?: any) {
    return AdminEndpointApiFp(this.configuration).updateResourceGroups(
      resourceGroupsData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * DataImageEndpointApi - axios parameter creator
 * @export
 */
export const DataImageEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {DataImageInfoData} dataImageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataImage(
      accountId: number,
      dataImageInfoData: DataImageInfoData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createDataImage.'
        );
      }
      // verify required parameter 'dataImageInfoData' is not null or undefined
      if (dataImageInfoData === null || dataImageInfoData === undefined) {
        throw new RequiredError(
          'dataImageInfoData',
          'Required parameter dataImageInfoData was null or undefined when calling createDataImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/data-image`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof dataImageInfoData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(dataImageInfoData !== undefined ? dataImageInfoData : {})
        : dataImageInfoData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteDataImage.'
        );
      }
      // verify required parameter 'imageId' is not null or undefined
      if (imageId === null || imageId === undefined) {
        throw new RequiredError(
          'imageId',
          'Required parameter imageId was null or undefined when calling deleteDataImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/data-image/{imageId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDataImage.'
        );
      }
      // verify required parameter 'imageId' is not null or undefined
      if (imageId === null || imageId === undefined) {
        throw new RequiredError(
          'imageId',
          'Required parameter imageId was null or undefined when calling getDataImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/data-image/{imageId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedDataImages(
      accountId: number,
      name?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getPagedDataImages.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/data-image`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {DataImageInfoData} dataImageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataImage(
      accountId: number,
      imageId: number,
      dataImageInfoData: DataImageInfoData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateDataImage.'
        );
      }
      // verify required parameter 'imageId' is not null or undefined
      if (imageId === null || imageId === undefined) {
        throw new RequiredError(
          'imageId',
          'Required parameter imageId was null or undefined when calling updateDataImage.'
        );
      }
      // verify required parameter 'dataImageInfoData' is not null or undefined
      if (dataImageInfoData === null || dataImageInfoData === undefined) {
        throw new RequiredError(
          'dataImageInfoData',
          'Required parameter dataImageInfoData was null or undefined when calling updateDataImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/data-image/{imageId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof dataImageInfoData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(dataImageInfoData !== undefined ? dataImageInfoData : {})
        : dataImageInfoData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataImageEndpointApi - functional programming interface
 * @export
 */
export const DataImageEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {DataImageInfoData} dataImageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataImage(
      accountId: number,
      dataImageInfoData: DataImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImageInfoData> {
      const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).createDataImage(
        accountId,
        dataImageInfoData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataImage(
      accountId: number,
      imageId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).deleteDataImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataImage(
      accountId: number,
      imageId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImageInfoData> {
      const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).getDataImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedDataImages(
      accountId: number,
      name?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataImageInfoData> {
      const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).getPagedDataImages(
        accountId,
        name,
        page,
        size,
        sort,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {DataImageInfoData} dataImageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataImage(
      accountId: number,
      imageId: number,
      dataImageInfoData: DataImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImageInfoData> {
      const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).updateDataImage(
        accountId,
        imageId,
        dataImageInfoData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DataImageEndpointApi - factory interface
 * @export
 */
export const DataImageEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {DataImageInfoData} dataImageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataImage(
      accountId: number,
      dataImageInfoData: DataImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<DataImageInfoData> {
      return DataImageEndpointApiFp(configuration).createDataImage(
        accountId,
        dataImageInfoData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return DataImageEndpointApiFp(configuration).deleteDataImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataImage(
      accountId: number,
      imageId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<DataImageInfoData> {
      return DataImageEndpointApiFp(configuration).getDataImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedDataImages(
      accountId: number,
      name?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<PageDataImageInfoData> {
      return DataImageEndpointApiFp(configuration).getPagedDataImages(
        accountId,
        name,
        page,
        size,
        sort,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {DataImageInfoData} dataImageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataImage(
      accountId: number,
      imageId: number,
      dataImageInfoData: DataImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<DataImageInfoData> {
      return DataImageEndpointApiFp(configuration).updateDataImage(
        accountId,
        imageId,
        dataImageInfoData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * DataImageEndpointApi - object-oriented interface
 * @export
 * @class DataImageEndpointApi
 * @extends {BaseAPI}
 */
export class DataImageEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {DataImageInfoData} dataImageInfoData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataImageEndpointApi
   */
  public createDataImage(
    accountId: number,
    dataImageInfoData: DataImageInfoData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return DataImageEndpointApiFp(this.configuration).createDataImage(
      accountId,
      dataImageInfoData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} imageId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataImageEndpointApi
   */
  public deleteDataImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any) {
    return DataImageEndpointApiFp(this.configuration).deleteDataImage(
      accountId,
      imageId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} imageId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataImageEndpointApi
   */
  public getDataImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any) {
    return DataImageEndpointApiFp(this.configuration).getDataImage(
      accountId,
      imageId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [name]
   * @param {number} [page]
   * @param {number} [size]
   * @param {string} [sort]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataImageEndpointApi
   */
  public getPagedDataImages(
    accountId: number,
    name?: string,
    page?: number,
    size?: number,
    sort?: string,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return DataImageEndpointApiFp(this.configuration).getPagedDataImages(
      accountId,
      name,
      page,
      size,
      sort,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} imageId
   * @param {DataImageInfoData} dataImageInfoData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataImageEndpointApi
   */
  public updateDataImage(
    accountId: number,
    imageId: number,
    dataImageInfoData: DataImageInfoData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return DataImageEndpointApiFp(this.configuration).updateDataImage(
      accountId,
      imageId,
      dataImageInfoData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * DatasetEndpointApi - axios parameter creator
 * @export
 */
export const DatasetEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {DatasetInfoWithContentData} datasetInfoWithContentData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataset(
      accountId: number,
      datasetInfoWithContentData: DatasetInfoWithContentData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createDataset.'
        );
      }
      // verify required parameter 'datasetInfoWithContentData' is not null or undefined
      if (datasetInfoWithContentData === null || datasetInfoWithContentData === undefined) {
        throw new RequiredError(
          'datasetInfoWithContentData',
          'Required parameter datasetInfoWithContentData was null or undefined when calling createDataset.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/dataset`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof datasetInfoWithContentData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(datasetInfoWithContentData !== undefined ? datasetInfoWithContentData : {})
        : datasetInfoWithContentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataset(accountId: number, datasetId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteDataset.'
        );
      }
      // verify required parameter 'datasetId' is not null or undefined
      if (datasetId === null || datasetId === undefined) {
        throw new RequiredError(
          'datasetId',
          'Required parameter datasetId was null or undefined when calling deleteDataset.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/dataset/{datasetId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'datasetId'}}`, encodeURIComponent(String(datasetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [type]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDatasetContent(
      accountId: number,
      datasetId: number,
      type?: string,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling downloadDatasetContent.'
        );
      }
      // verify required parameter 'datasetId' is not null or undefined
      if (datasetId === null || datasetId === undefined) {
        throw new RequiredError(
          'datasetId',
          'Required parameter datasetId was null or undefined when calling downloadDatasetContent.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/dataset/{datasetId}/content`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'datasetId'}}`, encodeURIComponent(String(datasetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetInfo(accountId: number, datasetId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getDatasetInfo.'
        );
      }
      // verify required parameter 'datasetId' is not null or undefined
      if (datasetId === null || datasetId === undefined) {
        throw new RequiredError(
          'datasetId',
          'Required parameter datasetId was null or undefined when calling getDatasetInfo.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/dataset/{datasetId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'datasetId'}}`, encodeURIComponent(String(datasetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasets(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listDatasets.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/dataset`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {DatasetInfoWithContentData} datasetInfoWithContentData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataset(
      accountId: number,
      datasetId: number,
      datasetInfoWithContentData: DatasetInfoWithContentData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateDataset.'
        );
      }
      // verify required parameter 'datasetId' is not null or undefined
      if (datasetId === null || datasetId === undefined) {
        throw new RequiredError(
          'datasetId',
          'Required parameter datasetId was null or undefined when calling updateDataset.'
        );
      }
      // verify required parameter 'datasetInfoWithContentData' is not null or undefined
      if (datasetInfoWithContentData === null || datasetInfoWithContentData === undefined) {
        throw new RequiredError(
          'datasetInfoWithContentData',
          'Required parameter datasetInfoWithContentData was null or undefined when calling updateDataset.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/dataset/{datasetId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'datasetId'}}`, encodeURIComponent(String(datasetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof datasetInfoWithContentData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(datasetInfoWithContentData !== undefined ? datasetInfoWithContentData : {})
        : datasetInfoWithContentData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {any} file
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadDatasetContent(
      accountId: number,
      datasetId: number,
      file: any,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling uploadDatasetContent.'
        );
      }
      // verify required parameter 'datasetId' is not null or undefined
      if (datasetId === null || datasetId === undefined) {
        throw new RequiredError(
          'datasetId',
          'Required parameter datasetId was null or undefined when calling uploadDatasetContent.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          'file',
          'Required parameter file was null or undefined when calling uploadDatasetContent.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/dataset/{datasetId}/content`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'datasetId'}}`, encodeURIComponent(String(datasetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetEndpointApi - functional programming interface
 * @export
 */
export const DatasetEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {DatasetInfoWithContentData} datasetInfoWithContentData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataset(
      accountId: number,
      datasetInfoWithContentData: DatasetInfoWithContentData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
      const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).createDataset(
        accountId,
        datasetInfoWithContentData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataset(
      accountId: number,
      datasetId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).deleteDataset(
        accountId,
        datasetId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [type]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDatasetContent(
      accountId: number,
      datasetId: number,
      type?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).downloadDatasetContent(
        accountId,
        datasetId,
        type,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetInfo(
      accountId: number,
      datasetId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
      const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).getDatasetInfo(
        accountId,
        datasetId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasets(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetInfoData>> {
      const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).listDatasets(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {DatasetInfoWithContentData} datasetInfoWithContentData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataset(
      accountId: number,
      datasetId: number,
      datasetInfoWithContentData: DatasetInfoWithContentData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
      const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).updateDataset(
        accountId,
        datasetId,
        datasetInfoWithContentData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {any} file
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadDatasetContent(
      accountId: number,
      datasetId: number,
      file: any,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
      const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).uploadDatasetContent(
        accountId,
        datasetId,
        file,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DatasetEndpointApi - factory interface
 * @export
 */
export const DatasetEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {DatasetInfoWithContentData} datasetInfoWithContentData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataset(
      accountId: number,
      datasetInfoWithContentData: DatasetInfoWithContentData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<DatasetInfoData> {
      return DatasetEndpointApiFp(configuration).createDataset(
        accountId,
        datasetInfoWithContentData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataset(accountId: number, datasetId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return DatasetEndpointApiFp(configuration).deleteDataset(
        accountId,
        datasetId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [type]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDatasetContent(
      accountId: number,
      datasetId: number,
      type?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<string> {
      return DatasetEndpointApiFp(configuration).downloadDatasetContent(
        accountId,
        datasetId,
        type,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetInfo(
      accountId: number,
      datasetId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<DatasetInfoData> {
      return DatasetEndpointApiFp(configuration).getDatasetInfo(
        accountId,
        datasetId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasets(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<DatasetInfoData>> {
      return DatasetEndpointApiFp(configuration).listDatasets(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {DatasetInfoWithContentData} datasetInfoWithContentData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataset(
      accountId: number,
      datasetId: number,
      datasetInfoWithContentData: DatasetInfoWithContentData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<DatasetInfoData> {
      return DatasetEndpointApiFp(configuration).updateDataset(
        accountId,
        datasetId,
        datasetInfoWithContentData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} datasetId
     * @param {any} file
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadDatasetContent(
      accountId: number,
      datasetId: number,
      file: any,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<DatasetInfoData> {
      return DatasetEndpointApiFp(configuration).uploadDatasetContent(
        accountId,
        datasetId,
        file,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * DatasetEndpointApi - object-oriented interface
 * @export
 * @class DatasetEndpointApi
 * @extends {BaseAPI}
 */
export class DatasetEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {DatasetInfoWithContentData} datasetInfoWithContentData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetEndpointApi
   */
  public createDataset(
    accountId: number,
    datasetInfoWithContentData: DatasetInfoWithContentData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return DatasetEndpointApiFp(this.configuration).createDataset(
      accountId,
      datasetInfoWithContentData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} datasetId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetEndpointApi
   */
  public deleteDataset(accountId: number, datasetId: number, CAILA_API_KEY?: string, options?: any) {
    return DatasetEndpointApiFp(this.configuration).deleteDataset(
      accountId,
      datasetId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} datasetId
   * @param {string} [type]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetEndpointApi
   */
  public downloadDatasetContent(
    accountId: number,
    datasetId: number,
    type?: string,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return DatasetEndpointApiFp(this.configuration).downloadDatasetContent(
      accountId,
      datasetId,
      type,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} datasetId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetEndpointApi
   */
  public getDatasetInfo(accountId: number, datasetId: number, CAILA_API_KEY?: string, options?: any) {
    return DatasetEndpointApiFp(this.configuration).getDatasetInfo(
      accountId,
      datasetId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetEndpointApi
   */
  public listDatasets(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return DatasetEndpointApiFp(this.configuration).listDatasets(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} datasetId
   * @param {DatasetInfoWithContentData} datasetInfoWithContentData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetEndpointApi
   */
  public updateDataset(
    accountId: number,
    datasetId: number,
    datasetInfoWithContentData: DatasetInfoWithContentData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return DatasetEndpointApiFp(this.configuration).updateDataset(
      accountId,
      datasetId,
      datasetInfoWithContentData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} datasetId
   * @param {any} file
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetEndpointApi
   */
  public uploadDatasetContent(accountId: number, datasetId: number, file: any, CAILA_API_KEY?: string, options?: any) {
    return DatasetEndpointApiFp(this.configuration).uploadDatasetContent(
      accountId,
      datasetId,
      file,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * FitConfigEndpointApi - axios parameter creator
 * @export
 */
export const FitConfigEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFitConfig(
      accountId: number,
      modelId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createFitConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling createFitConfig.'
        );
      }
      // verify required parameter 'configCreateUpdateData' is not null or undefined
      if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
        throw new RequiredError(
          'configCreateUpdateData',
          'Required parameter configCreateUpdateData was null or undefined when calling createFitConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/fit-config`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof configCreateUpdateData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {})
        : configCreateUpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteFitConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling deleteFitConfig.'
        );
      }
      // verify required parameter 'configId' is not null or undefined
      if (configId === null || configId === undefined) {
        throw new RequiredError(
          'configId',
          'Required parameter configId was null or undefined when calling deleteFitConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/fit-config/{configId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'configId'}}`, encodeURIComponent(String(configId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFitConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getFitConfig.'
        );
      }
      // verify required parameter 'configId' is not null or undefined
      if (configId === null || configId === undefined) {
        throw new RequiredError(
          'configId',
          'Required parameter configId was null or undefined when calling getFitConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/fit-config/{configId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'configId'}}`, encodeURIComponent(String(configId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFitConfigs(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listFitConfigs.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling listFitConfigs.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/fit-config`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateFitConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling updateFitConfig.'
        );
      }
      // verify required parameter 'configId' is not null or undefined
      if (configId === null || configId === undefined) {
        throw new RequiredError(
          'configId',
          'Required parameter configId was null or undefined when calling updateFitConfig.'
        );
      }
      // verify required parameter 'configCreateUpdateData' is not null or undefined
      if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
        throw new RequiredError(
          'configCreateUpdateData',
          'Required parameter configCreateUpdateData was null or undefined when calling updateFitConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/fit-config/{configId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'configId'}}`, encodeURIComponent(String(configId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof configCreateUpdateData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {})
        : configCreateUpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FitConfigEndpointApi - functional programming interface
 * @export
 */
export const FitConfigEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFitConfig(
      accountId: number,
      modelId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FitConfigData> {
      const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).createFitConfig(
        accountId,
        modelId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).deleteFitConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FitConfigData> {
      const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).getFitConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFitConfigs(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FitConfigData>> {
      const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).listFitConfigs(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FitConfigData> {
      const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).updateFitConfig(
        accountId,
        modelId,
        configId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FitConfigEndpointApi - factory interface
 * @export
 */
export const FitConfigEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFitConfig(
      accountId: number,
      modelId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<FitConfigData> {
      return FitConfigEndpointApiFp(configuration).createFitConfig(
        accountId,
        modelId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return FitConfigEndpointApiFp(configuration).deleteFitConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<FitConfigData> {
      return FitConfigEndpointApiFp(configuration).getFitConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFitConfigs(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<Array<FitConfigData>> {
      return FitConfigEndpointApiFp(configuration).listFitConfigs(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFitConfig(
      accountId: number,
      modelId: number,
      configId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<FitConfigData> {
      return FitConfigEndpointApiFp(configuration).updateFitConfig(
        accountId,
        modelId,
        configId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * FitConfigEndpointApi - object-oriented interface
 * @export
 * @class FitConfigEndpointApi
 * @extends {BaseAPI}
 */
export class FitConfigEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {ConfigCreateUpdateData} configCreateUpdateData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FitConfigEndpointApi
   */
  public createFitConfig(
    accountId: number,
    modelId: number,
    configCreateUpdateData: ConfigCreateUpdateData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return FitConfigEndpointApiFp(this.configuration).createFitConfig(
      accountId,
      modelId,
      configCreateUpdateData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} configId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FitConfigEndpointApi
   */
  public deleteFitConfig(accountId: number, modelId: number, configId: number, CAILA_API_KEY?: string, options?: any) {
    return FitConfigEndpointApiFp(this.configuration).deleteFitConfig(
      accountId,
      modelId,
      configId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} configId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FitConfigEndpointApi
   */
  public getFitConfig(accountId: number, modelId: number, configId: number, CAILA_API_KEY?: string, options?: any) {
    return FitConfigEndpointApiFp(this.configuration).getFitConfig(
      accountId,
      modelId,
      configId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FitConfigEndpointApi
   */
  public listFitConfigs(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return FitConfigEndpointApiFp(this.configuration).listFitConfigs(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} configId
   * @param {ConfigCreateUpdateData} configCreateUpdateData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FitConfigEndpointApi
   */
  public updateFitConfig(
    accountId: number,
    modelId: number,
    configId: number,
    configCreateUpdateData: ConfigCreateUpdateData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return FitConfigEndpointApiFp(this.configuration).updateFitConfig(
      accountId,
      modelId,
      configId,
      configCreateUpdateData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ImageEndpointApi - axios parameter creator
 * @export
 */
export const ImageEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {ImageInfoData} imageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImage(
      accountId: number,
      imageInfoData: ImageInfoData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createImage.'
        );
      }
      // verify required parameter 'imageInfoData' is not null or undefined
      if (imageInfoData === null || imageInfoData === undefined) {
        throw new RequiredError(
          'imageInfoData',
          'Required parameter imageInfoData was null or undefined when calling createImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/image`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof imageInfoData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(imageInfoData !== undefined ? imageInfoData : {})
        : imageInfoData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteImage.'
        );
      }
      // verify required parameter 'imageId' is not null or undefined
      if (imageId === null || imageId === undefined) {
        throw new RequiredError(
          'imageId',
          'Required parameter imageId was null or undefined when calling deleteImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/image/{imageId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getImage.'
        );
      }
      // verify required parameter 'imageId' is not null or undefined
      if (imageId === null || imageId === undefined) {
        throw new RequiredError('imageId', 'Required parameter imageId was null or undefined when calling getImage.');
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/image/{imageId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsByImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLogsByImage.'
        );
      }
      // verify required parameter 'imageId' is not null or undefined
      if (imageId === null || imageId === undefined) {
        throw new RequiredError(
          'imageId',
          'Required parameter imageId was null or undefined when calling getLogsByImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/image/{imageId}/logs`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedImages(
      accountId: number,
      name?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getPagedImages.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/image`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {ImageInfoData} imageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImage(
      accountId: number,
      imageId: number,
      imageInfoData: ImageInfoData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateImage.'
        );
      }
      // verify required parameter 'imageId' is not null or undefined
      if (imageId === null || imageId === undefined) {
        throw new RequiredError(
          'imageId',
          'Required parameter imageId was null or undefined when calling updateImage.'
        );
      }
      // verify required parameter 'imageInfoData' is not null or undefined
      if (imageInfoData === null || imageInfoData === undefined) {
        throw new RequiredError(
          'imageInfoData',
          'Required parameter imageInfoData was null or undefined when calling updateImage.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/image/{imageId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof imageInfoData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(imageInfoData !== undefined ? imageInfoData : {})
        : imageInfoData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ImageEndpointApi - functional programming interface
 * @export
 */
export const ImageEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {ImageInfoData} imageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImage(
      accountId: number,
      imageInfoData: ImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInfoData> {
      const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).createImage(
        accountId,
        imageInfoData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImage(
      accountId: number,
      imageId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).deleteImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImage(
      accountId: number,
      imageId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInfoData> {
      const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).getImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsByImage(
      accountId: number,
      imageId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
      const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).getLogsByImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedImages(
      accountId: number,
      name?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageImageInfoData> {
      const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).getPagedImages(
        accountId,
        name,
        page,
        size,
        sort,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {ImageInfoData} imageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImage(
      accountId: number,
      imageId: number,
      imageInfoData: ImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInfoData> {
      const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).updateImage(
        accountId,
        imageId,
        imageInfoData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ImageEndpointApi - factory interface
 * @export
 */
export const ImageEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {ImageInfoData} imageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImage(
      accountId: number,
      imageInfoData: ImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ImageInfoData> {
      return ImageEndpointApiFp(configuration).createImage(
        accountId,
        imageInfoData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return ImageEndpointApiFp(configuration).deleteImage(accountId, imageId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<ImageInfoData> {
      return ImageEndpointApiFp(configuration).getImage(accountId, imageId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsByImage(
      accountId: number,
      imageId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<Array<StatLogData>> {
      return ImageEndpointApiFp(configuration).getLogsByImage(
        accountId,
        imageId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedImages(
      accountId: number,
      name?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<PageImageInfoData> {
      return ImageEndpointApiFp(configuration).getPagedImages(
        accountId,
        name,
        page,
        size,
        sort,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} imageId
     * @param {ImageInfoData} imageInfoData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImage(
      accountId: number,
      imageId: number,
      imageInfoData: ImageInfoData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ImageInfoData> {
      return ImageEndpointApiFp(configuration).updateImage(
        accountId,
        imageId,
        imageInfoData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ImageEndpointApi - object-oriented interface
 * @export
 * @class ImageEndpointApi
 * @extends {BaseAPI}
 */
export class ImageEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {ImageInfoData} imageInfoData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageEndpointApi
   */
  public createImage(accountId: number, imageInfoData: ImageInfoData, CAILA_API_KEY?: string, options?: any) {
    return ImageEndpointApiFp(this.configuration).createImage(
      accountId,
      imageInfoData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} imageId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageEndpointApi
   */
  public deleteImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any) {
    return ImageEndpointApiFp(this.configuration).deleteImage(
      accountId,
      imageId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} imageId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageEndpointApi
   */
  public getImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any) {
    return ImageEndpointApiFp(this.configuration).getImage(
      accountId,
      imageId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} imageId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageEndpointApi
   */
  public getLogsByImage(accountId: number, imageId: number, CAILA_API_KEY?: string, options?: any) {
    return ImageEndpointApiFp(this.configuration).getLogsByImage(
      accountId,
      imageId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [name]
   * @param {number} [page]
   * @param {number} [size]
   * @param {string} [sort]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageEndpointApi
   */
  public getPagedImages(
    accountId: number,
    name?: string,
    page?: number,
    size?: number,
    sort?: string,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ImageEndpointApiFp(this.configuration).getPagedImages(
      accountId,
      name,
      page,
      size,
      sort,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} imageId
   * @param {ImageInfoData} imageInfoData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageEndpointApi
   */
  public updateImage(
    accountId: number,
    imageId: number,
    imageInfoData: ImageInfoData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ImageEndpointApiFp(this.configuration).updateImage(
      accountId,
      imageId,
      imageInfoData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * InternalEndpointApi - axios parameter creator
 * @export
 */
export const InternalEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cluster(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate-internal/cluster`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateAccount(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate-internal/account/deactivate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateAccountInstances(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deactivateAccountInstances.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling deactivateAccountInstances.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/model/{modelId}/instance/deactivate`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMarkedInstances(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate-internal/instances/deleteByTimestamp`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ensureNamespace(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling ensureNamespace.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/namespace/ensure`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountFeatures1(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountFeatures1.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/features`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountInstancesWithDeleted(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountInstancesWithDeleted.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getAccountInstancesWithDeleted.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/model/{modelId}/instances`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroups1(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate-internal/resource-groups`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllConnectedActions(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate-internal/actions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listConnectedActions(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listConnectedActions.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling listConnectedActions.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/model/{modelId}/actions`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRegisteredServices(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate-internal/services`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} userId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushNotification(accountId: number, userId: string, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling pushNotification.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling pushNotification.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/notify/{accountId}/user/{userId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendTestEMail(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate-internal/test-mail`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountFeatureData} accountFeatureData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountFeatures(
      accountId: number,
      accountFeatureData: AccountFeatureData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAccountFeatures.'
        );
      }
      // verify required parameter 'accountFeatureData' is not null or undefined
      if (accountFeatureData === null || accountFeatureData === undefined) {
        throw new RequiredError(
          'accountFeatureData',
          'Required parameter accountFeatureData was null or undefined when calling updateAccountFeatures.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/features`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountFeatureData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountFeatureData !== undefined ? accountFeatureData : {})
        : accountFeatureData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountLimitsData} accountLimitsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLimits1(
      accountId: number,
      accountLimitsData: AccountLimitsData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateLimits1.'
        );
      }
      // verify required parameter 'accountLimitsData' is not null or undefined
      if (accountLimitsData === null || accountLimitsData === undefined) {
        throw new RequiredError(
          'accountLimitsData',
          'Required parameter accountLimitsData was null or undefined when calling updateLimits1.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/update-limits`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountLimitsData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountLimitsData !== undefined ? accountLimitsData : {})
        : accountLimitsData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelDir(
      accountId: number,
      modelId: number,
      body: string,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateModelDir.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling updateModelDir.'
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateModelDir.');
      }
      const localVarPath = `/api/mlpgate-internal/account/{accountId}/model/{modelId}/path`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResourceGroupsData} resourceGroupsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResourceGroups1(
      resourceGroupsData: ResourceGroupsData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'resourceGroupsData' is not null or undefined
      if (resourceGroupsData === null || resourceGroupsData === undefined) {
        throw new RequiredError(
          'resourceGroupsData',
          'Required parameter resourceGroupsData was null or undefined when calling updateResourceGroups1.'
        );
      }
      const localVarPath = `/api/mlpgate-internal/resource-groups`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof resourceGroupsData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(resourceGroupsData !== undefined ? resourceGroupsData : {})
        : resourceGroupsData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InternalEndpointApi - functional programming interface
 * @export
 */
export const InternalEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cluster(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).cluster(CAILA_API_KEY, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateAccount(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).deactivateAccount(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateAccountInstances(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).deactivateAccountInstances(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMarkedInstances(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).deleteMarkedInstances(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ensureNamespace(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).ensureNamespace(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountFeatures1(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFeatureData> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getAccountFeatures1(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountInstancesWithDeleted(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelInstance>> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getAccountInstancesWithDeleted(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroups1(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupData>> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getResourceGroups1(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllConnectedActions(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).listAllConnectedActions(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listConnectedActions(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).listConnectedActions(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRegisteredServices(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelInstance>> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).listRegisteredServices(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} userId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushNotification(
      accountId: number,
      userId: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).pushNotification(
        accountId,
        userId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendTestEMail(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).sendTestEMail(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountFeatureData} accountFeatureData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountFeatures(
      accountId: number,
      accountFeatureData: AccountFeatureData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateAccountFeatures(
        accountId,
        accountFeatureData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountLimitsData} accountLimitsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLimits1(
      accountId: number,
      accountLimitsData: AccountLimitsData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateLimits1(
        accountId,
        accountLimitsData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelDir(
      accountId: number,
      modelId: number,
      body: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateModelDir(
        accountId,
        modelId,
        body,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ResourceGroupsData} resourceGroupsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResourceGroups1(
      resourceGroupsData: ResourceGroupsData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateResourceGroups1(
        resourceGroupsData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * InternalEndpointApi - factory interface
 * @export
 */
export const InternalEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cluster(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
      return InternalEndpointApiFp(configuration).cluster(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateAccount(CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).deactivateAccount(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateAccountInstances(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).deactivateAccountInstances(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMarkedInstances(CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).deleteMarkedInstances(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ensureNamespace(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).ensureNamespace(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountFeatures1(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<AccountFeatureData> {
      return InternalEndpointApiFp(configuration).getAccountFeatures1(
        accountId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountInstancesWithDeleted(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<Array<ModelInstance>> {
      return InternalEndpointApiFp(configuration).getAccountInstancesWithDeleted(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroups1(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupData>> {
      return InternalEndpointApiFp(configuration).getResourceGroups1(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllConnectedActions(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
      return InternalEndpointApiFp(configuration).listAllConnectedActions(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listConnectedActions(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<Array<string>> {
      return InternalEndpointApiFp(configuration).listConnectedActions(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRegisteredServices(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<ModelInstance>> {
      return InternalEndpointApiFp(configuration).listRegisteredServices(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} userId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushNotification(accountId: number, userId: string, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).pushNotification(
        accountId,
        userId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendTestEMail(CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).sendTestEMail(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountFeatureData} accountFeatureData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountFeatures(
      accountId: number,
      accountFeatureData: AccountFeatureData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).updateAccountFeatures(
        accountId,
        accountFeatureData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {AccountLimitsData} accountLimitsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLimits1(
      accountId: number,
      accountLimitsData: AccountLimitsData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).updateLimits1(
        accountId,
        accountLimitsData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelDir(
      accountId: number,
      modelId: number,
      body: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).updateModelDir(
        accountId,
        modelId,
        body,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {ResourceGroupsData} resourceGroupsData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateResourceGroups1(
      resourceGroupsData: ResourceGroupsData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return InternalEndpointApiFp(configuration).updateResourceGroups1(
        resourceGroupsData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * InternalEndpointApi - object-oriented interface
 * @export
 * @class InternalEndpointApi
 * @extends {BaseAPI}
 */
export class InternalEndpointApi extends BaseAPI {
  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public cluster(CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).cluster(CAILA_API_KEY, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public deactivateAccount(CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).deactivateAccount(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public deactivateAccountInstances(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).deactivateAccountInstances(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public deleteMarkedInstances(CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).deleteMarkedInstances(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public ensureNamespace(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).ensureNamespace(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public getAccountFeatures1(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).getAccountFeatures1(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public getAccountInstancesWithDeleted(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).getAccountInstancesWithDeleted(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public getResourceGroups1(CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).getResourceGroups1(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public listAllConnectedActions(CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).listAllConnectedActions(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public listConnectedActions(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).listConnectedActions(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public listRegisteredServices(CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).listRegisteredServices(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId
   * @param {string} userId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public pushNotification(accountId: number, userId: string, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).pushNotification(
      accountId,
      userId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public sendTestEMail(CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).sendTestEMail(CAILA_API_KEY, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {AccountFeatureData} accountFeatureData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public updateAccountFeatures(
    accountId: number,
    accountFeatureData: AccountFeatureData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return InternalEndpointApiFp(this.configuration).updateAccountFeatures(
      accountId,
      accountFeatureData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {AccountLimitsData} accountLimitsData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public updateLimits1(accountId: number, accountLimitsData: AccountLimitsData, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).updateLimits1(
      accountId,
      accountLimitsData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} body
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public updateModelDir(accountId: number, modelId: number, body: string, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).updateModelDir(
      accountId,
      modelId,
      body,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {ResourceGroupsData} resourceGroupsData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalEndpointApi
   */
  public updateResourceGroups1(resourceGroupsData: ResourceGroupsData, CAILA_API_KEY?: string, options?: any) {
    return InternalEndpointApiFp(this.configuration).updateResourceGroups1(
      resourceGroupsData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * JobEndpointApi - axios parameter creator
 * @export
 */
export const JobEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} jobId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobStatus(accountId: number, jobId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling jobStatus.'
        );
      }
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError('jobId', 'Required parameter jobId was null or undefined when calling jobStatus.');
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/job/{jobId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobs1(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listJobs1.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/job`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobEndpointApi - functional programming interface
 * @export
 */
export const JobEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} jobId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobStatus(
      accountId: number,
      jobId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
      const localVarAxiosArgs = JobEndpointApiAxiosParamCreator(configuration).jobStatus(
        accountId,
        jobId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobs1(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobStatusData>> {
      const localVarAxiosArgs = JobEndpointApiAxiosParamCreator(configuration).listJobs1(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * JobEndpointApi - factory interface
 * @export
 */
export const JobEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} jobId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobStatus(accountId: number, jobId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<JobStatusData> {
      return JobEndpointApiFp(configuration).jobStatus(accountId, jobId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobs1(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<JobStatusData>> {
      return JobEndpointApiFp(configuration).listJobs1(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
  };
};

/**
 * JobEndpointApi - object-oriented interface
 * @export
 * @class JobEndpointApi
 * @extends {BaseAPI}
 */
export class JobEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} jobId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobEndpointApi
   */
  public jobStatus(accountId: number, jobId: number, CAILA_API_KEY?: string, options?: any) {
    return JobEndpointApiFp(this.configuration).jobStatus(
      accountId,
      jobId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobEndpointApi
   */
  public listJobs1(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return JobEndpointApiFp(this.configuration).listJobs1(accountId, CAILA_API_KEY, options)(this.axios, this.basePath);
  }
}

/**
 * ManualManagementEndpointApi - axios parameter creator
 * @export
 */
export const ManualManagementEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} featureName
     * @param {ManagementRequestData} managementRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestFeature(
      accountId: number,
      featureName: string,
      managementRequestData: ManagementRequestData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling requestFeature.'
        );
      }
      // verify required parameter 'featureName' is not null or undefined
      if (featureName === null || featureName === undefined) {
        throw new RequiredError(
          'featureName',
          'Required parameter featureName was null or undefined when calling requestFeature.'
        );
      }
      // verify required parameter 'managementRequestData' is not null or undefined
      if (managementRequestData === null || managementRequestData === undefined) {
        throw new RequiredError(
          'managementRequestData',
          'Required parameter managementRequestData was null or undefined when calling requestFeature.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/management/{featureName}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'featureName'}}`, encodeURIComponent(String(featureName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof managementRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(managementRequestData !== undefined ? managementRequestData : {})
        : managementRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManualManagementEndpointApi - functional programming interface
 * @export
 */
export const ManualManagementEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} featureName
     * @param {ManagementRequestData} managementRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestFeature(
      accountId: number,
      featureName: string,
      managementRequestData: ManagementRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ManualManagementEndpointApiAxiosParamCreator(configuration).requestFeature(
        accountId,
        featureName,
        managementRequestData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ManualManagementEndpointApi - factory interface
 * @export
 */
export const ManualManagementEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} featureName
     * @param {ManagementRequestData} managementRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestFeature(
      accountId: number,
      featureName: string,
      managementRequestData: ManagementRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return ManualManagementEndpointApiFp(configuration).requestFeature(
        accountId,
        featureName,
        managementRequestData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ManualManagementEndpointApi - object-oriented interface
 * @export
 * @class ManualManagementEndpointApi
 * @extends {BaseAPI}
 */
export class ManualManagementEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {string} featureName
   * @param {ManagementRequestData} managementRequestData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManualManagementEndpointApi
   */
  public requestFeature(
    accountId: number,
    featureName: string,
    managementRequestData: ManagementRequestData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ManualManagementEndpointApiFp(this.configuration).requestFeature(
      accountId,
      featureName,
      managementRequestData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * MetricEndpointApi - axios parameter creator
 * @export
 */
export const MetricEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountMetric(
      accountId: number,
      metrics: Array<
        | 'ACCOUNT_CPU_USAGE'
        | 'ACCOUNT_CPU_REQUESTED'
        | 'ACCOUNT_CPU_LIMIT'
        | 'ACCOUNT_RAM_USAGE'
        | 'ACCOUNT_RAM_REQUESTED'
        | 'ACCOUNT_RAM_LIMIT'
        | 'ACCOUNT_DISK_REQUESTED'
        | 'ACCOUNT_DISK_LIMIT'
        | 'ACCOUNT_GPU_REQUESTED'
        | 'ACCOUNT_GPU_LIMIT'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
      >,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountMetric.'
        );
      }
      // verify required parameter 'metrics' is not null or undefined
      if (metrics === null || metrics === undefined) {
        throw new RequiredError(
          'metrics',
          'Required parameter metrics was null or undefined when calling getAccountMetric.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/metric`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (metrics) {
        localVarQueryParameter['metrics'] = metrics;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountMetricRange(
      accountId: number,
      metrics: Array<
        | 'ACCOUNT_CPU_USAGE'
        | 'ACCOUNT_CPU_REQUESTED'
        | 'ACCOUNT_CPU_LIMIT'
        | 'ACCOUNT_RAM_USAGE'
        | 'ACCOUNT_RAM_REQUESTED'
        | 'ACCOUNT_RAM_LIMIT'
        | 'ACCOUNT_DISK_REQUESTED'
        | 'ACCOUNT_DISK_LIMIT'
        | 'ACCOUNT_GPU_REQUESTED'
        | 'ACCOUNT_GPU_LIMIT'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountMetricRange.'
        );
      }
      // verify required parameter 'metrics' is not null or undefined
      if (metrics === null || metrics === undefined) {
        throw new RequiredError(
          'metrics',
          'Required parameter metrics was null or undefined when calling getAccountMetricRange.'
        );
      }
      // verify required parameter 'minutes' is not null or undefined
      if (minutes === null || minutes === undefined) {
        throw new RequiredError(
          'minutes',
          'Required parameter minutes was null or undefined when calling getAccountMetricRange.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/metric-range`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (metrics) {
        localVarQueryParameter['metrics'] = metrics;
      }

      if (minutes !== undefined) {
        localVarQueryParameter['minutes'] = minutes;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceMetric(
      accountId: number,
      modelId: number,
      instanceId: number,
      metrics: Array<
        | 'INSTANCE_CPU_USAGE'
        | 'INSTANCE_CPU_REQUESTED'
        | 'INSTANCE_CPU_LIMIT'
        | 'INSTANCE_RAM_USAGE'
        | 'INSTANCE_RAM_REQUESTED'
        | 'INSTANCE_RAM_LIMIT'
        | 'INSTANCE_RECONNECTS_COUNT'
      >,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getInstanceMetric.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getInstanceMetric.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling getInstanceMetric.'
        );
      }
      // verify required parameter 'metrics' is not null or undefined
      if (metrics === null || metrics === undefined) {
        throw new RequiredError(
          'metrics',
          'Required parameter metrics was null or undefined when calling getInstanceMetric.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/metric`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (metrics) {
        localVarQueryParameter['metrics'] = metrics;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceMetricRange(
      accountId: number,
      modelId: number,
      instanceId: number,
      metrics: Array<
        | 'INSTANCE_CPU_USAGE'
        | 'INSTANCE_CPU_REQUESTED'
        | 'INSTANCE_CPU_LIMIT'
        | 'INSTANCE_RAM_USAGE'
        | 'INSTANCE_RAM_REQUESTED'
        | 'INSTANCE_RAM_LIMIT'
        | 'INSTANCE_RECONNECTS_COUNT'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getInstanceMetricRange.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getInstanceMetricRange.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling getInstanceMetricRange.'
        );
      }
      // verify required parameter 'metrics' is not null or undefined
      if (metrics === null || metrics === undefined) {
        throw new RequiredError(
          'metrics',
          'Required parameter metrics was null or undefined when calling getInstanceMetricRange.'
        );
      }
      // verify required parameter 'minutes' is not null or undefined
      if (minutes === null || minutes === undefined) {
        throw new RequiredError(
          'minutes',
          'Required parameter minutes was null or undefined when calling getInstanceMetricRange.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/metric-range`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (metrics) {
        localVarQueryParameter['metrics'] = metrics;
      }

      if (minutes !== undefined) {
        localVarQueryParameter['minutes'] = minutes;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelMetric(
      accountId: number,
      modelId: number,
      metrics: Array<
        | 'MODEL_CPU_USAGE'
        | 'MODEL_CPU_REQUESTED'
        | 'MODEL_CPU_LIMIT'
        | 'MODEL_RAM_USAGE'
        | 'MODEL_RAM_REQUESTED'
        | 'MODEL_RAM_LIMIT'
        | 'MODEL_RECONNECTS_COUNT'
        | 'MODEL_SUCCESS_REQUESTS_COUNT'
        | 'MODEL_FAILED_REQUESTS_COUNT'
        | 'MODEL_ACTIVE_REQUESTS_COUNT'
        | 'MODEL_AVERAGE_REQUEST_TIME'
        | 'MODEL_MIN_REQUEST_TIME'
        | 'MODEL_MAX_REQUEST_TIME'
        | 'MODEL_INSTANT_REQUEST_TIME'
      >,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getModelMetric.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getModelMetric.'
        );
      }
      // verify required parameter 'metrics' is not null or undefined
      if (metrics === null || metrics === undefined) {
        throw new RequiredError(
          'metrics',
          'Required parameter metrics was null or undefined when calling getModelMetric.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/metric`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (metrics) {
        localVarQueryParameter['metrics'] = metrics;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelMetricRange(
      accountId: number,
      modelId: number,
      metrics: Array<
        | 'MODEL_CPU_USAGE'
        | 'MODEL_CPU_REQUESTED'
        | 'MODEL_CPU_LIMIT'
        | 'MODEL_RAM_USAGE'
        | 'MODEL_RAM_REQUESTED'
        | 'MODEL_RAM_LIMIT'
        | 'MODEL_RECONNECTS_COUNT'
        | 'MODEL_SUCCESS_REQUESTS_COUNT'
        | 'MODEL_FAILED_REQUESTS_COUNT'
        | 'MODEL_ACTIVE_REQUESTS_COUNT'
        | 'MODEL_AVERAGE_REQUEST_TIME'
        | 'MODEL_MIN_REQUEST_TIME'
        | 'MODEL_MAX_REQUEST_TIME'
        | 'MODEL_INSTANT_REQUEST_TIME'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getModelMetricRange.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getModelMetricRange.'
        );
      }
      // verify required parameter 'metrics' is not null or undefined
      if (metrics === null || metrics === undefined) {
        throw new RequiredError(
          'metrics',
          'Required parameter metrics was null or undefined when calling getModelMetricRange.'
        );
      }
      // verify required parameter 'minutes' is not null or undefined
      if (minutes === null || minutes === undefined) {
        throw new RequiredError(
          'minutes',
          'Required parameter minutes was null or undefined when calling getModelMetricRange.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/metric-range`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (metrics) {
        localVarQueryParameter['metrics'] = metrics;
      }

      if (minutes !== undefined) {
        localVarQueryParameter['minutes'] = minutes;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MetricEndpointApi - functional programming interface
 * @export
 */
export const MetricEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountMetric(
      accountId: number,
      metrics: Array<
        | 'ACCOUNT_CPU_USAGE'
        | 'ACCOUNT_CPU_REQUESTED'
        | 'ACCOUNT_CPU_LIMIT'
        | 'ACCOUNT_RAM_USAGE'
        | 'ACCOUNT_RAM_REQUESTED'
        | 'ACCOUNT_RAM_LIMIT'
        | 'ACCOUNT_DISK_REQUESTED'
        | 'ACCOUNT_DISK_LIMIT'
        | 'ACCOUNT_GPU_REQUESTED'
        | 'ACCOUNT_GPU_LIMIT'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
      >,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }> {
      const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getAccountMetric(
        accountId,
        metrics,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountMetricRange(
      accountId: number,
      metrics: Array<
        | 'ACCOUNT_CPU_USAGE'
        | 'ACCOUNT_CPU_REQUESTED'
        | 'ACCOUNT_CPU_LIMIT'
        | 'ACCOUNT_RAM_USAGE'
        | 'ACCOUNT_RAM_REQUESTED'
        | 'ACCOUNT_RAM_LIMIT'
        | 'ACCOUNT_DISK_REQUESTED'
        | 'ACCOUNT_DISK_LIMIT'
        | 'ACCOUNT_GPU_REQUESTED'
        | 'ACCOUNT_GPU_LIMIT'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Measurement> }> {
      const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getAccountMetricRange(
        accountId,
        metrics,
        minutes,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceMetric(
      accountId: number,
      modelId: number,
      instanceId: number,
      metrics: Array<
        | 'INSTANCE_CPU_USAGE'
        | 'INSTANCE_CPU_REQUESTED'
        | 'INSTANCE_CPU_LIMIT'
        | 'INSTANCE_RAM_USAGE'
        | 'INSTANCE_RAM_REQUESTED'
        | 'INSTANCE_RAM_LIMIT'
        | 'INSTANCE_RECONNECTS_COUNT'
      >,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }> {
      const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getInstanceMetric(
        accountId,
        modelId,
        instanceId,
        metrics,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceMetricRange(
      accountId: number,
      modelId: number,
      instanceId: number,
      metrics: Array<
        | 'INSTANCE_CPU_USAGE'
        | 'INSTANCE_CPU_REQUESTED'
        | 'INSTANCE_CPU_LIMIT'
        | 'INSTANCE_RAM_USAGE'
        | 'INSTANCE_RAM_REQUESTED'
        | 'INSTANCE_RAM_LIMIT'
        | 'INSTANCE_RECONNECTS_COUNT'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Measurement> }> {
      const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getInstanceMetricRange(
        accountId,
        modelId,
        instanceId,
        metrics,
        minutes,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelMetric(
      accountId: number,
      modelId: number,
      metrics: Array<
        | 'MODEL_CPU_USAGE'
        | 'MODEL_CPU_REQUESTED'
        | 'MODEL_CPU_LIMIT'
        | 'MODEL_RAM_USAGE'
        | 'MODEL_RAM_REQUESTED'
        | 'MODEL_RAM_LIMIT'
        | 'MODEL_RECONNECTS_COUNT'
        | 'MODEL_SUCCESS_REQUESTS_COUNT'
        | 'MODEL_FAILED_REQUESTS_COUNT'
        | 'MODEL_ACTIVE_REQUESTS_COUNT'
        | 'MODEL_AVERAGE_REQUEST_TIME'
        | 'MODEL_MIN_REQUEST_TIME'
        | 'MODEL_MAX_REQUEST_TIME'
        | 'MODEL_INSTANT_REQUEST_TIME'
      >,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }> {
      const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getModelMetric(
        accountId,
        modelId,
        metrics,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelMetricRange(
      accountId: number,
      modelId: number,
      metrics: Array<
        | 'MODEL_CPU_USAGE'
        | 'MODEL_CPU_REQUESTED'
        | 'MODEL_CPU_LIMIT'
        | 'MODEL_RAM_USAGE'
        | 'MODEL_RAM_REQUESTED'
        | 'MODEL_RAM_LIMIT'
        | 'MODEL_RECONNECTS_COUNT'
        | 'MODEL_SUCCESS_REQUESTS_COUNT'
        | 'MODEL_FAILED_REQUESTS_COUNT'
        | 'MODEL_ACTIVE_REQUESTS_COUNT'
        | 'MODEL_AVERAGE_REQUEST_TIME'
        | 'MODEL_MIN_REQUEST_TIME'
        | 'MODEL_MAX_REQUEST_TIME'
        | 'MODEL_INSTANT_REQUEST_TIME'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Measurement> }> {
      const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getModelMetricRange(
        accountId,
        modelId,
        metrics,
        minutes,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MetricEndpointApi - factory interface
 * @export
 */
export const MetricEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountMetric(
      accountId: number,
      metrics: Array<
        | 'ACCOUNT_CPU_USAGE'
        | 'ACCOUNT_CPU_REQUESTED'
        | 'ACCOUNT_CPU_LIMIT'
        | 'ACCOUNT_RAM_USAGE'
        | 'ACCOUNT_RAM_REQUESTED'
        | 'ACCOUNT_RAM_LIMIT'
        | 'ACCOUNT_DISK_REQUESTED'
        | 'ACCOUNT_DISK_LIMIT'
        | 'ACCOUNT_GPU_REQUESTED'
        | 'ACCOUNT_GPU_LIMIT'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
      >,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<{ [key: string]: number }> {
      return MetricEndpointApiFp(configuration).getAccountMetric(
        accountId,
        metrics,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountMetricRange(
      accountId: number,
      metrics: Array<
        | 'ACCOUNT_CPU_USAGE'
        | 'ACCOUNT_CPU_REQUESTED'
        | 'ACCOUNT_CPU_LIMIT'
        | 'ACCOUNT_RAM_USAGE'
        | 'ACCOUNT_RAM_REQUESTED'
        | 'ACCOUNT_RAM_LIMIT'
        | 'ACCOUNT_DISK_REQUESTED'
        | 'ACCOUNT_DISK_LIMIT'
        | 'ACCOUNT_GPU_REQUESTED'
        | 'ACCOUNT_GPU_LIMIT'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
        | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<{ [key: string]: Array<Measurement> }> {
      return MetricEndpointApiFp(configuration).getAccountMetricRange(
        accountId,
        metrics,
        minutes,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceMetric(
      accountId: number,
      modelId: number,
      instanceId: number,
      metrics: Array<
        | 'INSTANCE_CPU_USAGE'
        | 'INSTANCE_CPU_REQUESTED'
        | 'INSTANCE_CPU_LIMIT'
        | 'INSTANCE_RAM_USAGE'
        | 'INSTANCE_RAM_REQUESTED'
        | 'INSTANCE_RAM_LIMIT'
        | 'INSTANCE_RECONNECTS_COUNT'
      >,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<{ [key: string]: number }> {
      return MetricEndpointApiFp(configuration).getInstanceMetric(
        accountId,
        modelId,
        instanceId,
        metrics,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceMetricRange(
      accountId: number,
      modelId: number,
      instanceId: number,
      metrics: Array<
        | 'INSTANCE_CPU_USAGE'
        | 'INSTANCE_CPU_REQUESTED'
        | 'INSTANCE_CPU_LIMIT'
        | 'INSTANCE_RAM_USAGE'
        | 'INSTANCE_RAM_REQUESTED'
        | 'INSTANCE_RAM_LIMIT'
        | 'INSTANCE_RECONNECTS_COUNT'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<{ [key: string]: Array<Measurement> }> {
      return MetricEndpointApiFp(configuration).getInstanceMetricRange(
        accountId,
        modelId,
        instanceId,
        metrics,
        minutes,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelMetric(
      accountId: number,
      modelId: number,
      metrics: Array<
        | 'MODEL_CPU_USAGE'
        | 'MODEL_CPU_REQUESTED'
        | 'MODEL_CPU_LIMIT'
        | 'MODEL_RAM_USAGE'
        | 'MODEL_RAM_REQUESTED'
        | 'MODEL_RAM_LIMIT'
        | 'MODEL_RECONNECTS_COUNT'
        | 'MODEL_SUCCESS_REQUESTS_COUNT'
        | 'MODEL_FAILED_REQUESTS_COUNT'
        | 'MODEL_ACTIVE_REQUESTS_COUNT'
        | 'MODEL_AVERAGE_REQUEST_TIME'
        | 'MODEL_MIN_REQUEST_TIME'
        | 'MODEL_MAX_REQUEST_TIME'
        | 'MODEL_INSTANT_REQUEST_TIME'
      >,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<{ [key: string]: number }> {
      return MetricEndpointApiFp(configuration).getModelMetric(
        accountId,
        modelId,
        metrics,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
     * @param {number} minutes
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelMetricRange(
      accountId: number,
      modelId: number,
      metrics: Array<
        | 'MODEL_CPU_USAGE'
        | 'MODEL_CPU_REQUESTED'
        | 'MODEL_CPU_LIMIT'
        | 'MODEL_RAM_USAGE'
        | 'MODEL_RAM_REQUESTED'
        | 'MODEL_RAM_LIMIT'
        | 'MODEL_RECONNECTS_COUNT'
        | 'MODEL_SUCCESS_REQUESTS_COUNT'
        | 'MODEL_FAILED_REQUESTS_COUNT'
        | 'MODEL_ACTIVE_REQUESTS_COUNT'
        | 'MODEL_AVERAGE_REQUEST_TIME'
        | 'MODEL_MIN_REQUEST_TIME'
        | 'MODEL_MAX_REQUEST_TIME'
        | 'MODEL_INSTANT_REQUEST_TIME'
      >,
      minutes: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<{ [key: string]: Array<Measurement> }> {
      return MetricEndpointApiFp(configuration).getModelMetricRange(
        accountId,
        modelId,
        metrics,
        minutes,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * MetricEndpointApi - object-oriented interface
 * @export
 * @class MetricEndpointApi
 * @extends {BaseAPI}
 */
export class MetricEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricEndpointApi
   */
  public getAccountMetric(
    accountId: number,
    metrics: Array<
      | 'ACCOUNT_CPU_USAGE'
      | 'ACCOUNT_CPU_REQUESTED'
      | 'ACCOUNT_CPU_LIMIT'
      | 'ACCOUNT_RAM_USAGE'
      | 'ACCOUNT_RAM_REQUESTED'
      | 'ACCOUNT_RAM_LIMIT'
      | 'ACCOUNT_DISK_REQUESTED'
      | 'ACCOUNT_DISK_LIMIT'
      | 'ACCOUNT_GPU_REQUESTED'
      | 'ACCOUNT_GPU_LIMIT'
      | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
      | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
      | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
      | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
    >,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return MetricEndpointApiFp(this.configuration).getAccountMetric(
      accountId,
      metrics,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics
   * @param {number} minutes
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricEndpointApi
   */
  public getAccountMetricRange(
    accountId: number,
    metrics: Array<
      | 'ACCOUNT_CPU_USAGE'
      | 'ACCOUNT_CPU_REQUESTED'
      | 'ACCOUNT_CPU_LIMIT'
      | 'ACCOUNT_RAM_USAGE'
      | 'ACCOUNT_RAM_REQUESTED'
      | 'ACCOUNT_RAM_LIMIT'
      | 'ACCOUNT_DISK_REQUESTED'
      | 'ACCOUNT_DISK_LIMIT'
      | 'ACCOUNT_GPU_REQUESTED'
      | 'ACCOUNT_GPU_LIMIT'
      | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL'
      | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED'
      | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL'
      | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'
    >,
    minutes: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return MetricEndpointApiFp(this.configuration).getAccountMetricRange(
      accountId,
      metrics,
      minutes,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricEndpointApi
   */
  public getInstanceMetric(
    accountId: number,
    modelId: number,
    instanceId: number,
    metrics: Array<
      | 'INSTANCE_CPU_USAGE'
      | 'INSTANCE_CPU_REQUESTED'
      | 'INSTANCE_CPU_LIMIT'
      | 'INSTANCE_RAM_USAGE'
      | 'INSTANCE_RAM_REQUESTED'
      | 'INSTANCE_RAM_LIMIT'
      | 'INSTANCE_RECONNECTS_COUNT'
    >,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return MetricEndpointApiFp(this.configuration).getInstanceMetric(
      accountId,
      modelId,
      instanceId,
      metrics,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics
   * @param {number} minutes
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricEndpointApi
   */
  public getInstanceMetricRange(
    accountId: number,
    modelId: number,
    instanceId: number,
    metrics: Array<
      | 'INSTANCE_CPU_USAGE'
      | 'INSTANCE_CPU_REQUESTED'
      | 'INSTANCE_CPU_LIMIT'
      | 'INSTANCE_RAM_USAGE'
      | 'INSTANCE_RAM_REQUESTED'
      | 'INSTANCE_RAM_LIMIT'
      | 'INSTANCE_RECONNECTS_COUNT'
    >,
    minutes: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return MetricEndpointApiFp(this.configuration).getInstanceMetricRange(
      accountId,
      modelId,
      instanceId,
      metrics,
      minutes,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricEndpointApi
   */
  public getModelMetric(
    accountId: number,
    modelId: number,
    metrics: Array<
      | 'MODEL_CPU_USAGE'
      | 'MODEL_CPU_REQUESTED'
      | 'MODEL_CPU_LIMIT'
      | 'MODEL_RAM_USAGE'
      | 'MODEL_RAM_REQUESTED'
      | 'MODEL_RAM_LIMIT'
      | 'MODEL_RECONNECTS_COUNT'
      | 'MODEL_SUCCESS_REQUESTS_COUNT'
      | 'MODEL_FAILED_REQUESTS_COUNT'
      | 'MODEL_ACTIVE_REQUESTS_COUNT'
      | 'MODEL_AVERAGE_REQUEST_TIME'
      | 'MODEL_MIN_REQUEST_TIME'
      | 'MODEL_MAX_REQUEST_TIME'
      | 'MODEL_INSTANT_REQUEST_TIME'
    >,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return MetricEndpointApiFp(this.configuration).getModelMetric(
      accountId,
      modelId,
      metrics,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics
   * @param {number} minutes
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricEndpointApi
   */
  public getModelMetricRange(
    accountId: number,
    modelId: number,
    metrics: Array<
      | 'MODEL_CPU_USAGE'
      | 'MODEL_CPU_REQUESTED'
      | 'MODEL_CPU_LIMIT'
      | 'MODEL_RAM_USAGE'
      | 'MODEL_RAM_REQUESTED'
      | 'MODEL_RAM_LIMIT'
      | 'MODEL_RECONNECTS_COUNT'
      | 'MODEL_SUCCESS_REQUESTS_COUNT'
      | 'MODEL_FAILED_REQUESTS_COUNT'
      | 'MODEL_ACTIVE_REQUESTS_COUNT'
      | 'MODEL_AVERAGE_REQUEST_TIME'
      | 'MODEL_MIN_REQUEST_TIME'
      | 'MODEL_MAX_REQUEST_TIME'
      | 'MODEL_INSTANT_REQUEST_TIME'
    >,
    minutes: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return MetricEndpointApiFp(this.configuration).getModelMetricRange(
      accountId,
      modelId,
      metrics,
      minutes,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ModelEndpointApi - axios parameter creator
 * @export
 */
export const ModelEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExternalConnection(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createExternalConnection.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling createExternalConnection.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/external`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {ModelCreateUpdateData} modelCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModel(
      accountId: number,
      modelCreateUpdateData: ModelCreateUpdateData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createModel.'
        );
      }
      // verify required parameter 'modelCreateUpdateData' is not null or undefined
      if (modelCreateUpdateData === null || modelCreateUpdateData === undefined) {
        throw new RequiredError(
          'modelCreateUpdateData',
          'Required parameter modelCreateUpdateData was null or undefined when calling createModel.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof modelCreateUpdateData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(modelCreateUpdateData !== undefined ? modelCreateUpdateData : {})
        : modelCreateUpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalConnection(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteExternalConnection.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling deleteExternalConnection.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/external`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModel(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteModel.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling deleteModel.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalConnectionInfo(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getExternalConnectionInfo.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getExternalConnectionInfo.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/external`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstancesStatus(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getInstancesStatus.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getInstancesStatus.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instances`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsByModel(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLogsByModel.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getLogsByModel.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/logs`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInfo(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getModelInfo.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getModelInfo.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {boolean} [onlyMy]
     * @param {string} [account]
     * @param {string} [model]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedModels(
      accountId: number,
      onlyMy?: boolean,
      account?: string,
      model?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getPagedModels.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (onlyMy !== undefined) {
        localVarQueryParameter['onlyMy'] = onlyMy;
      }

      if (account !== undefined) {
        localVarQueryParameter['account'] = account;
      }

      if (model !== undefined) {
        localVarQueryParameter['model'] = model;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSimpleDoc(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSimpleDoc.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getSimpleDoc.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/simple-doc`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lastJob(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling lastJob.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError('modelId', 'Required parameter modelId was null or undefined when calling lastJob.');
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/last-job`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setRequestedInstancesCount(
      accountId: number,
      modelId: number,
      body: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling setRequestedInstancesCount.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling setRequestedInstancesCount.'
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling setRequestedInstancesCount.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instances`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSimpleDoc(
      accountId: number,
      modelId: number,
      body: string,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling setSimpleDoc.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling setSimpleDoc.'
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling setSimpleDoc.');
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/simple-doc`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'plain/text';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shortModelStatus(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling shortModelStatus.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling shortModelStatus.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/status`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startNewInstance(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling startNewInstance.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling startNewInstance.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/start`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ModelCreateUpdateData} modelCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModel(
      accountId: number,
      modelId: number,
      modelCreateUpdateData: ModelCreateUpdateData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateModel.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling updateModel.'
        );
      }
      // verify required parameter 'modelCreateUpdateData' is not null or undefined
      if (modelCreateUpdateData === null || modelCreateUpdateData === undefined) {
        throw new RequiredError(
          'modelCreateUpdateData',
          'Required parameter modelCreateUpdateData was null or undefined when calling updateModel.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof modelCreateUpdateData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(modelCreateUpdateData !== undefined ? modelCreateUpdateData : {})
        : modelCreateUpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModelEndpointApi - functional programming interface
 * @export
 */
export const ModelEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExternalConnection(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalConnectionInfoData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).createExternalConnection(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {ModelCreateUpdateData} modelCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModel(
      accountId: number,
      modelCreateUpdateData: ModelCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).createModel(
        accountId,
        modelCreateUpdateData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalConnection(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).deleteExternalConnection(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModel(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).deleteModel(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalConnectionInfo(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalConnectionInfoData>> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getExternalConnectionInfo(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstancesStatus(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstancesStatusData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getInstancesStatus(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsByModel(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getLogsByModel(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInfo(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getModelInfo(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {boolean} [onlyMy]
     * @param {string} [account]
     * @param {string} [model]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedModels(
      accountId: number,
      onlyMy?: boolean,
      account?: string,
      model?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageModelInfoData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getPagedModels(
        accountId,
        onlyMy,
        account,
        model,
        page,
        size,
        sort,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSimpleDoc(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getSimpleDoc(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lastJob(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).lastJob(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setRequestedInstancesCount(
      accountId: number,
      modelId: number,
      body: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).setRequestedInstancesCount(
        accountId,
        modelId,
        body,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSimpleDoc(
      accountId: number,
      modelId: number,
      body: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).setSimpleDoc(
        accountId,
        modelId,
        body,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shortModelStatus(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelShortStatusData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).shortModelStatus(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startNewInstance(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).startNewInstance(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ModelCreateUpdateData} modelCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModel(
      accountId: number,
      modelId: number,
      modelCreateUpdateData: ModelCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
      const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).updateModel(
        accountId,
        modelId,
        modelCreateUpdateData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ModelEndpointApi - factory interface
 * @export
 */
export const ModelEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExternalConnection(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ExternalConnectionInfoData> {
      return ModelEndpointApiFp(configuration).createExternalConnection(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {ModelCreateUpdateData} modelCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModel(
      accountId: number,
      modelCreateUpdateData: ModelCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ModelInfoData> {
      return ModelEndpointApiFp(configuration).createModel(
        accountId,
        modelCreateUpdateData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalConnection(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return ModelEndpointApiFp(configuration).deleteExternalConnection(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModel(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return ModelEndpointApiFp(configuration).deleteModel(accountId, modelId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalConnectionInfo(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<Array<ExternalConnectionInfoData>> {
      return ModelEndpointApiFp(configuration).getExternalConnectionInfo(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstancesStatus(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<InstancesStatusData> {
      return ModelEndpointApiFp(configuration).getInstancesStatus(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsByModel(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<Array<StatLogData>> {
      return ModelEndpointApiFp(configuration).getLogsByModel(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInfo(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ModelInfoData> {
      return ModelEndpointApiFp(configuration).getModelInfo(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {boolean} [onlyMy]
     * @param {string} [account]
     * @param {string} [model]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagedModels(
      accountId: number,
      onlyMy?: boolean,
      account?: string,
      model?: string,
      page?: number,
      size?: number,
      sort?: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<PageModelInfoData> {
      return ModelEndpointApiFp(configuration).getPagedModels(
        accountId,
        onlyMy,
        account,
        model,
        page,
        size,
        sort,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSimpleDoc(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<string> {
      return ModelEndpointApiFp(configuration).getSimpleDoc(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lastJob(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<JobStatusData> {
      return ModelEndpointApiFp(configuration).lastJob(accountId, modelId, CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setRequestedInstancesCount(
      accountId: number,
      modelId: number,
      body: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return ModelEndpointApiFp(configuration).setRequestedInstancesCount(
        accountId,
        modelId,
        body,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSimpleDoc(
      accountId: number,
      modelId: number,
      body: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<string> {
      return ModelEndpointApiFp(configuration).setSimpleDoc(
        accountId,
        modelId,
        body,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shortModelStatus(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ModelShortStatusData> {
      return ModelEndpointApiFp(configuration).shortModelStatus(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startNewInstance(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<void> {
      return ModelEndpointApiFp(configuration).startNewInstance(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ModelCreateUpdateData} modelCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModel(
      accountId: number,
      modelId: number,
      modelCreateUpdateData: ModelCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ModelInfoData> {
      return ModelEndpointApiFp(configuration).updateModel(
        accountId,
        modelId,
        modelCreateUpdateData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ModelEndpointApi - object-oriented interface
 * @export
 * @class ModelEndpointApi
 * @extends {BaseAPI}
 */
export class ModelEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public createExternalConnection(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).createExternalConnection(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {ModelCreateUpdateData} modelCreateUpdateData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public createModel(
    accountId: number,
    modelCreateUpdateData: ModelCreateUpdateData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelEndpointApiFp(this.configuration).createModel(
      accountId,
      modelCreateUpdateData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public deleteExternalConnection(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).deleteExternalConnection(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public deleteModel(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).deleteModel(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public getExternalConnectionInfo(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).getExternalConnectionInfo(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public getInstancesStatus(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).getInstancesStatus(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public getLogsByModel(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).getLogsByModel(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public getModelInfo(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).getModelInfo(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {boolean} [onlyMy]
   * @param {string} [account]
   * @param {string} [model]
   * @param {number} [page]
   * @param {number} [size]
   * @param {string} [sort]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public getPagedModels(
    accountId: number,
    onlyMy?: boolean,
    account?: string,
    model?: string,
    page?: number,
    size?: number,
    sort?: string,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelEndpointApiFp(this.configuration).getPagedModels(
      accountId,
      onlyMy,
      account,
      model,
      page,
      size,
      sort,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public getSimpleDoc(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).getSimpleDoc(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public lastJob(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).lastJob(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} body
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public setRequestedInstancesCount(
    accountId: number,
    modelId: number,
    body: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelEndpointApiFp(this.configuration).setRequestedInstancesCount(
      accountId,
      modelId,
      body,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} body
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public setSimpleDoc(accountId: number, modelId: number, body: string, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).setSimpleDoc(
      accountId,
      modelId,
      body,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public shortModelStatus(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).shortModelStatus(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public startNewInstance(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelEndpointApiFp(this.configuration).startNewInstance(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {ModelCreateUpdateData} modelCreateUpdateData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelEndpointApi
   */
  public updateModel(
    accountId: number,
    modelId: number,
    modelCreateUpdateData: ModelCreateUpdateData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelEndpointApiFp(this.configuration).updateModel(
      accountId,
      modelId,
      modelCreateUpdateData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ModelInstanceEndpointApi - axios parameter creator
 * @export
 */
export const ModelInstanceEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceEvents(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getInstanceEvents.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getInstanceEvents.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling getInstanceEvents.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/events`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {boolean} [previousContainer]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceLogFile(
      accountId: number,
      modelId: number,
      instanceId: number,
      previousContainer?: boolean,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getInstanceLogFile.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getInstanceLogFile.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling getInstanceLogFile.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/file/logs`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (previousContainer !== undefined) {
        localVarQueryParameter['previousContainer'] = previousContainer;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} path
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstancePodFile(
      accountId: number,
      modelId: number,
      instanceId: number,
      path: string,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getInstancePodFile.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getInstancePodFile.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling getInstancePodFile.'
        );
      }
      // verify required parameter 'path' is not null or undefined
      if (path === null || path === undefined) {
        throw new RequiredError(
          'path',
          'Required parameter path was null or undefined when calling getInstancePodFile.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/file`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (path !== undefined) {
        localVarQueryParameter['path'] = path;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInstanceEnvironment(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getModelInstanceEnvironment.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getModelInstanceEnvironment.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling getModelInstanceEnvironment.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/environment`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInstances(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getModelInstances.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getModelInstances.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopInstance(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling stopInstance.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling stopInstance.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling stopInstance.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/stop`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateInstance(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling terminateInstance.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling terminateInstance.'
        );
      }
      // verify required parameter 'instanceId' is not null or undefined
      if (instanceId === null || instanceId === undefined) {
        throw new RequiredError(
          'instanceId',
          'Required parameter instanceId was null or undefined when calling terminateInstance.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/instance/{instanceId}/terminate`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModelInstanceEndpointApi - functional programming interface
 * @export
 */
export const ModelInstanceEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceEvents(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceEventData> {
      const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getInstanceEvents(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {boolean} [previousContainer]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceLogFile(
      accountId: number,
      modelId: number,
      instanceId: number,
      previousContainer?: boolean,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getInstanceLogFile(
        accountId,
        modelId,
        instanceId,
        previousContainer,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} path
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstancePodFile(
      accountId: number,
      modelId: number,
      instanceId: number,
      path: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getInstancePodFile(
        accountId,
        modelId,
        instanceId,
        path,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInstanceEnvironment(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceEnvironmentData> {
      const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getModelInstanceEnvironment(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInstances(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInstanceListData> {
      const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getModelInstances(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopInstance(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).stopInstance(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateInstance(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).terminateInstance(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ModelInstanceEndpointApi - factory interface
 * @export
 */
export const ModelInstanceEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceEvents(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<InstanceEventData> {
      return ModelInstanceEndpointApiFp(configuration).getInstanceEvents(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {boolean} [previousContainer]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceLogFile(
      accountId: number,
      modelId: number,
      instanceId: number,
      previousContainer?: boolean,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<any> {
      return ModelInstanceEndpointApiFp(configuration).getInstanceLogFile(
        accountId,
        modelId,
        instanceId,
        previousContainer,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} path
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstancePodFile(
      accountId: number,
      modelId: number,
      instanceId: number,
      path: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<any> {
      return ModelInstanceEndpointApiFp(configuration).getInstancePodFile(
        accountId,
        modelId,
        instanceId,
        path,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInstanceEnvironment(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<InstanceEnvironmentData> {
      return ModelInstanceEndpointApiFp(configuration).getModelInstanceEnvironment(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInstances(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ModelInstanceListData> {
      return ModelInstanceEndpointApiFp(configuration).getModelInstances(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopInstance(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return ModelInstanceEndpointApiFp(configuration).stopInstance(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} instanceId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateInstance(
      accountId: number,
      modelId: number,
      instanceId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return ModelInstanceEndpointApiFp(configuration).terminateInstance(
        accountId,
        modelId,
        instanceId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ModelInstanceEndpointApi - object-oriented interface
 * @export
 * @class ModelInstanceEndpointApi
 * @extends {BaseAPI}
 */
export class ModelInstanceEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelInstanceEndpointApi
   */
  public getInstanceEvents(
    accountId: number,
    modelId: number,
    instanceId: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelInstanceEndpointApiFp(this.configuration).getInstanceEvents(
      accountId,
      modelId,
      instanceId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {boolean} [previousContainer]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelInstanceEndpointApi
   */
  public getInstanceLogFile(
    accountId: number,
    modelId: number,
    instanceId: number,
    previousContainer?: boolean,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelInstanceEndpointApiFp(this.configuration).getInstanceLogFile(
      accountId,
      modelId,
      instanceId,
      previousContainer,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {string} path
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelInstanceEndpointApi
   */
  public getInstancePodFile(
    accountId: number,
    modelId: number,
    instanceId: number,
    path: string,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelInstanceEndpointApiFp(this.configuration).getInstancePodFile(
      accountId,
      modelId,
      instanceId,
      path,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelInstanceEndpointApi
   */
  public getModelInstanceEnvironment(
    accountId: number,
    modelId: number,
    instanceId: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelInstanceEndpointApiFp(this.configuration).getModelInstanceEnvironment(
      accountId,
      modelId,
      instanceId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelInstanceEndpointApi
   */
  public getModelInstances(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelInstanceEndpointApiFp(this.configuration).getModelInstances(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelInstanceEndpointApi
   */
  public stopInstance(accountId: number, modelId: number, instanceId: number, CAILA_API_KEY?: string, options?: any) {
    return ModelInstanceEndpointApiFp(this.configuration).stopInstance(
      accountId,
      modelId,
      instanceId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} instanceId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelInstanceEndpointApi
   */
  public terminateInstance(
    accountId: number,
    modelId: number,
    instanceId: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ModelInstanceEndpointApiFp(this.configuration).terminateInstance(
      accountId,
      modelId,
      instanceId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * PredictConfigEndpointApi - axios parameter creator
 * @export
 */
export const PredictConfigEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPredictConfig(
      accountId: number,
      modelId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createPredictConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling createPredictConfig.'
        );
      }
      // verify required parameter 'configCreateUpdateData' is not null or undefined
      if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
        throw new RequiredError(
          'configCreateUpdateData',
          'Required parameter configCreateUpdateData was null or undefined when calling createPredictConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/predict-config`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof configCreateUpdateData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {})
        : configCreateUpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deletePredictConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling deletePredictConfig.'
        );
      }
      // verify required parameter 'configId' is not null or undefined
      if (configId === null || configId === undefined) {
        throw new RequiredError(
          'configId',
          'Required parameter configId was null or undefined when calling deletePredictConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/predict-config/{configId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'configId'}}`, encodeURIComponent(String(configId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getPredictConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling getPredictConfig.'
        );
      }
      // verify required parameter 'configId' is not null or undefined
      if (configId === null || configId === undefined) {
        throw new RequiredError(
          'configId',
          'Required parameter configId was null or undefined when calling getPredictConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/predict-config/{configId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'configId'}}`, encodeURIComponent(String(configId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPredictConfigs(accountId: number, modelId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listPredictConfigs.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling listPredictConfigs.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/predict-config`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updatePredictConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling updatePredictConfig.'
        );
      }
      // verify required parameter 'configId' is not null or undefined
      if (configId === null || configId === undefined) {
        throw new RequiredError(
          'configId',
          'Required parameter configId was null or undefined when calling updatePredictConfig.'
        );
      }
      // verify required parameter 'configCreateUpdateData' is not null or undefined
      if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
        throw new RequiredError(
          'configCreateUpdateData',
          'Required parameter configCreateUpdateData was null or undefined when calling updatePredictConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/predict-config/{configId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'configId'}}`, encodeURIComponent(String(configId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof configCreateUpdateData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {})
        : configCreateUpdateData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PredictConfigEndpointApi - functional programming interface
 * @export
 */
export const PredictConfigEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPredictConfig(
      accountId: number,
      modelId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictConfigData> {
      const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).createPredictConfig(
        accountId,
        modelId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).deletePredictConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictConfigData> {
      const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).getPredictConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPredictConfigs(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PredictConfigData>> {
      const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).listPredictConfigs(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictConfigData> {
      const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).updatePredictConfig(
        accountId,
        modelId,
        configId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PredictConfigEndpointApi - factory interface
 * @export
 */
export const PredictConfigEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPredictConfig(
      accountId: number,
      modelId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<PredictConfigData> {
      return PredictConfigEndpointApiFp(configuration).createPredictConfig(
        accountId,
        modelId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<void> {
      return PredictConfigEndpointApiFp(configuration).deletePredictConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<PredictConfigData> {
      return PredictConfigEndpointApiFp(configuration).getPredictConfig(
        accountId,
        modelId,
        configId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPredictConfigs(
      accountId: number,
      modelId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<Array<PredictConfigData>> {
      return PredictConfigEndpointApiFp(configuration).listPredictConfigs(
        accountId,
        modelId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {number} configId
     * @param {ConfigCreateUpdateData} configCreateUpdateData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePredictConfig(
      accountId: number,
      modelId: number,
      configId: number,
      configCreateUpdateData: ConfigCreateUpdateData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<PredictConfigData> {
      return PredictConfigEndpointApiFp(configuration).updatePredictConfig(
        accountId,
        modelId,
        configId,
        configCreateUpdateData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * PredictConfigEndpointApi - object-oriented interface
 * @export
 * @class PredictConfigEndpointApi
 * @extends {BaseAPI}
 */
export class PredictConfigEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {ConfigCreateUpdateData} configCreateUpdateData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictConfigEndpointApi
   */
  public createPredictConfig(
    accountId: number,
    modelId: number,
    configCreateUpdateData: ConfigCreateUpdateData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return PredictConfigEndpointApiFp(this.configuration).createPredictConfig(
      accountId,
      modelId,
      configCreateUpdateData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} configId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictConfigEndpointApi
   */
  public deletePredictConfig(
    accountId: number,
    modelId: number,
    configId: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return PredictConfigEndpointApiFp(this.configuration).deletePredictConfig(
      accountId,
      modelId,
      configId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} configId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictConfigEndpointApi
   */
  public getPredictConfig(accountId: number, modelId: number, configId: number, CAILA_API_KEY?: string, options?: any) {
    return PredictConfigEndpointApiFp(this.configuration).getPredictConfig(
      accountId,
      modelId,
      configId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictConfigEndpointApi
   */
  public listPredictConfigs(accountId: number, modelId: number, CAILA_API_KEY?: string, options?: any) {
    return PredictConfigEndpointApiFp(this.configuration).listPredictConfigs(
      accountId,
      modelId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {number} configId
   * @param {ConfigCreateUpdateData} configCreateUpdateData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictConfigEndpointApi
   */
  public updatePredictConfig(
    accountId: number,
    modelId: number,
    configId: number,
    configCreateUpdateData: ConfigCreateUpdateData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return PredictConfigEndpointApiFp(this.configuration).updatePredictConfig(
      accountId,
      modelId,
      configId,
      configCreateUpdateData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ProcessEndpointApi - axios parameter creator
 * @export
 */
export const ProcessEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {CrossValidationRequestData} crossValidationRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crossValidation(
      accountId: number,
      modelId: number,
      crossValidationRequestData: CrossValidationRequestData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling crossValidation.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling crossValidation.'
        );
      }
      // verify required parameter 'crossValidationRequestData' is not null or undefined
      if (crossValidationRequestData === null || crossValidationRequestData === undefined) {
        throw new RequiredError(
          'crossValidationRequestData',
          'Required parameter crossValidationRequestData was null or undefined when calling crossValidation.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/cross-validation`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof crossValidationRequestData !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crossValidationRequestData !== undefined ? crossValidationRequestData : {})
        : crossValidationRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {FitRequestData} fitRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fit(
      accountId: number,
      modelId: number,
      fitRequestData: FitRequestData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling fit.');
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError('modelId', 'Required parameter modelId was null or undefined when calling fit.');
      }
      // verify required parameter 'fitRequestData' is not null or undefined
      if (fitRequestData === null || fitRequestData === undefined) {
        throw new RequiredError(
          'fitRequestData',
          'Required parameter fitRequestData was null or undefined when calling fit.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/fit`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof fitRequestData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(fitRequestData !== undefined ? fitRequestData : {})
        : fitRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {number} [configId]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predict(
      accountId: number,
      modelId: number,
      body: string,
      configId?: number,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling predict.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError('modelId', 'Required parameter modelId was null or undefined when calling predict.');
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling predict.');
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/predict`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (configId !== undefined) {
        localVarQueryParameter['configId'] = configId;
      }

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {PredictRequestData} predictRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictWithConfig(
      accountId: number,
      modelId: number,
      predictRequestData: PredictRequestData,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling predictWithConfig.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling predictWithConfig.'
        );
      }
      // verify required parameter 'predictRequestData' is not null or undefined
      if (predictRequestData === null || predictRequestData === undefined) {
        throw new RequiredError(
          'predictRequestData',
          'Required parameter predictRequestData was null or undefined when calling predictWithConfig.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/model/{modelId}/predict-with-config`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof predictRequestData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(predictRequestData !== undefined ? predictRequestData : {})
        : predictRequestData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProcessEndpointApi - functional programming interface
 * @export
 */
export const ProcessEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {CrossValidationRequestData} crossValidationRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crossValidation(
      accountId: number,
      modelId: number,
      crossValidationRequestData: CrossValidationRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
      const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).crossValidation(
        accountId,
        modelId,
        crossValidationRequestData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {FitRequestData} fitRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fit(
      accountId: number,
      modelId: number,
      fitRequestData: FitRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
      const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).fit(
        accountId,
        modelId,
        fitRequestData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {number} [configId]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predict(
      accountId: number,
      modelId: number,
      body: string,
      configId?: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).predict(
        accountId,
        modelId,
        body,
        configId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {PredictRequestData} predictRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictWithConfig(
      accountId: number,
      modelId: number,
      predictRequestData: PredictRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).predictWithConfig(
        accountId,
        modelId,
        predictRequestData,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProcessEndpointApi - factory interface
 * @export
 */
export const ProcessEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {CrossValidationRequestData} crossValidationRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crossValidation(
      accountId: number,
      modelId: number,
      crossValidationRequestData: CrossValidationRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<JobStatusData> {
      return ProcessEndpointApiFp(configuration).crossValidation(
        accountId,
        modelId,
        crossValidationRequestData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {FitRequestData} fitRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fit(
      accountId: number,
      modelId: number,
      fitRequestData: FitRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<JobStatusData> {
      return ProcessEndpointApiFp(configuration).fit(
        accountId,
        modelId,
        fitRequestData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {string} body
     * @param {number} [configId]
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predict(
      accountId: number,
      modelId: number,
      body: string,
      configId?: number,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<string> {
      return ProcessEndpointApiFp(configuration).predict(
        accountId,
        modelId,
        body,
        configId,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} modelId
     * @param {PredictRequestData} predictRequestData
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictWithConfig(
      accountId: number,
      modelId: number,
      predictRequestData: PredictRequestData,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<string> {
      return ProcessEndpointApiFp(configuration).predictWithConfig(
        accountId,
        modelId,
        predictRequestData,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ProcessEndpointApi - object-oriented interface
 * @export
 * @class ProcessEndpointApi
 * @extends {BaseAPI}
 */
export class ProcessEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {CrossValidationRequestData} crossValidationRequestData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProcessEndpointApi
   */
  public crossValidation(
    accountId: number,
    modelId: number,
    crossValidationRequestData: CrossValidationRequestData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ProcessEndpointApiFp(this.configuration).crossValidation(
      accountId,
      modelId,
      crossValidationRequestData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {FitRequestData} fitRequestData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProcessEndpointApi
   */
  public fit(
    accountId: number,
    modelId: number,
    fitRequestData: FitRequestData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ProcessEndpointApiFp(this.configuration).fit(
      accountId,
      modelId,
      fitRequestData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {string} body
   * @param {number} [configId]
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProcessEndpointApi
   */
  public predict(
    accountId: number,
    modelId: number,
    body: string,
    configId?: number,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ProcessEndpointApiFp(this.configuration).predict(
      accountId,
      modelId,
      body,
      configId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} modelId
   * @param {PredictRequestData} predictRequestData
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProcessEndpointApi
   */
  public predictWithConfig(
    accountId: number,
    modelId: number,
    predictRequestData: PredictRequestData,
    CAILA_API_KEY?: string,
    options?: any
  ) {
    return ProcessEndpointApiFp(this.configuration).predictWithConfig(
      accountId,
      modelId,
      predictRequestData,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ProcessServletApi - axios parameter creator
 * @export
 */
export const ProcessServletApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Return model info
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInfoByName(account: string, model: string, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'account' is not null or undefined
      if (account === null || account === undefined) {
        throw new RequiredError(
          'account',
          'Required parameter account was null or undefined when calling getModelInfoByName.'
        );
      }
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError(
          'model',
          'Required parameter model was null or undefined when calling getModelInfoByName.'
        );
      }
      const localVarPath = `/api/mlpgate/p/{account}/{model}`
        .replace(`{${'account'}}`, encodeURIComponent(String(account)))
        .replace(`{${'model'}}`, encodeURIComponent(String(model)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Predict without config
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {object} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictByName(
      account: string,
      model: string,
      body: object,
      CAILA_API_KEY?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'account' is not null or undefined
      if (account === null || account === undefined) {
        throw new RequiredError(
          'account',
          'Required parameter account was null or undefined when calling predictByName.'
        );
      }
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError('model', 'Required parameter model was null or undefined when calling predictByName.');
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling predictByName.');
      }
      const localVarPath = `/api/mlpgate/p/{account}/{model}`
        .replace(`{${'account'}}`, encodeURIComponent(String(account)))
        .replace(`{${'model'}}`, encodeURIComponent(String(model)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProcessServletApi - functional programming interface
 * @export
 */
export const ProcessServletApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Return model info
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInfoByName(
      account: string,
      model: string,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
      const localVarAxiosArgs = ProcessServletApiAxiosParamCreator(configuration).getModelInfoByName(
        account,
        model,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Predict without config
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {object} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictByName(
      account: string,
      model: string,
      body: object,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ProcessServletApiAxiosParamCreator(configuration).predictByName(
        account,
        model,
        body,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProcessServletApi - factory interface
 * @export
 */
export const ProcessServletApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Return model info
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelInfoByName(
      account: string,
      model: string,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<ModelInfoData> {
      return ProcessServletApiFp(configuration).getModelInfoByName(
        account,
        model,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
    /**
     * Predict without config
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {object} body
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictByName(
      account: string,
      model: string,
      body: object,
      CAILA_API_KEY?: string,
      options?: any
    ): AxiosPromise<object> {
      return ProcessServletApiFp(configuration).predictByName(
        account,
        model,
        body,
        CAILA_API_KEY,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ProcessServletApi - object-oriented interface
 * @export
 * @class ProcessServletApi
 * @extends {BaseAPI}
 */
export class ProcessServletApi extends BaseAPI {
  /**
   * Return model info
   * @param {string} account Account id or account name
   * @param {string} model Model id or model name
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProcessServletApi
   */
  public getModelInfoByName(account: string, model: string, CAILA_API_KEY?: string, options?: any) {
    return ProcessServletApiFp(this.configuration).getModelInfoByName(
      account,
      model,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Predict without config
   * @param {string} account Account id or account name
   * @param {string} model Model id or model name
   * @param {object} body
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProcessServletApi
   */
  public predictByName(account: string, model: string, body: object, CAILA_API_KEY?: string, options?: any) {
    return ProcessServletApiFp(this.configuration).predictByName(
      account,
      model,
      body,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * StatLogEndpointApi - axios parameter creator
 * @export
 */
export const StatLogEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobs(accountId: number, CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listJobs.'
        );
      }
      const localVarPath = `/api/mlpgate/account/{accountId}/stat-log`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatLogEndpointApi - functional programming interface
 * @export
 */
export const StatLogEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobs(
      accountId: number,
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
      const localVarAxiosArgs = StatLogEndpointApiAxiosParamCreator(configuration).listJobs(
        accountId,
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * StatLogEndpointApi - factory interface
 * @export
 */
export const StatLogEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobs(accountId: number, CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<StatLogData>> {
      return StatLogEndpointApiFp(configuration).listJobs(accountId, CAILA_API_KEY, options)(axios, basePath);
    },
  };
};

/**
 * StatLogEndpointApi - object-oriented interface
 * @export
 * @class StatLogEndpointApi
 * @extends {BaseAPI}
 */
export class StatLogEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatLogEndpointApi
   */
  public listJobs(accountId: number, CAILA_API_KEY?: string, options?: any) {
    return StatLogEndpointApiFp(this.configuration).listJobs(
      accountId,
      CAILA_API_KEY,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SystemConfigEndpointApi - axios parameter creator
 * @export
 */
export const SystemConfigEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetDataTypes(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/system-config/dataset-data-type`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelDefaults(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/system-config/model-defaults`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroupsForAccount(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/system-config/resource-groups`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskTypes(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/system-config/task-types`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemConfigEndpointApi - functional programming interface
 * @export
 */
export const SystemConfigEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetDataTypes(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getDatasetDataTypes(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelDefaults(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelDefaults> {
      const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getModelDefaults(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroupsForAccount(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupData>> {
      const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getResourceGroupsForAccount(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskTypes(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskTypeInfo>> {
      const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getTaskTypes(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemConfigEndpointApi - factory interface
 * @export
 */
export const SystemConfigEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetDataTypes(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
      return SystemConfigEndpointApiFp(configuration).getDatasetDataTypes(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelDefaults(CAILA_API_KEY?: string, options?: any): AxiosPromise<ModelDefaults> {
      return SystemConfigEndpointApiFp(configuration).getModelDefaults(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResourceGroupsForAccount(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupData>> {
      return SystemConfigEndpointApiFp(configuration).getResourceGroupsForAccount(CAILA_API_KEY, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskTypes(CAILA_API_KEY?: string, options?: any): AxiosPromise<Array<TaskTypeInfo>> {
      return SystemConfigEndpointApiFp(configuration).getTaskTypes(CAILA_API_KEY, options)(axios, basePath);
    },
  };
};

/**
 * SystemConfigEndpointApi - object-oriented interface
 * @export
 * @class SystemConfigEndpointApi
 * @extends {BaseAPI}
 */
export class SystemConfigEndpointApi extends BaseAPI {
  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemConfigEndpointApi
   */
  public getDatasetDataTypes(CAILA_API_KEY?: string, options?: any) {
    return SystemConfigEndpointApiFp(this.configuration).getDatasetDataTypes(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemConfigEndpointApi
   */
  public getModelDefaults(CAILA_API_KEY?: string, options?: any) {
    return SystemConfigEndpointApiFp(this.configuration).getModelDefaults(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemConfigEndpointApi
   */
  public getResourceGroupsForAccount(CAILA_API_KEY?: string, options?: any) {
    return SystemConfigEndpointApiFp(this.configuration).getResourceGroupsForAccount(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemConfigEndpointApi
   */
  public getTaskTypes(CAILA_API_KEY?: string, options?: any) {
    return SystemConfigEndpointApiFp(this.configuration).getTaskTypes(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * SystemEndpointApi - axios parameter creator
 * @export
 */
export const SystemEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/health`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheckForTests(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/health/test`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(CAILA_API_KEY?: string, options: any = {}): RequestArgs {
      const localVarPath = `/api/mlpgate/version`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (CAILA_API_KEY !== undefined && CAILA_API_KEY !== null) {
        localVarHeaderParameter['CAILA-API-KEY'] = String(CAILA_API_KEY);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemEndpointApi - functional programming interface
 * @export
 */
export const SystemEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResult> {
      const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).healthCheck(CAILA_API_KEY, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheckForTests(
      CAILA_API_KEY?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResult> {
      const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).healthCheckForTests(
        CAILA_API_KEY,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(CAILA_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).version(CAILA_API_KEY, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemEndpointApi - factory interface
 * @export
 */
export const SystemEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(CAILA_API_KEY?: string, options?: any): AxiosPromise<HealthCheckResult> {
      return SystemEndpointApiFp(configuration).healthCheck(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheckForTests(CAILA_API_KEY?: string, options?: any): AxiosPromise<HealthCheckResult> {
      return SystemEndpointApiFp(configuration).healthCheckForTests(CAILA_API_KEY, options)(axios, basePath);
    },
    /**
     *
     * @param {string} [CAILA_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(CAILA_API_KEY?: string, options?: any): AxiosPromise<string> {
      return SystemEndpointApiFp(configuration).version(CAILA_API_KEY, options)(axios, basePath);
    },
  };
};

/**
 * SystemEndpointApi - object-oriented interface
 * @export
 * @class SystemEndpointApi
 * @extends {BaseAPI}
 */
export class SystemEndpointApi extends BaseAPI {
  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemEndpointApi
   */
  public healthCheck(CAILA_API_KEY?: string, options?: any) {
    return SystemEndpointApiFp(this.configuration).healthCheck(CAILA_API_KEY, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemEndpointApi
   */
  public healthCheckForTests(CAILA_API_KEY?: string, options?: any) {
    return SystemEndpointApiFp(this.configuration).healthCheckForTests(CAILA_API_KEY, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} [CAILA_API_KEY] token to use instead of a session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemEndpointApi
   */
  public version(CAILA_API_KEY?: string, options?: any) {
    return SystemEndpointApiFp(this.configuration).version(CAILA_API_KEY, options)(this.axios, this.basePath);
  }
}
