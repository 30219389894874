import React, { FC, useContext } from 'react';

import { JGToolbarButton } from '../JGToolbar';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Icon, useToggle } from '@just-ai/just-ui';
import classes from './classes.module.scss';
import { CommitDialog } from '../../../Editor/components/CommitDialog';
import { DiscardDialog } from '../../../Editor/components/DiscardDialog';
import cn from 'classnames';
import { CommitButtonContext, CommitButtonContextProvider } from './CommitButtonContext';
import { t } from 'localization';
import { useJGraphContext } from '../../contexts/JGraphContext';

const CommitButton: FC<{ className: string }> = ({ className }) => {
  const [isOpen, setOpen, , toggleOpen] = useToggle(false);

  const {
    showError,
    commit,
    discard,
    pull,
    resolve,
    commitDialog,
    discardDialog,
    commitMessage,
    setCommitMessage,
    isGitProject,
    hasExternalChanges,
  } = useContext(CommitButtonContext);

  return (
    <>
      <div className='d-inline-block'>
        <Dropdown isOpen={isOpen} toggle={toggleOpen}>
          <DropdownToggle tag='div' onClick={toggleOpen} data-toggle='dropdown' aria-expanded={isOpen}>
            <JGToolbarButton
              className={cn({ 'tasks__button-active': hasExternalChanges, active: isOpen }, className)}
              iconName='farSave'
              onClick={setOpen}
              data-test-id='JGToolbar:Save'
            />
          </DropdownToggle>
          <DropdownMenu className={classes.CommitButtonDropDown}>
            <DropdownItem
              tag='div'
              className={cn(classes.CommitButtonDropDown_Item, {
                [classes.CommitButtonDropDown_Item_ExtraPadding]: isGitProject,
              })}
              onClick={commitDialog.show}
            >
              {t('CommitButton:Save')}
            </DropdownItem>
            <DropdownItem
              tag='div'
              className={cn(classes.CommitButtonDropDown_Item, {
                [classes.CommitButtonDropDown_Item_ExtraPadding]: isGitProject,
              })}
              onClick={discardDialog.show}
            >
              {t('CommitButton:Discard')}
            </DropdownItem>
            {isGitProject && (
              <DropdownItem
                tag='div'
                className={cn(classes.CommitButtonDropDown_Item, {
                  [classes.CommitButtonDropDown_Item_ExtraPadding]: isGitProject,
                })}
                disabled={!hasExternalChanges}
                onClick={pull}
              >
                {hasExternalChanges && (
                  <Icon
                    wrapperClassName={classes.CommitButtonDropDown_Icon}
                    name='faExclamationCircle'
                    color='danger'
                  />
                )}

                <div>{t('CommitButton:Update')}</div>

                {hasExternalChanges && <small dangerouslySetInnerHTML={{ __html: t('CommitButton:UpdateText') }} />}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
      {commitDialog.isShown ? (
        <CommitDialog
          showError={showError}
          control={commitDialog}
          commit={commit}
          commitMessage={commitMessage}
          setCommitMessage={setCommitMessage}
          discard={discard}
          resolve={resolve}
        />
      ) : null}
      {discardDialog.isShown ? <DiscardDialog control={discardDialog} discard={discard} /> : null}
    </>
  );
};

export const CommitButtonWithContext: FC<{ className: string }> = ({ className }) => {
  const { isJGraphLite } = useJGraphContext();
  return (
    <CommitButtonContextProvider>
      {isJGraphLite ? null : <CommitButton className={className} />}
    </CommitButtonContextProvider>
  );
};
