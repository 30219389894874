export const choosereadyintentLocalization = {
  'ChooseReadyIntent Cities': {
    eng: 'City',
    ru: 'Города',
    cn: '城市',
  },
  'ChooseReadyIntent Capitals': {
    eng: 'Capital',
    ru: 'Столицы',
    cn: '首都',
  },
  'ChooseReadyIntent Counties': {
    eng: 'Country',
    ru: 'Страны',
    cn: '国家',
  },
  'ChooseReadyIntent Names': {
    eng: 'Name',
    ru: 'Имена',
    cn: '名称',
  },
  'ChooseReadyIntent Gender': {
    eng: 'Gender',
    ru: 'Пол',
    cn: '性别',
  },
  'ChooseReadyIntent Phone': {
    eng: 'Phone',
    ru: 'Телефон',
    cn: '电话',
  },
  'ChooseReadyIntent Date and time': {
    eng: 'Date&Time',
    ru: 'Дата и время',
    cn: '日期和时间',
  },
  'ChooseReadyIntent Normal': {
    eng: 'Neutral reaction',
    ru: 'Нейтральная оценка',
    cn: '中立反应',
  },
  'ChooseReadyIntent Negative': {
    eng: 'Negative reaction',
    ru: 'Негативная оценка',
    cn: '消极反应',
  },
  'ChooseReadyIntent Approval': {
    eng: 'Positive reaction',
    ru: 'Положительная оценка',
    cn: '积极反应',
  },
  'ChooseReadyIntent Insults': {
    eng: 'Explicit language',
    ru: 'Нецензурная лексика',
    cn: '污言秽语',
  },
  'ChooseReadyIntent Uncertainty': {
    eng: 'Doubt',
    ru: 'Сомнение',
    cn: '怀疑',
  },
  'ChooseReadyIntent Thanks': {
    eng: 'Thank you',
    ru: 'Благодарность',
    cn: '谢谢',
  },
  'ChooseReadyIntent Parting': {
    eng: 'Goodbye',
    ru: 'Прощание',
    cn: '再见',
  },
  'ChooseReadyIntent Hello': {
    eng: 'Hello',
    ru: 'Приветствие',
    cn: '你好',
  },
  'ChooseReadyIntent Negation': {
    eng: 'Disagreement',
    ru: 'Отказ',
    cn: '不同意',
  },
  'ChooseReadyIntent Agreement': {
    eng: 'Agreement',
    ru: 'Согласие',
    cn: '同意',
  },
  'ChooseReadyIntent E-mail': {
    eng: 'Email',
    ru: 'Email',
    cn: '邮件',
  },
  'ChooseReadyIntent Switch to operator': {
    eng: 'Transfer to agent',
    ru: 'Перевод на оператора',
    cn: '切换到人工客服',
  },
  'ChooseReadyIntent Answering machine': {
    eng: 'Voicemail',
    ru: 'Автоответчик',
  },
  'ChooseReadyIntent spravka link': {
    eng: 'https://help.aimylogic.com/en/article/canned-intents-uwse2u/',
    ru: 'https://help.aimylogic.com/ru/article/gotovye-intenty-maba78/',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/city': {
    eng: 'City',
    ru: 'Город',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/capital': {
    eng: 'Capital',
    ru: 'Столица',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/country': {
    eng: 'Country',
    ru: 'Страна',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/name': {
    eng: 'Name',
    ru: 'Имена',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/gender': {
    eng: 'Gender',
    ru: 'Пол',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/phone': {
    eng: 'Phone',
    ru: 'Номер телефона',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/datetime': {
    eng: 'Date&Time',
    ru: 'Дата и время',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/normal': {
    eng: 'Neutral reaction',
    ru: 'Нейтральная оценка',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/negative': {
    eng: 'Negative reaction',
    ru: 'Негативная оценка',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/approval': {
    eng: 'Positive reaction',
    ru: 'Положительная оценка',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/insults': {
    eng: 'Explicit language',
    ru: 'Нецензурная лексика',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/uncertainty': {
    eng: 'Doubt',
    ru: 'Сомнение',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/thanks': {
    eng: 'Gratitude',
    ru: 'Благодарность',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/parting': {
    eng: 'Parting',
    ru: 'Прощание',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/hello': {
    eng: 'Greeting',
    ru: 'Приветствие',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/negation': {
    eng: 'Disagreement',
    ru: 'Отказ',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/agreement': {
    eng: 'Agreement',
    ru: 'Согласие',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/email': {
    eng: 'Email',
    ru: 'Email',
  },
  'ChooseReadyIntent /sys/aimylogic/ru/switch': {
    eng: 'Transfer to agent',
    ru: 'Запрос на диалог с человеком',
  },
  'ChooseReadyIntent Redialrequest': {
    eng: 'Request a callback',
    ru: 'Запрос на повторный звонок',
  },
  'ChooseReadyIntent Phraserequest': {
    eng: 'Request to repeat the phrase',
    ru: 'Запрос на повтор фразы',
  },
  'ChooseReadyIntent Canthear': {
    eng: 'Cannot hear well',
    ru: 'Плохо слышно',
  },
  'ChooseReadyIntent Offeragreement': {
    eng: 'Consent to listen to the offer',
    ru: 'Согласие прослушать предложение',
  },
  'ChooseReadyIntent Offerreject': {
    eng: 'Refusal to listen to the offer',
    ru: 'Отказ прослушать предложение',
  },
  'ChooseReadyIntent SMSofferagreement': {
    eng: 'Consent to receive SMS',
    ru: 'Согласие получить SMS',
  },
};
