export const cailaLocalization = {
  'caila project language': {
    eng: 'NLU language',
    ru: 'Язык NLU',
  },
  'caila language ru': {
    eng: 'Russian',
    ru: 'Русский',
    cn: '俄语',
  },
  'caila language en': {
    eng: 'English',
    ru: 'Английский',
    cn: '英语',
  },
  'caila language zh': {
    eng: 'Chinese',
    ru: 'Китайский',
    cn: '中文',
  },
  'caila language cn': {
    eng: 'Chinese',
    ru: 'Китайский',
    cn: '中文',
  },
  'caila language pt': {
    eng: 'Portuguese',
    ru: 'Португальский',
    cn: '葡萄牙语',
  },
  'caila language ja': {
    eng: 'Japanese',
    ru: 'Японский',
    cn: '日本人',
  },
  'caila language es': {
    eng: 'Spanish',
    ru: 'Испанский',
    cn: '西班牙语',
  },
  'caila language da': {
    eng: 'Danish',
    ru: 'Датский',
    cn: '丹麦文',
  },
  'caila language nl': {
    eng: 'Dutch',
    ru: 'Нидерландский',
    cn: '荷兰语',
  },
  'caila language fr': {
    eng: 'French',
    ru: 'Французский',
    cn: '法语',
  },
  'caila language de': {
    eng: 'German',
    ru: 'Немецкий',
    cn: '德语',
  },
  'caila language el': {
    eng: 'Greek',
    ru: 'Греческий',
    cn: '希腊语',
  },
  'caila language it': {
    eng: 'Italian',
    ru: 'Итальянский',
    cn: '义大利文',
  },
  'caila language lt': {
    eng: 'Lithuanian',
    ru: 'Литовский',
    cn: '立陶宛语',
  },
  'caila language pl': {
    eng: 'Polish',
    ru: 'Польский',
    cn: '抛光',
  },
  'caila language ro': {
    eng: 'Romanian',
    ru: 'Румынский',
    cn: '罗马尼亚语',
  },
  'caila language kz': {
    eng: 'Kazakh',
    ru: 'Казахский',
    cn: '哈萨克语',
  },
  'caila language uk': {
    eng: 'Ukrainian',
    ru: 'Украинский',
    cn: '乌克兰语',
  },
  'caila get languages error': {
    eng: 'Error loading settings for CAILA',
    ru: 'Ошибка при получении настроек для CAILA',
    cn: '加载CAILA设置时出错',
  },
  'caila get project error': {
    eng: 'CAILA project is not found',
    ru: 'Проект CAILA не найден',
    cn: '找不到CAILA项目',
  },
  'caila set language error': {
    eng: 'Error saving CAILA settings',
    ru: 'Ошибка при сохранении настроек CAILA',
    cn: '保存CAILA设置时出错',
  },
  'caila fix errors': {
    eng: 'Correct the errors:',
    ru: 'Исправьте ошибки:',
  },
  'caila create project error': {
    eng: 'Error creating project in CAILA',
    ru: 'Ошибка при создании проекта в CAILA',
    cn: '在CAILA中创建项目时出错',
  },
  'caila error generate access key': {
    eng: 'Error generating the CAILA key',
    ru: 'Ошибка при генерации ключа CAILA',
    cn: '生成Caile密钥时出错',
  },
  'caila generate access key': {
    eng: 'Generate',
    ru: 'Сгенерировать',
    cn: '生成',
  },
  'caila access key': {
    eng: 'CAILA API key',
    ru: 'API-ключ CAILA',
    cn: 'CAILA API密钥',
  },
  'tovie access key': {
    eng: 'Tovie NLP key',
    ru: 'NLP-ключ Tovie',
    cn: 'Tovie NLP密钥',
  },
  'caila import project': {
    eng: 'Import CAILA project',
    ru: 'Импортировать проект CAILA',
    cn: '导入CAILA项目',
  },
  'tovie import project': {
    eng: 'Import NLU file',
    ru: 'Импортировать файл NLU',
    cn: '导入NLU项目',
  },
  'import project error': {
    eng: 'Error importing NLU file',
    ru: 'Ошибка при импорте файла NLU',
    cn: '导入NLU项目时出错',
  },
  'caila training success': {
    eng: 'Training completed',
    ru: 'Обучение завершено',
    cn: '培训已完成',
  },
  'caila training error': {
    eng: 'Error during training',
    ru: 'Ошибка при обучении',
    cn: '培训时出错',
  },
  'caila training error message': {
    eng: 'Last successful build',
    ru: 'Последняя успешная сборка',
    cn: '上一次成功建立',
  },
  'Caila project import': {
    eng: 'Import CAILA project',
    ru: 'Импорт проекта CAILA',
    cn: '导入Caile项目',
  },
  'caila project language tooltip': {
    eng: 'Processing client requests depends on the NLU language.',
    ru: 'Обработка запросов клиентов зависит от языка NLU.',
  },
  'caila project language tooltip list header': {
    eng: 'For each language, we have enabled:',
    ru: 'Для каждого языка мы подключили:',
  },
  'caila project language tooltip list item1': {
    eng: 'set of standard entities and intents;',
    ru: 'набор стандартных сущностей и интентов;',
  },
  'caila project language tooltip list item2': {
    eng: 'tokenization library',
    ru: 'библиотеку для токенизации;',
    cn: 'tokenization library;',
  },
  'caila project language tooltip list item3': {
    eng: 'dictionary used to correct errors.',
    ru: 'словарь для исправления ошибок.',
  },
  'caila project language tooltip btn caption': {
    eng: 'How NLU works in bots',
    ru: 'Как работает NLU в ботах',
  },
  'caila extended settings syntax error': {
    eng: 'Syntax error',
    ru: 'Ошибка в синтаксисе',
    cn: '语法错误',
  },
  'IntentItemsEdit:tooltipParaphraseInfo': {
    eng: 'Paraphrase',
    ru: 'Перефразировать',
  },
  'IntentItemsEdit:tooltipParaphraseAnother': {
    eng: 'Another variant',
    ru: 'Другой вариант',
  },
  'IntentItemsEdit:tooltipParaphraseLoading': {
    eng: 'Paraphrase generation in progress',
    ru: 'Парафраз генерируется',
  },
  'IntentItemsEdit:tooltipParaphraseUnavailable': {
    eng: 'Paraphrasing tool is unavailable now',
    ru: 'Перефразирование временно недоступно',
  },
  'IntentItemsEdit:textPlaceholder': {
    eng: 'Text',
    ru: 'Текст',
  },
};
