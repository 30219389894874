import React, { Component } from 'react';
import Unprojected from './Unprojected';
import Projected from './Projected';
import { isSystemAccess } from '../../isAccessFunction';
import classNames from 'classnames';

import TariffInfo from '../../views/TariffInfo';
import IconToggle from './IconToggle';
import localize from '../../localization';
import { AppContext } from 'modules/Caila/components/AppContext';

import OldUnprojected from './OldUnprojected';
import history from '../../appHistory';

const urlWithoutProjectSidebar = [
  '/projects',
  '/plans',
  '/edit-group',
  '/account',
  '/accountmanager',
  '/imputer',
  '/userslist',
  '/tagsettings',
  '/calllists',
  '/connectionslist',
  '/userlog',
  '/logsmasking',
];

export default class Sidebar extends Component {
  static contextType = AppContext;

  constructor() {
    super();
    const sidebarMin = window.localStorage.getItem('SIDEBAR_MIN');
    this.state = {
      isMinimized: sidebarMin === 'true',
    };
  }

  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1;
  };

  toggleSidebar = e => {
    e && e.preventDefault();

    this.setState(
      prevState => {
        const isMinimized = !prevState.isMinimized;
        window.localStorage.setItem('SIDEBAR_MIN', String(isMinimized));
        return { isMinimized };
      },
      () => {
        document.dispatchEvent(new Event('sidebarToggled'));
      }
    );
  };

  logout = () => {
    history.push('/logout');
  };

  render() {
    const { totalTasksElements, currentUser } = this.props;
    const { isMinimized } = this.state;
    const { projectShortName, onlyHeader } = this.context;

    if (onlyHeader) return null;

    const commonProps = {
      currentUser: currentUser,
      location: this.props.location,
      telephonyEnabled: isSystemAccess(['botadmin_telephony_feature']),
      dialerEnabled: isSystemAccess(['botadmin_dialer_feature']),
      billingEnabled: isSystemAccess(['botadmin_billing_feature']),
      activeRoute: this.activeRoute,
      countryIsoCode: this.props.countryIsoCode,
    };

    if (urlWithoutProjectSidebar.includes(this.props.location.pathname)) {
      return <Unprojected logout={this.logout} tariffData={this.props.tariffData} {...commonProps} />;
    }

    return (
      <div
        className={classNames('sidebar', { minimized: isMinimized, unprojected: !projectShortName })}
        data-test-id={!projectShortName ? 'unprojectedSidebar' : 'projectedSidebar'}
      >
        {projectShortName ? (
          <Projected
            key={projectShortName}
            projectShortName={projectShortName}
            totalTasksElements={totalTasksElements}
            strDialogsSearch={this.props.strDialogsSearch}
            strClientsSearch={this.props.strClientsSearch}
            projectList={this.props.projectList}
            cailaEnabled={isSystemAccess(['botadmin_nlu_feature'])}
            logLabelingEnabled={isSystemAccess(['botadmin_log_labeling_feature'])}
            {...commonProps}
          />
        ) : (
          <OldUnprojected {...commonProps} />
        )}

        <div className='sidebar-expand-button'>
          {isSystemAccess(['botadmin_billing_feature']) ? (
            <TariffInfo {...this.props.tariffData} language={this.props.language} />
          ) : null}
          <ul className='nav'>
            <li className='nav-item'>
              <a className='nav-link' href='#!' data-test-id='projectedSidebar.minimize' onClick={this.toggleSidebar}>
                <IconToggle />
                <span className='nav-item-name'>{localize.translate('Sidebar minimize menu item')}</span>
                <div className='sidebar-tooltip'>{localize.translate('Sidebar expand menu item')}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
