import React, { useCallback, useRef } from 'react';
import { ClickAwayListener, useToggle } from '@just-ai/just-ui';
import cn from 'classnames';

import { t } from 'localization';

import { AutoLayoutStartSubject$ } from 'modules/JGraph/hooks/useAutoplacement';

import { JGToolbarButton } from '../JGToolbar';

import styles from './styles.module.scss';

type AutoPlacementButtonProps = {
  classname?: string;
  stageInnerId: string;
};
const AutoPlacementButton = ({ classname, stageInnerId }: AutoPlacementButtonProps) => {
  const buttonRef = useRef();
  const [dropdownOpened, , closeDropdown, toggleDropdown] = useToggle(false);

  const autoplacementElk = useCallback(() => {
    closeDropdown();
    AutoLayoutStartSubject$.next({ type: 'elk' });
  }, [closeDropdown]);

  const autoplacementDagre = useCallback(() => {
    closeDropdown();
    AutoLayoutStartSubject$.next({ type: 'dagree' });
  }, [closeDropdown]);

  return (
    <div className={styles.AutoPlacementButton}>
      <JGToolbarButton
        ref={buttonRef}
        className={cn(classname, {
          active: dropdownOpened,
        })}
        iconName='farSparkles'
        onClick={toggleDropdown}
        data-test-id='JGToolbar:AutoLayoutButton'
        id={`${stageInnerId}_AutoLayoutButton`}
        tooltip={t('JGToolbar:Autoplacement')}
        placement='right'
      />
      {dropdownOpened && buttonRef.current && (
        <ClickAwayListener handleClickOut={closeDropdown} refs={[buttonRef.current]}>
          <div className={styles.AutoPlacementButton__dropdown}>
            <div className={styles.AutoPlacementButton__dropdown__item} onClick={autoplacementDagre}>
              {t('Autoplacement:Type:Simple')}
            </div>
            <div className={styles.AutoPlacementButton__dropdown__item} onClick={autoplacementElk}>
              {t('Autoplacement:Type:Alternative')}
            </div>
            <div className={styles.divider} />
            <div className={styles.AutoPlacementButton__dropdown__info}>{t('Autoplacement:Type:Hint')}</div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default React.memo(AutoPlacementButton);
