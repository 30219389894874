import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { Button, ButtonProps, Icon, ProgressBar } from '@just-ai/just-ui';
import '../styles.scss';
import { t } from '../../../../localization';
import TaskSpoiler from './TaskSpoiler';
import { dateExtract, TaskStatusData, getTaskStatus, TASK_STATUSES } from '../../utils/tasks';
import classNames from 'classnames';

interface TaskProps {
  task: TaskStatusData;
  body?: string | JSX.Element;
  onDelete?: (task: TaskStatusData) => void;
  onCancel?: (e: React.MouseEvent) => void;
  markHovered: (index: number) => void;
  actionButtonProp?: ButtonProps & { caption?: string };
  toggleSpoiler: (e?: React.MouseEvent) => void;
  openedSpoiler: boolean;
}

const reportTypeSpecial = ['MESSAGES', 'SESSIONS', 'STATE_ROUTES'];
const getSpecialReportType = (reportType: string) => {
  return reportTypeSpecial.includes(reportType) ? reportType : '';
};
const Task: FunctionComponent<TaskProps> = ({
  task,
  body,
  onDelete = () => {},
  onCancel,
  markHovered,
  actionButtonProp,
  openedSpoiler,
  toggleSpoiler,
}) => {
  const [time, setTime] = useState('');

  const updateTime = useCallback(() => {
    setTime(dateExtract(task.startTimestamp));
  }, [task.startTimestamp]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!task.completed) {
      !time && updateTime();
      timeoutId = setTimeout(updateTime, 1000);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [task.completed, task.startTimestamp, time, updateTime]);

  const onDeleteHandler = useCallback(() => {
    onDelete(task);
  }, [onDelete, task]);

  const taskStatus = getTaskStatus(task);
  const reportType = task.message?.data?.reportType as unknown as string;
  return (
    <div
      className={classNames(` task-card`, { hovered: task.hovered })}
      onMouseLeave={() => task.hasOwnProperty('index') && Number.isInteger(task.index!) && markHovered(task.index!)}
    >
      <div className='task-card__info'>
        <div className='task-card__name'>
          <h3>{t(`tasks title ${task?.message?.code?.code}${getSpecialReportType(reportType)}`)}</h3>
          {task?.message?.data?.botId && <p className='task-card__bot-id'>{task?.message?.data?.botId}</p>}
          {body && <div className='task-card__body'>{body}</div>}
        </div>
        <div className='task-card__control'>
          {task.completed === true || task.canceled === true ? (
            <Button color='secondary' flat iconRight='farTrashAlt' onClick={onDeleteHandler}>
              {t('tasks delete button')}
            </Button>
          ) : (
            <>
              <p>{time}</p>
              {onCancel && (
                <div className='task-card__stop' onClick={onCancel}>
                  <Icon size='sm' name='farStopCircle' color='danger' />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!task.completed && typeof task.progress === 'number' && task.progress > 0 && (
        <ProgressBar progress={Math.floor(task.progress / 10)} />
      )}

      {taskStatus === TASK_STATUSES.COMPLETED && actionButtonProp?.onClick && (
        <Button
          className='task-card__button'
          color={actionButtonProp?.color || 'success'}
          onClick={actionButtonProp?.onClick}
          outline
          size='sm'
        >
          {actionButtonProp?.caption || t('notifications view log')}
        </Button>
      )}
      <TaskSpoiler isOpen={openedSpoiler} toggleOpen={toggleSpoiler} task={task} />
    </div>
  );
};

export default Task;
