export const notificationsLocalization = {
  'notifications tabs all': {
    eng: 'All',
    ru: 'Все',
  },
  'notifications tabs errors': {
    eng: 'Errors',
    ru: 'Ошибки',
  },
  'notifications tabs warnings': {
    eng: 'Warnings',
    ru: 'Предупреждения',
  },
  'notifications tabs info': {
    eng: 'Info',
    ru: 'Информационные',
  },
  'notifications title': {
    eng: 'Notifications',
    ru: 'Список оповещений',
  },
  'notifications today': {
    eng: 'Today',
    ru: 'Сегодня',
  },
  'notifications yesterday': {
    eng: 'Yesterday',
    ru: 'Вчера',
  },
  'notifications delete all button': {
    eng: 'Delete All',
    ru: 'Удалить все',
  },
  'notifications empty list': {
    eng: 'Notification list is empty',
    ru: 'Список оповещений пуст',
  },
  'notifications copy button': {
    eng: 'Copy',
    ru: 'Скопировать',
    cn: '复制',
  },
  'notifications copy buffer button': {
    eng: 'Copy to clipboard',
    ru: 'Скопировать в буфер обмена',
  },
  'notifications view log': {
    eng: 'View log',
    ru: 'Посмотреть лог',
  },
  'notifications editorbe.deploy.bot.finished': {
    eng: 'Bot deploy is finished',
    ru: 'Бот опубликован',
  },
  'notifications editorbe.deploy.bot': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'notifications editorbe.deploy.bot.info': {
    eng: 'Bot deployment',
    ru: 'Публикация бота',
  },
  'notifications editorbe.deploy.bot.failed': {
    eng: 'Bot deployment error',
    ru: 'Ошибка при публикации бота',
  },
  'notifications editorbe.deploy.bot.test_failed': {
    eng: 'Script deployment result',
    ru: 'Результат публикации сценария',
    cn: '方案部署结果',
  },
  'notifications editorbe.deploy.alex.success': {
    eng: 'Successful Alexa deploy',
    ru: 'Бот Alexa опубликован',
  },
  'notifications editorbe.deploy.alex.failed': {
    eng: 'Failed to deploy Alexa bot',
    ru: 'Ошибка публикации бота Alexa',
  },
  'notifications editorbe.caila.project.export.error': {
    eng: 'Cannot export project from CAILA',
    ru: 'Невозможно экспортировать проект из CAILA',
  },
  'notifications editorbe.projects.internal_model_parse_failed': {
    eng: 'Failed to parse internal model',
    ru: 'Ошибка при разборе модели',
  },
  'notifications editorbe.projects.stop_checking_update': {
    eng: 'Stop checking updates',
    ru: 'Прекращена проверка обновлений',
  },
  'notifications editorbe.projects.remote_changes_found': {
    eng: 'Files in remote repository are changed. Pull changes.',
    ru: 'Файлы в удаленном репозитории изменены. Сделайте pull.',
  },
  'notifications editorbe.graph.convert_scenario_to_json_errors': {
    eng: 'Failed to convert script to JSON',
    ru: 'Не удалось преобразовать сценарий в JSON',
  },
  'notifications reporter.reports.report_generation_task_completed': {
    eng: 'Log export',
    ru: 'Экспорт логов',
  },
  'notifications reporter.too_many_requests_per_minute': {
    eng: 'There are too many requests per account',
    ru: 'Превышено число запросов для аккаунта',
  },
  'notifications body reporter.too_many_requests_per_minute': {
    eng: 'There are too many requests per account',
    ru: 'Превышено число запросов для аккаунта',
  },
  'notifications reporter.reports.report_generation_task_completedSESSIONS': {
    eng: 'Session log export',
    ru: 'Экспорт логов сессий',
  },
  'notifications reporter.reports.report_generation_task_completedMESSAGES': {
    eng: 'Message log export',
    ru: 'Экспорт логов сообщений',
  },
  'notifications reporter.reports.report_generation_task_completedSTATE_ROUTES': {
    eng: 'Route log export',
    ru: 'Экспорт логов маршрутов',
  },
  'notifications body reporter.reports.report_generation_task_completed': {
    eng: 'Log export has completed successfully',
    ru: 'Экспорт логов успешно завершен',
  },
  'notifications body reporter.reports.report_generation_task_completedSESSIONS': {
    eng: 'Session log export has completed successfully',
    ru: 'Экспорт логов сессий успешно завершен',
  },
  'notifications body reporter.reports.report_generation_task_completedMESSAGES': {
    eng: 'Message log export has completed successfully',
    ru: 'Экспорт логов сообщений успешно завершен',
  },
  'notifications body reporter.reports.report_generation_task_completedSTATE_ROUTES': {
    eng: 'Route log export has completed successfully',
    ru: 'Экспорт логов маршрутов успешно завершен',
  },
  'notifications botadmin.logs.logs_download_task_completed': {
    eng: 'System log export',
    ru: 'Экспорт системных логов',
  },
  'notifications body botadmin.logs.logs_download_task_completed': {
    eng: 'System log export has completed successfully',
    ru: 'Экспорт системных логов успешно завершен',
  },
  'notifications too_many_rows_in_report MESSAGES': {
    eng: 'The file is too large',
    ru: 'Слишком большой файл',
  },
  'notifications too_many_rows_in_report SESSIONS': {
    eng: 'The file is too large',
    ru: 'Слишком большой файл',
  },
  'notifications too_many_rows_in_report CLIENTS': {
    eng: 'The file is too large',
    ru: 'Слишком большой файл',
  },
  'notifications too_many_rows_in_report TELEPHONY': {
    eng: 'The file is too large',
    ru: 'Слишком большой файл',
  },
  'notifications too_many_task_launches SESSIONS': {
    eng: 'Failed to download session report',
    ru: 'Не удалось загрузить отчет по сессиям',
  },
  'notifications too_many_task_launches MESSAGES': {
    eng: 'Failed to download message report',
    ru: 'Не удалось загрузить отчет по сообщениям',
  },
  'notifications body too_many_task_launches': {
    eng: 'Contact support with the following task ID: $[2]',
    ru: 'ID задачи для обращения в техподдержку: $[2]',
  },
  'notifications body too_many_rows_in_report': {
    eng: 'You are trying to export a file larger than $[1] lines. Reduce the selection size to generate an export file.',
    ru: 'Вы экспортируете более $[1] строк. Уменьшите размер выборки, чтобы сформировать файл экспорта.',
  },
  'notifications reporter.too_many_report_generation_requests': {
    eng: 'Too many report generation tasks are in progress',
    ru: 'Слишком много задач на формирование отчетов',
  },
  'notifications body reporter.too_many_report_generation_requests': {
    eng: 'This account has too many report generation tasks in the queue. Please wait until the running tasks have completed or cancel them manually.',
    ru: 'В очереди слишком много задач для создания отчетов для этой учетной записи. Дождитесь завершения существующих задач или отмените их вручную.',
  },
  'notifications null MESSAGES': {
    eng: 'An error occurred while trying to download dialog logs',
    ru: 'Ошибка при выгрузке логов диалогов',
  },
  'notifications null SESSIONS': {
    eng: 'An error occurred while trying to download session logs',
    ru: 'Ошибка при выгрузке логов сессий',
  },
  'notifications null CLIENTS': {
    eng: 'An error occurred while trying to download the client list',
    ru: 'Ошибка при выгрузке списка клиентов',
  },
  'notifications null TELEPHONY': {
    eng: 'An error occurred while trying to download the telephony report',
    ru: 'Ошибка при выгрузке отчета по телефонии',
  },
  'notifications null STATE_ROUTES': {
    eng: 'An error occurred while trying to download the route report',
    ru: 'Ошибка при выгрузке отчета по маршрутам',
  },
  'notifications body null': {
    eng: 'Please try downloading again or contact support. The download task ID is $[2].',
    ru: 'Запустите выгрузку еще раз или обратитесь в службу тех. поддержки. ID задачи $[2].',
  },
  'tasks title botserver.tests.tests_run_failed': {
    eng: 'Tests failed',
    ru: 'Тесты провалены',
    cn: 'Tests failed',
  },
  'tasks title botserver.tests.tests_run_completed': {
    eng: 'Tests completed',
    ru: 'Тесты выполнены',
    cn: 'Tests completed',
  },
  'notifications botserver.tests.tests_run_failed': {
    eng: 'Tests failed',
    ru: 'Тесты провалены',
    cn: 'Tests failed',
  },
  'notifications botserver.tests.tests_run_completed': {
    eng: 'Tests completed',
    ru: 'Тесты выполнены',
    cn: 'Tests completed',
  },
};
