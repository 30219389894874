import { ConsoleLogTransport } from './Transports/ConsoleLogTransport';
export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["FATAl"] = 0] = "FATAl";
    LogLevel[LogLevel["ERROR"] = 1] = "ERROR";
    LogLevel[LogLevel["WARN"] = 2] = "WARN";
    LogLevel[LogLevel["LOG"] = 3] = "LOG";
    LogLevel[LogLevel["INFO"] = 4] = "INFO";
    LogLevel[LogLevel["DEBUG"] = 5] = "DEBUG";
})(LogLevel || (LogLevel = {}));
var Logger = /** @class */ (function () {
    function Logger() {
        this.transports = {};
    }
    Logger.prototype.fatal = function (event, context) {
        this.notify(LogLevel.FATAl, event, context);
    };
    Logger.prototype.error = function (event, context) {
        this.notify(LogLevel.ERROR, event, context);
    };
    Logger.prototype.warn = function (event, context) {
        this.notify(LogLevel.WARN, event, context);
    };
    Logger.prototype.log = function (event, context) {
        this.notify(LogLevel.LOG, event, context);
    };
    Logger.prototype.info = function (event, context) {
        this.notify(LogLevel.INFO, event, context);
    };
    Logger.prototype.debug = function (event, context) {
        this.notify(LogLevel.DEBUG, event, context);
    };
    Logger.prototype.addTransport = function (key, transport) {
        this.transports[key] = transport;
    };
    Logger.prototype.removeTransport = function (key) {
        delete this.transports[key];
    };
    Logger.prototype.notify = function (level, event, context) {
        Object.values(this.transports)
            .filter(function (transport) { return transport.level >= level; })
            .forEach(function (transport) {
            var richLogEvent = Object.assign(event, { level: level });
            transport.log(richLogEvent, context);
        });
    };
    return Logger;
}());
export { Logger };
var AppLogger = new Logger();
AppLogger.addTransport('console', new ConsoleLogTransport(LogLevel.WARN));
export { AppLogger };
