export const accountsfilterLocalization = {
  'AccountsFilter accountStatuses ACTIVE': {
    eng: 'Active',
    ru: 'Активен',
    cn: '有效',
  },
  'AccountsFilter accountStatuses PAYMENT_BLOCKED': {
    eng: 'Blocked by non-payment',
    ru: 'Заблокирован по неоплате',
    cn: '被未付款被冻结',
  },
  'AccountsFilter accountStatuses FULL_BLOCKED': {
    eng: 'Blocked',
    ru: 'Заблокирован',
    cn: '冻结',
  },
  'AccountsFilter partnerStatuses ACTIVE': {
    eng: 'Active',
    ru: 'Активный',
    cn: '有效',
  },
  'AccountsFilter partnerStatuses BLOCKED': {
    eng: 'Blocked',
    ru: 'Заблокированный',
    cn: '锁定',
  },
  'AccountsFilter partnerStatuses UNVERIFIED': {
    eng: 'Unverified',
    ru: 'Неверифицированный',
  },
  'AccountsFilter tariff': {
    eng: 'Plan',
    ru: 'Тариф',
  },
  'AccountsFilter enabled features': {
    eng: 'Enabled features',
    ru: 'Подключенные фичи',
  },
  'AccountsFilter disabled features': {
    eng: 'Disabled features',
    ru: 'Неподключенные фичи',
  },
  'AccountsFilter account status': {
    eng: 'Account status',
    ru: 'Статус аккаунта',
  },
  'AccountsFilter partner type': {
    eng: 'Partner type',
    ru: 'Тип партнера',
  },
  'AccountsFilter partner status': {
    eng: 'Partner status',
    ru: 'Статус партнера',
  },
  'AccountsFilter account creation date': {
    eng: 'Account creation date',
    ru: 'Дата создания аккаунта',
  },
  'AccountsFilter partnerStatuses JAICP': {
    eng: 'JAICP',
    ru: 'JAICP',
  },
  'AccountsFilter partnerStatuses AIMYLOGIC': {
    eng: 'Aimylogic',
    ru: 'Aimylogic',
  },
  'AccountsFilter partnerStatuses JAICP_AND_AIMYLOGIC': {
    eng: 'JAICP and Aimylogic',
    ru: 'JAICP и Aimylogic',
  },
  'AccountsFilter search': {
    eng: 'Search',
    ru: 'Найти',
  },
  'AccountsFilter partnerStatuses undefined': {
    eng: 'Undefined',
    ru: 'Не определен',
  },
};
