import React from 'react';
import { Route } from 'react-router-dom';
import BreadcrumbConfig from 'auto-breadcrumb';
import history from 'appHistory';
import localize from 'localization';
import { hasFeatures } from '../../isAccessFunction';

export const getBreadcrumbConfig = () => {
  return {
    dynamicRoutesMap: {
      '/:projectName/clientslist': localize.translate('Clients'),
      '/clientslist': localize.translate('Clients'),
      '/:projectName/clientslist/:id': ({ id }) => {
        return <span onClick={preventBreadcrumbLinkClick}>{localize.translate('Client $[1] detail page', id)}</span>;
      },
      '/:projectName/clientslist/:id/:channel_type': values => {
        return (
          <span onClick={preventBreadcrumbLinkClick}>
            {localize.translate('Type $[1]', localize.translate(`ChannelType ${values['channel_type']}`))}
          </span>
        );
      },
      '/:projectName/clientslist/:id/:channel_type/:botId': localize.translate('Bot $[1]', '{{botId}}'),
      '/userslist': localize.translate('Users list'),
      '/accounts_userslist': localize.translate('Users-superadmin list'),
      '/newslist': localize.translate('News list'),
      '/newslist/new': localize.translate('News creation'),
      '/newslist/:id': localize.translate('News list', '{{id}}'),
      '/:projectName/editor': localize.translate('Script editor'),
      '/:projectName/channels': localize.translate('Channels'),
      '/channels': localize.translate('Channels'),
      '/projects': localize.translate('Project management'),
      '/:projectName/statistic': localize.translate('Analytics'),
      '/:projectName/statistic/pageflow': localize.translate('Page flow'),
      '/system': localize.translate('System'),
      '/system/status': localize.translate('Status'),
      '/system/deploy-info': localize.translate('Deploy-info'),
      '/:projectName/channels/:id/deployments': localize.translate('Deploy history and results'),
      '/:projectName/statistic/dialogs': localize.translate('Dialogs'),
      '/:projectName/statistic/dialogs-new': localize.translate('Dialogs'),
      '/tagsettings': localize.translate('Tags'),
      '/:projectName/tasks': localize.translate('Tasks'),
      '/:projectName/statistic/tasks': localize.translate('Tasks'),
      '/:projectName/statistic/telephony': localize.translate('TelephonyPage'),
      '/:projectName/statistic/operator': localize.translate('Transferred to agent'),
      '/accounts': localize.translate('Accounts'),
      '/account': localize.translate('Profile'),
      '/features': localize.translate('Feature settings'),
      '/calllists': localize.translate('Call lists'),
      '/userlog': localize.translate('User log'),
      '/licenses': localize.translate('Licenses'),
      '/logsmasking': localize.translate('Logs:LogsMasking.navLink'),
      '/:projectShortName/calltasklist': localize.translate('Call tasks'),
      '/:projectShortName/calltask': localize.translate('Call task'),
      '/:projectShortName/chatwidgetEditor': ({ projectShortName }) => (
        <a
          href='#!'
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            history.push(`/${projectShortName}/channels`);
          }}
        >
          {localize.translate('Channels')}
        </a>
      ),
      '/project-create': localize.translate('Project create'),
      '/project-create/empty': localize.translate('Project create'),
      '/project-create/template': localize.translate('Project create'),
      '/project-create/caila': localize.translate('Project create'),
      '/project-create/git': localize.translate('Project create'),
      '/project-create/zip': localize.translate('Project create'),
      '/project-create/jaicf': localize.translate('Project create'),
      '/project-create/jaicf/external': localize.translate('Project create'),
      '/project-create/jaicf/caila': localize.translate('Project create'),
      '/project-create/jaicf/git': localize.translate('Project create'),
      '/project-create/jaicf/zip': localize.translate('Project create'),
      '/project-create/jaicf/clone': localize.translate('Project create'),
      '/connectionslist': localize.translate('Telephony settings'),
      '/connectionslist/telephonysettings': localize.translate('Connection settings'),
      '/:projectShortName/intents': localize.translate('Intents'),
      '/:projectShortName/entities': localize.translate('entities page'),
      '/:projectShortName/log-labeling': localize.translate('LogLabeling'),
      '/accountmanager': localize.translate('Account managing', ''),
    },
    staticRoutesMap: {
      '/': localize.translate('Homepage'),
    },
  };
};

const preventBreadcrumbLinkClick = e => {
  e.stopPropagation();
  e.preventDefault();
};

const getBreadcrumbs = location => {
  let Breadcrumbs = BreadcrumbConfig(getBreadcrumbConfig());
  return <Breadcrumbs className='breadcrumb' itemClass='breadcrumb-item' pathname={location.pathname} />;
};

export const BreadcrumbRoute = () => {
  if (!hasFeatures('show_breadcrumbs')) return null;
  return (
    <div className='breadcrumbs-panel'>
      <Route
        render={({ location }) => {
          return getBreadcrumbs(location);
        }}
      />
    </div>
  );
};
