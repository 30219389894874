import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Vector2d } from 'konva/lib/types';
import keyboardJS from 'keyboardjs';

import { useAppDispatch } from 'storeHooks';
import safeJsonParse from 'utils/safeJsonParse';

import { EditMenuBlock, JStateWithId } from 'reducers/JGraph.reducer/types';
import { addNewStateWithSave } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { resetConnections } from 'reducers/JGraph.reducer/Graph';
import { ClipboardService } from 'modules/JGraph/services/ClipboardService';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import { TJBlock, TagNames, checkObjectIsTJBlock, checkObjectIsJGState } from 'modules/JGraph/utils/types';
import { EditMenuBlock$ } from 'modules/JGraph/view/StateScreen.hooks';
import { useStageObservableContext } from 'modules/JGraph/contexts/StageObservablesProvider';

function clipboardListener(cb: (text: string) => void) {
  ClipboardService.readFromClipboard().then(cb);
  const documentFocusListener = () => ClipboardService.readFromClipboard().then(cb);
  window.addEventListener('focus', documentFocusListener);
  const unsub = ClipboardService.onClipboardCopy(cb);
  return () => {
    window.removeEventListener('focus', documentFocusListener);
    unsub();
  };
}

export function useClipboardListener() {
  const [clipboardText, setClipboardText] = useState('');
  useEffect(() => clipboardListener(setClipboardText), []);
  return clipboardText;
}

export function useCopyToClipboardBlock(stateBlocks: TJBlock[] = [], blockPath = '', blockIndex?: number) {
  return useCallback(() => {
    const block = ScreenBlockPath.getBlockByPathAndIndex(stateBlocks, blockPath, blockIndex);
    if (!block) {
      console.error(`Unable to find block to copy`);
      return;
    }
    let blocks: TJBlock[] = [block];
    if (block.tagName === TagNames.if && blockIndex) {
      blocks = ScreenBlockPath.getIfChainBlocks(blockIndex, stateBlocks);
    }
    ClipboardService.copyToClipboard(JSON.stringify(blocks));
  }, [stateBlocks, blockPath, blockIndex]);
}

export function useStateBlocksInClipboard() {
  const clipboardText = useClipboardListener();
  return useMemo(() => {
    if (!clipboardText) return null;
    const parsedJson = safeJsonParse(clipboardText);
    if (!parsedJson) return null;
    if (Array.isArray(parsedJson)) {
      if (!parsedJson.every(checkObjectIsTJBlock)) return null;
      return parsedJson;
    }
    if (!checkObjectIsTJBlock(parsedJson)) return null;
    return [parsedJson];
  }, [clipboardText]);
}

export function useCopyToClipboardState(active: boolean) {
  const dispatch = useAppDispatch();
  const stageObservableContext = useStageObservableContext();
  const editMenuBlock = useRef<EditMenuBlock>();
  const clipboardText = useRef<string>();

  const createStateInner = useCallback(
    async (state: JStateWithId, pos: Vector2d) => {
      return dispatch(
        addNewStateWithSave({
          blocks: resetConnections(state.blocks),
          screenPath: state.value,
          parentPath: stageObservableContext.selectedGroupPath,
          setEdit: true,
          position: pos,
        })
      );
    },
    [dispatch, stageObservableContext.selectedGroupPath]
  );

  useEffect(() => {
    if (!active) return;
    const tryToPasteState = () => {
      if (!stageObservableContext.GStage) return;
      const screenPosition = stageObservableContext.GStage.getAbsolutePosition();
      const scale = stageObservableContext.GStage.scale();
      const pointerPos = stageObservableContext.GStage.getPointerPosition();
      if (!pointerPos) return;
      const position = {
        x: (pointerPos.x - screenPosition.x) / scale.x,
        y: (pointerPos.y - screenPosition.y) / scale.y,
      };

      if (!clipboardText.current) return null;
      const parsedJson = safeJsonParse(clipboardText.current);
      if (!parsedJson) return null;
      if (!checkObjectIsJGState(parsedJson)) return null;
      createStateInner(parsedJson, position);
    };

    const sub = EditMenuBlock$.subscribe(value => {
      editMenuBlock.current = value;
    });
    const unsubClipboardListener = clipboardListener(text => {
      clipboardText.current = text;
    });
    const copyKeymap = ['ctrl + v', 'command + v'];
    keyboardJS.bind(copyKeymap, tryToPasteState);

    return () => {
      sub.unsubscribe();
      unsubClipboardListener();
      keyboardJS.unbind(copyKeymap, tryToPasteState);
    };
  }, [stageObservableContext, createStateInner, active]);

  const copyState = useCallback(() => {
    if (window.getSelection()?.toString()) return;
    if (
      !editMenuBlock.current ||
      !editMenuBlock.current.screen ||
      editMenuBlock.current.jBlockIndex !== undefined ||
      editMenuBlock.current.path !== undefined
    ) {
      return;
    }
    return ClipboardService.copyToClipboard(JSON.stringify(editMenuBlock.current?.screen));
  }, []);

  useEffect(() => {
    if (!active) return;
    const copyKeymap = ['ctrl + c', 'command + c'];
    keyboardJS.bind(copyKeymap, copyState);
    return () => {
      keyboardJS.unbind(copyKeymap, copyState);
    };
  }, [active, copyState]);
}
