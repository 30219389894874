var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { CustomInput } from 'reactstrap';
import { Slider, Handles, Tracks, Rail } from 'react-compound-slider';
import './Range.scss';
export function Range(_a) {
    var { id, type, name, visibleStep, min, max, onChange = () => { }, visibleStepLabel } = _a, restProps = __rest(_a, ["id", "type", "name", "visibleStep", "min", "max", "onChange", "visibleStepLabel"]);
    const range = (React.createElement(CustomInput, Object.assign({ id: id || name || 'range', type: 'range', name: name, min: min, max: max, onChange: event => onChange(Number(event.target.value), name) }, restProps)));
    if (visibleStep && typeof min === 'number' && max) {
        const visualStepsTemplate = new Array(Math.floor((max - min) / visibleStep) + 1).fill(true);
        const visualSteps = visualStepsTemplate.reduce((result, _, index) => {
            const value = min + index * visibleStep;
            if (value >= max && result[result.length - 1] !== max) {
                result.push(max);
            }
            else if (value < max) {
                result.push(min + index * visibleStep);
            }
            return result;
        }, []);
        return (React.createElement("div", { className: 'justui-range-container' },
            React.createElement("div", { className: 'justui-range-steps' }, visualSteps.map((value, index) => (React.createElement("div", { key: value },
                React.createElement("svg", { width: '2', height: '4', viewBox: '0 0 2 4', fill: 'none' },
                    React.createElement("rect", { x: '0.5', width: '1', height: '4', rx: '0.5', fill: 'var(--gray-800)' })),
                visibleStepLabel ? visibleStepLabel(value) : value)))),
            range));
    }
    else {
        return range;
    }
}
export function RangeAlt(props) {
    const { min, max, value, onChange, step, mode = 1, handleIds } = props;
    return (React.createElement(Slider, { mode: mode, step: step, domain: [min, max], className: 'slider-root-styles just-ui__slider', onChange: onChange, values: value },
        React.createElement(Rail, null, ({ getRailProps }) => (React.createElement(React.Fragment, null,
            React.createElement("div", Object.assign({ className: 'slider-outer-rail' }, getRailProps())),
            React.createElement("div", { className: 'slider-inner-rail' })))),
        React.createElement(Handles, null, ({ handles, getHandleProps }) => (React.createElement("div", { className: 'slider-handles' }, handles.map((handle, index) => (React.createElement(Handle, { disabled: false, key: handle.id, handle: handle, domain: [min, max], getHandleProps: getHandleProps, testId: handleIds && handleIds[index] })))))),
        React.createElement(Tracks, { left: mode === 2 ? false : undefined, right: false }, ({ tracks, getTrackProps }) => (React.createElement("div", { className: 'slider-tracks' }, tracks.map(({ id, source, target }) => (React.createElement(Track, { disabled: false, key: id, source: source, target: target, getTrackProps: getTrackProps }))))))));
}
function Handle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps, testId }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", Object.assign({ className: 'slider-handle', "data-test-id": testId, style: {
                left: `${percent}%`,
            } }, getHandleProps(id))),
        React.createElement("div", { role: 'slider', "aria-valuemin": min, "aria-valuemax": max, "aria-valuenow": value, style: {
                left: `${percent}%`,
                backgroundColor: disabled ? '#666' : 'white',
            } })));
}
function Track({ source, target, getTrackProps, disabled }) {
    return (React.createElement("div", Object.assign({ className: 'slider-track', style: {
            backgroundColor: disabled ? '#999' : '#605FE3',
            left: `${source.percent}%`,
            width: `${target.percent - source.percent}%`,
        } }, getTrackProps())));
}
export default Range;
