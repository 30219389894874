const prefix = '@features/';

export const LOAD_FEATURES = prefix + 'LOAD_FEATURES';
export const LOAD_FEATURES_FULFILLED = prefix + 'LOAD_FEATURES_FULFILLED';
export const LOAD_FEATURES_PENDING = prefix + 'LOAD_FEATURES_PENDING';
export const LOAD_FEATURES_REJECTED = prefix + 'LOAD_FEATURES_REJECTED';

export const LOAD_DEFAULT_FEATURES = prefix + 'LOAD_DEFAULT_FEATURES';
export const LOAD_DEFAULT_FEATURES_FULFILLED = prefix + 'LOAD_DEFAULT_FEATURES_FULFILLED';
export const LOAD_DEFAULT_FEATURES_PENDING = prefix + 'LOAD_DEFAULT_FEATURES_PENDING';
export const LOAD_DEFAULT_FEATURES_REJECTED = prefix + 'LOAD_DEFAULT_FEATURES_REJECTED';

export const SAVE_PROJECT_FEATURE = prefix + 'SAVE_PROJECT_FEATURE';
export const SAVE_PROJECT_FEATURE_FULFILLED = prefix + 'SAVE_PROJECT_FEATURE_FULFILLED';
export const SAVE_PROJECT_FEATURE_PENDING = prefix + 'SAVE_PROJECT_FEATURE_PENDING';
export const SAVE_PROJECT_FEATURE_REJECTED = prefix + 'SAVE_PROJECT_FEATURE_REJECTED';

export const SAVE_ACCOUNT_FEATURE = prefix + 'SAVE_ACCOUNT_FEATURE';
export const SAVE_ACCOUNT_FEATURE_FULFILLED = prefix + 'SAVE_ACCOUNT_FEATURE_FULFILLED';
export const SAVE_ACCOUNT_FEATURE_PENDING = prefix + 'SAVE_ACCOUNT_FEATURE_PENDING';
export const SAVE_ACCOUNT_FEATURE_REJECTED = prefix + 'SAVE_ACCOUNT_FEATURE_REJECTED';
