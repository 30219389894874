import {
  TextToSpeechApi,
  TtsRequestDTO,
  SpeechProviderListApi as SpeechApi,
  AvailableProvidersAndVoicesApi,
  SuggestedVoicesDTO,
  TextToSpeechProviderDetail,
} from '../api/client';
import { axios } from 'pipes/functions';

export default class TextToSpeechService {
  private static readonly BASE_PATH = window.location.origin;
  private readonly accountId: number;
  private textToSpeechApi: TextToSpeechApi;
  private speechApi: SpeechApi;
  private availableProvidersAndVoicesApi: AvailableProvidersAndVoicesApi;

  public ttsVoices: SuggestedVoicesDTO['providers'] = [];

  constructor(accountId: number) {
    this.accountId = accountId;
    this.textToSpeechApi = new TextToSpeechApi({}, TextToSpeechService.BASE_PATH, axios);
    this.speechApi = new SpeechApi({}, TextToSpeechService.BASE_PATH, axios);
    this.availableProvidersAndVoicesApi = new AvailableProvidersAndVoicesApi({}, TextToSpeechService.BASE_PATH, axios);

    this.getSuggestedVoicesForProvider().then(({ data }) => {
      this.ttsVoices = data.providers;
    });
  }

  synthesize = (ttsRequestDTO: TtsRequestDTO, options?: any) => {
    return this.textToSpeechApi.synthesize(this.accountId, ttsRequestDTO, options);
  };

  downloadAudio = (audioKey: string, options?: any) => {
    return this.textToSpeechApi.downloadAudio(this.accountId, audioKey, options);
  };

  getSpeechProviderList = (options?: any) => {
    return this.speechApi.getSpeechProviderList(this.accountId, options);
  };

  getAvailableProvidersForAccount = (options?: any) => {
    return this.availableProvidersAndVoicesApi.getAvailableProvidersForAccount(this.accountId, options);
  };

  getSuggestedVoicesForProvider = (options?: any) => {
    return this.availableProvidersAndVoicesApi.getSuggestedVoicesForProvider(options);
  };

  getDefaultProvidersForAccount = (language: string, options?: any) => {
    return this.availableProvidersAndVoicesApi.getDefaultProvidersForAccount(language, options);
  };

  getVoicesForProvider(providerKey: TextToSpeechProviderDetail, lang: string) {
    return (
      this.ttsVoices
        .find(tts_provider => tts_provider.provider === providerKey)
        ?.languages?.find(({ language }) => language?.startsWith(lang))?.voices || []
    );
  }
}
