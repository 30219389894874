import React, { Component } from 'react';
import Settings from '../Settings/Settings';
import User from '../User/User';
import ProjectSelector from '../../views/ProjectSelector';
import { JAICPLabel, JAICPLogo, TovieLabel, TovieLogo } from '../Logo';
import { isEuroInstance, isInternal, isLoggedInAccount } from '../../isAccessFunction';
import history from '../../appHistory';
import HelpMenu from '../HelpMenu';
import { TestPublishTrainWidget } from './TestPublishTrainWidget';
import { WSContext } from 'modules/Notifications/context/WSContext';
import './style.scss';
import { isArray } from 'lodash';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import { i18nTranslation } from 'modules/Caila/locale/i18nToLocalize';
import { CloudDomains } from '../../types';
import CloudMenu from '@just-ai/just-ui/dist/CloudMenu';
import { NavLink } from 'react-router-dom';

type Channel = {
  id: string;
};

const { t } = i18nTranslation('Channels');

type Props = {
  logoutFromAccount: () => Promise<unknown>;
  clearCurrentProject: () => Promise<unknown>;
  setChannelToPublish: (channelId: string) => Promise<unknown>;
  publishChannel: (channelId: string, skipTests?: boolean, runTestsInBackground?: boolean) => Promise<unknown>;
  addMessage: (message: AlertNotificationItemProps) => Promise<unknown>;
  projectShortName: string;
  appConfig: any;
  tariffData: any;
  language: string;
  channelList: Channel[];
  countryIsoCode: string;
  cloudDomains: CloudDomains;
  ccSessionsAreEnabled: boolean;
  universalLoginMenuEnabled: boolean;
  currentUser: any;
};

type State = {
  dropdownOpen: boolean;
};

export default class Header extends Component<Props, State> {
  static contextType = WSContext;

  constructor(props: Props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle(e: CustomEvent) {
    e.preventDefault();
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  logoutFromAcc = () => {
    const { currentUser } = this.props;
    if (currentUser.ccSession) {
      return (window.location.href = `/c/change-account?accountId=undefined&redirectUrl=${window.location.origin}/accounts`);
    }
    this.props.logoutFromAccount().then(() => {
      history.push('/accounts');
      this.props.clearCurrentProject();
    });
  };

  startDeploy = async (skipTests: boolean, runTestsInBackground: boolean) => {
    if (!this.props.channelList || !isArray(this.props.channelList)) return;

    if (this.props.channelList.length === 0) {
      this.props.addMessage({
        type: 'warning',
        title: t('noChannelsNotificationTitle'),
        message: t('noChannelsNotificationMessage'),
        time: Date.now(),
        showed: false,
      });
      return;
    }

    for (let channel of this.props.channelList) {
      await this.props.setChannelToPublish(channel.id);
      await this.props.publishChannel(channel.id, skipTests, runTestsInBackground);
    }
  };

  render() {
    const { appConfig, cloudDomains, universalLoginMenuEnabled } = this.props;

    return (
      <header className='app-header navbar' data-test-id='userHeader'>
        <NavLink className='navbar-brand' to='/' data-test-id='UserHeader.GoToProjects'>
          {isEuroInstance() ? (
            <TovieLogo width={32} height={32} logoUrl={appConfig.botadmin.logo} />
          ) : (
            <JAICPLogo width={32} height={32} logoUrl={appConfig.botadmin.logo} />
          )}
          {appConfig.botadmin.productName?.length > 0 && appConfig.botadmin.productName !== 'JAICP' ? (
            appConfig.botadmin.productName
          ) : isEuroInstance() ? (
            <TovieLabel height={24} width={103} />
          ) : (
            <JAICPLabel height={24} width={51} />
          )}
        </NavLink>
        <ul className='nav navbar-nav extra-nav'>
          <li className='nav-item px-3'>{isLoggedInAccount() || !isInternal() ? <ProjectSelector /> : null}</li>
        </ul>
        <ul className='nav navbar-nav ml-auto'>
          {Boolean(this.props.projectShortName) && this.props.projectShortName !== '' && (
            <>
              <li className='nav-item' id='test-publish-train-widget'>
                <TestPublishTrainWidget startDeploy={this.startDeploy} />
              </li>
              <li className='divider' />
            </>
          )}
          <li className='nav-item' id='tasks-list' />
          <li className='nav-item' id='notifications-list' />
          {appConfig.botadmin.showHelp && (
            <li className='nav-item'>
              <HelpMenu tariffData={this.props.tariffData} language={this.props.language} />
            </li>
          )}
          <li className='nav-item'>
            <Settings />
          </li>
          <li className='nav-item'>
            <User {...this.props} logoutFromAcc={this.logoutFromAcc} />
          </li>
          <CloudMenu
            countryIsoCode={this.props.countryIsoCode}
            universalLoginMenuEnabled={universalLoginMenuEnabled}
            cloudDomains={cloudDomains}
            translate={t}
            isEuroInstance={isEuroInstance()}
          />
        </ul>
      </header>
    );
  }
}
