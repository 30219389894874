import React, { useRef, useCallback } from 'react';
import cn from 'classnames';
import { IconButton, Popover, useToggle, useId, Button, ClickAwayListener } from '@just-ai/just-ui';

import localization, { t } from 'localization';

import { accountsAdminNotificationsLocalization } from '../../localization/accountsAdminNotifications.loc';
import Notification from './Notification';
import { useAccountAdminWSTasks } from './hook';

import styles from './styles.module.scss';

localization.addTranslations(accountsAdminNotificationsLocalization);

const NotificationBell = () => {
  const [notificationsOpened, , closeNotifications, toggleNotifications] = useToggle(false);
  const { tasks, onDeleteAllTasks, onDeleteTask, isAllTasksViewed, markTasksAsViewed } = useAccountAdminWSTasks({
    onAllTasksDeleted: closeNotifications,
  });
  const bellRef = useRef<HTMLButtonElement>(null);
  const popoverId = useId();

  const togglePopup = useCallback(() => {
    markTasksAsViewed();
    toggleNotifications();
  }, [markTasksAsViewed, toggleNotifications]);

  return (
    <div className={styles.NotificationBell}>
      <IconButton
        innerRef={bellRef}
        name='faBell'
        size='md'
        id={popoverId}
        withoutPadding
        data-test-id='Admin.Header.NotificationButton'
        onClick={togglePopup}
        className={cn(styles.bell, {
          [styles.bell_active]: !isAllTasksViewed,
        })}
      />
      <ClickAwayListener refs={[bellRef.current]} handleClickOut={closeNotifications}>
        <Popover
          className={styles.NotificationBell__popover}
          isOpen={notificationsOpened}
          target={popoverId}
          placement='bottom-end'
          title={t('BillingReportTask:Notification')}
          onCancelClick={closeNotifications}
        >
          <div className={styles.NotificationBell__content}>
            {tasks.map(task => (
              <Notification key={task.taskId} task={task} onDelete={onDeleteTask} />
            ))}
          </div>
          <div className={styles.NotificationBell__footer}>
            <Button color='secondary' flat iconLeft='farTrashAlt' onClick={onDeleteAllTasks}>
              {t('BillingReportTask:DeleteAllNotification')}
            </Button>
          </div>
        </Popover>
      </ClickAwayListener>
    </div>
  );
};

NotificationBell.displayName = 'NotificationBell';

export default React.memo(NotificationBell);
