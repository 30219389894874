import React, { useCallback, useState, useEffect, useMemo } from 'react';
import isAccess, { isSystemAccess, isReporterEnabled, isEuroInstance } from '../../isAccessFunction';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import localize from '../../localization';
import classNames from 'classnames';
import { Icon } from '@just-ai/just-ui/dist';
import IconCaila from './IconCaila';
import './Sidebar.scss';
import IconTovieNlp from './IconTovieNlp';

const t = localize.translate;

const Projected = ({
  totalTasksElements,
  strDialogsSearch,
  strClientsSearch,
  strLogsSearch,
  projectShortName,
  projectList,
  location,
  ...rest
}) => {
  const [links, setLinks] = useState({
    session: `/${projectShortName}/statistic/dialogs`,
    client: `/${projectShortName}/clientslist`,
    dashboard: `/${projectShortName}`,
    editor: `/${projectShortName}/editor`,
    visual: `/${projectShortName}/jgraph`,
    analytics_tags: `/${projectShortName}/analytics_tags`,
    reports: `/${projectShortName}/reports`,
  });
  const fullPath = useMemo(() => `${location.pathname}${location.search}`, [location.pathname, location.search]);

  const [cailaOpened, setCailaOpened] = useState(false);
  const [analyticOpened, setAnalyticOpened] = useState(false);
  const [editorOpened, setEditorOpened] = useState(fullPath.includes('/editor') || fullPath.includes('/jgraph'));

  const hideAll = useCallback(() => {
    setCailaOpened(false);
    setAnalyticOpened(false);
    setEditorOpened(false);
  }, []);

  const currentProject = projectList.find(project => project.shortName === projectShortName);

  useEffect(() => {
    if (fullPath.includes('statistic/dialogs?') && fullPath.includes(projectShortName)) {
      setLinks(prevLinks => ({ ...prevLinks, session: fullPath }));
    } else if (fullPath.includes('/editor?') && fullPath.includes(projectShortName)) {
      setLinks(prevLinks => ({
        ...prevLinks,
        editor: fullPath,
      }));
    } else if (fullPath.includes('/clientslist?') && fullPath.includes(projectShortName)) {
      setLinks(prevLinks => ({ ...prevLinks, client: fullPath }));
    } else if (location.pathname === `/${projectShortName}`) {
      setLinks(prevLinks => ({ ...prevLinks, dashboard: fullPath }));
    }
  }, [fullPath, location.pathname, projectShortName]);

  const isMinimized = window.localStorage.getItem('SIDEBAR_MIN') === 'true';

  useEffect(() => {
    if (isMinimized) hideAll();
  }, [hideAll, isMinimized]);

  const toggleCaila = useCallback(() => {
    setCailaOpened(x => !x);
    setAnalyticOpened(false);
  }, [setCailaOpened, setAnalyticOpened]);

  const toggleAnalytic = useCallback(() => {
    setAnalyticOpened(x => !x);
    setCailaOpened(false);
  }, [setAnalyticOpened, setCailaOpened]);

  const toggleEditor = useCallback(() => {
    setAnalyticOpened(false);
    setCailaOpened(false);
    setEditorOpened(x => !x);
  }, [setAnalyticOpened, setCailaOpened]);

  useEffect(() => {
    const onClickOutside = e => {
      if (!isMinimized) return;
      const target = e.target;
      const dropdownItems = document.querySelector('.nav-item.nav-dropdown.open>.nav-dropdown-items');
      const openedList = document.querySelector('.nav-item.nav-dropdown.open');
      if (dropdownItems && openedList && !dropdownItems.contains(target) && !openedList.contains(target)) {
        hideAll();
      }
    };
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  }, [hideAll, isMinimized]);

  const handlePopoverMenuClick = useCallback(() => {
    if (isMinimized) hideAll();
  }, [hideAll, isMinimized]);

  return (
    <nav className='sidebar-nav projectedSidebar'>
      <ul className='nav'>
        {isAccess('GENERAL_ANALYTICS_READ') && (
          <li className='nav-item'>
            <NavLink
              exact
              to={links.dashboard}
              className='nav-link'
              activeClassName='active'
              data-test-id='projectedSidebar.overviewNavLink'
              onClick={hideAll}
            >
              <Icon name='faTable' size='md' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{t('Project overview')}</span>
              <div className='sidebar-tooltip'>{t('Project overview')}</div>
            </NavLink>
          </li>
        )}

        <li className='nav-item'>
          <NavLink
            to={`/${projectShortName}/channels`}
            className='nav-link'
            activeClassName='active'
            data-test-id='projectedSidebar.channelsNavLink'
            onClick={hideAll}
          >
            <Icon name='faRssSquare' size='md' wrapperClassName='visible_icon' />
            <span className='nav-item-name'>{t('Channels')}</span>
            <div className='sidebar-tooltip'>{t('Channels')}</div>
          </NavLink>
        </li>

        {((!currentProject?.jaicfProject && isAccess(['BOT_CONTENT_READ'])) ||
          (isAccess(['BOT_CONTENT_READ', 'framework.cloud']) &&
            Boolean(currentProject?.environment) &&
            currentProject.environment !== 'external')) && (
          <>
            <li
              className={classNames('nav-item nav-dropdown', {
                open: editorOpened,
              })}
            >
              <div
                data-test-id='projectedSidebar.editorNavLink'
                className={classNames('nav-link link-toggle-shevron', { active: editorOpened })}
                onClick={toggleEditor}
              >
                <div>
                  <Icon name='faCode' size='md' wrapperClassName='visible_icon' />
                  <span>{t('Editor')}</span>
                  <div className='sidebar-tooltip'>{t('Editor')}</div>
                </div>
                <Icon name='farChevronDown' size='sm' />
              </div>
              <ul className='nav-dropdown-items' style={{ zIndex: 10000 }}>
                {isAccess('CODE_READ') && (
                  <li className='nav-item'>
                    <NavLink
                      to={links.editor}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.codeEditorLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span className='nav-item-name'>{t('Sidebar:CodeEditor')}</span>
                    </NavLink>
                  </li>
                )}
                <li className='nav-item'>
                  <NavLink
                    to={links.visual}
                    className='nav-link'
                    activeClassName='active'
                    data-test-id='projectedSidebar.visualEditorLink'
                    onClick={handlePopoverMenuClick}
                  >
                    <span className='nav-item-name'>{t('Sidebar:VisualEditor')}</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </>
        )}

        <li className='nav-item'>
          <NavLink
            to={`/${projectShortName}/tokens`}
            className='nav-link'
            activeClassName='active'
            data-test-id='projectedSidebar.tokensNavLink'
            onClick={hideAll}
          >
            <Icon name='faLockAlt' size='md' wrapperClassName='visible_icon' />
            <span className='nav-item-name'>{t('TokensPage:TokensAndVariables')}</span>
            <div className='sidebar-tooltip'>{t('TokensPage:TokensAndVariables')}</div>
          </NavLink>
        </li>

        {isAccess(['BOT_CONTENT_READ', 'nlu']) && isSystemAccess('knowledge_base') && rest.cailaEnabled && (
          <li className='nav-item'>
            <NavLink
              to={`/${projectShortName}/faq`}
              className='nav-link'
              activeClassName='active'
              data-test-id='projectedSidebar.faqTemplate'
              onClick={hideAll}
            >
              <Icon name='faBookSpells' size='md' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{t('FAQ:FAQBot')}</span>
              <div className='sidebar-tooltip'>{t('FAQ:FAQBot')}</div>
            </NavLink>
          </li>
        )}

        {isAccess(['NLU_READ', 'nlu']) && rest.cailaEnabled && (
          <li
            className={classNames('nav-item nav-dropdown', {
              open: cailaOpened,
            })}
          >
            <div
              data-test-id='projectedSidebar.caila'
              className={classNames('nav-link link-toggle-shevron', { active: cailaOpened })}
              onClick={toggleCaila}
            >
              <div>
                {isEuroInstance() ? <IconTovieNlp /> : <IconCaila />}
                <span>{t('Sidebar caila menu item', t(isEuroInstance() ? 'Tovie NLP' : 'CAILA'))}</span>
                <div className='sidebar-tooltip'>
                  {t('Sidebar caila menu item', t(isEuroInstance() ? 'Tovie NLP' : 'CAILA'))}
                </div>
              </div>
              <Icon name='farChevronDown' size='sm' />
            </div>
            <ul className='nav-dropdown-items' style={{ zIndex: 10000 }}>
              <li className='nav-item'>
                <NavLink
                  data-test-id='projectedSidebar.intents'
                  to={`/${projectShortName}/intents`}
                  className='nav-link'
                  activeClassName='active'
                  onClick={handlePopoverMenuClick}
                >
                  <span className='nav-item-name'>{t('intents page')}</span>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  data-test-id='projectedSidebar.entities'
                  to={`/${projectShortName}/entities`}
                  className='nav-link'
                  activeClassName='active'
                  onClick={handlePopoverMenuClick}
                >
                  <span className='nav-item-name'>{t('entities page')}</span>
                </NavLink>
              </li>
              {isAccess(['BOT_CONTENT_READ', 'nlu', 'nlu.log_labeling', 'NLU_WRITE']) && rest.logLabelingEnabled ? (
                <li className='nav-item'>
                  <NavLink
                    to={`/${projectShortName}/log-labeling`}
                    className='nav-link'
                    activeClassName='active'
                    data-test-id='projectedSidebar.logLabeling'
                    onClick={handlePopoverMenuClick}
                  >
                    <span className='nav-item-name'>{t('DataLabeling')}</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </li>
        )}

        {isAccess(['DIALER_CALL_TASK_READ', 'dialer.feature']) && rest.dialerEnabled && (
          <li className='nav-item'>
            <NavLink
              to={`/${projectShortName}/calltasklist`}
              className='nav-link'
              activeClassName='active'
              data-test-id='projectedSidebar.callTasksNavLink'
              onClick={hideAll}
            >
              <Icon name='faPhoneVolume' size='md' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{t('Call tasks')}</span>
              <div className='sidebar-tooltip'>{t('Call tasks')}</div>
            </NavLink>
          </li>
        )}
        {isAccess('DIALER_CALL_TASK_READ') && (
          <li className='nav-item'>
            <NavLink
              to={`/${projectShortName}/mailingtasks`}
              className='nav-link'
              activeClassName='active'
              data-test-id='projectedSidebar.MailingTasksNavLink'
              onClick={hideAll}
            >
              <Icon name='farEnvelope' size='md' wrapperClassName='visible_icon' />
              <span className='nav-item-name'>{t('Mailing tasks')}</span>
              <div className='sidebar-tooltip'>{t('Mailing tasks')}</div>
            </NavLink>
          </li>
        )}

        {(isAccess('CLIENT_ANALYTICS_READ') || isAccess('GENERAL_ANALYTICS_READ')) &&
          isSystemAccess(['botadmin_dialogs_feature']) && (
            <li className={classNames('nav-item nav-dropdown', { open: analyticOpened })}>
              <div
                data-test-id='projectedSidebar.analytic'
                className={classNames('nav-link link-toggle-shevron', { active: analyticOpened })}
                onClick={toggleAnalytic}
              >
                <div>
                  <Icon name='faChartBar' size='md' wrapperClassName='visible_icon' />
                  <span>{t('Analytics')}</span>
                  <div className='sidebar-tooltip'>{t('Analytics')}</div>
                </div>
                <Icon name='farChevronDown' size='sm' />
              </div>
              <ul className='nav-dropdown-items' style={{ zIndex: 10000 }}>
                {isAccess('new-analytics') && (
                  <li className='nav-item'>
                    <NavLink
                      to={links.reports}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.reportsNavLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span>{t('Reports')}</span>
                    </NavLink>
                  </li>
                )}

                <li className='nav-item'>
                  <NavLink
                    to={links.client}
                    className='nav-link'
                    activeClassName='active'
                    data-test-id='projectedSidebar.clientsNavLink'
                    onClick={handlePopoverMenuClick}
                  >
                    <span>{t('Clients')}</span>
                  </NavLink>
                </li>

                {isAccess(['pageflow.feature']) && (
                  <li className='nav-item'>
                    <NavLink
                      to={`/${projectShortName}/statistic/pageflow`}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.pageFlowNavLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span className='nav-item-name'>{t('Page flow')}</span>
                    </NavLink>
                  </li>
                )}
                {isAccess(['GENERAL_ANALYTICS_READ', 'dialogs.analytics.feature']) &&
                isSystemAccess(['botadmin_dialogs_feature']) ? (
                  <li className='nav-item'>
                    <NavLink
                      to={links.session}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.dialogsNavLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span className='nav-item-name'>{t('Dialogs')}</span>
                    </NavLink>
                  </li>
                ) : null}

                {isAccess('new-analytics') && (
                  <li className='nav-item'>
                    <NavLink
                      to={links.analytics_tags}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.analyticsTagsNavLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span>{t('Analytics tags')}</span>
                    </NavLink>
                  </li>
                )}

                {isReporterEnabled() && (
                  <li className='nav-item'>
                    <NavLink
                      to={`/${projectShortName}/experiments`}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.experimentsNavLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span className='nav-item-name'>{t('Sidebar experiments menu item')}</span>
                    </NavLink>
                  </li>
                )}
                {isAccess(['GENERAL_ANALYTICS_READ', 'dialogs.analytics.feature']) ? (
                  <li className='nav-item'>
                    <NavLink
                      to={`/${projectShortName}/statistic/tasks`}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.tasksNavLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span className='nav-item-name'>
                        {t('Tasks')}
                        <Badge data-test-id='projectedSidebar.taskCounter' color='primary'>
                          {totalTasksElements}
                        </Badge>
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {isSystemAccess(['botadmin_telephony_feature']) && isReporterEnabled() && (
                  <li className='nav-item'>
                    <NavLink
                      to={`/${projectShortName}/statistic/telephony`}
                      className='nav-link'
                      activeClassName='active'
                      data-test-id='projectedSidebar.telephonyNavLink'
                      onClick={handlePopoverMenuClick}
                    >
                      <span className='nav-item-name'>{t('TelephonyPage')}</span>
                    </NavLink>
                  </li>
                )}
                <li className='nav-item'>
                  <NavLink
                    to={`/${projectShortName}/statistic/operator`}
                    className='nav-link'
                    activeClassName='active'
                    data-test-id='projectedSidebar.operatorNavLink'
                    onClick={handlePopoverMenuClick}
                  >
                    <span className='nav-item-name'>{t('Transferred to agent')}</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
      </ul>
    </nav>
  );
};

export default Projected;
