export const projectGroupLocalization = {
  'ProjectGroupSelector:DefaultGroup': {
    eng: 'My projects',
    ru: 'Мои проекты',
  },
  'ProjectGroupSelector:CreateGroup': {
    eng: 'Create a group',
    ru: 'Создать группу',
  },
  'ProjectGroupSelector:CreateGroupModal:Title': {
    eng: 'Create a group',
    ru: 'Создать группу',
  },
  'ProjectGroupSelector:CreateGroupModal:Name:Label': {
    eng: 'Group name',
    ru: 'Название группы',
  },
  'ProjectGroupSelector:CreateGroupModal:Button:Submit': {
    eng: 'Create',
    ru: 'Создать',
  },
  'ProjectGroupSelector:CreateGroupModal:Button:Cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  'ProjectGroupSelector:InvitedToGroupBadge': {
    eng: 'You were invited',
    ru: 'Вас пригласили',
  },
  'ProjectGroupSelector:CreateGroupModal:Error:required.field': {
    eng: 'Required field',
    ru: 'Обязательное поле',
    pt: 'Campo obrigatório',
    cn: 'Required field',
  },
  'ProjectGroupSelector:CreateGroupModal:Error:common.validation.constraints.Pattern': {
    eng: 'Invalid characters',
    ru: 'Недопустимые символы',
  },
  'ProjectGroupSelector:LeaveGroup': {
    eng: 'Leave the group',
    ru: 'Выйти из группы',
  },
};
