import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import localize, { t } from '../../localization';
import * as ProjectsActions from '../../actions/projects.actions';
import { Icon, JustSelect } from '@just-ai/just-ui';
import { projectGroupLocalization } from '../../modules/ProjectGroups/localization/projectGroup.loc';
import { ProjectGroupsContext } from '../../modules/ProjectGroups/context';
import { Link } from 'react-router-dom';
import { isInternal } from '../../isAccessFunction';

localize.addTranslations(projectGroupLocalization);

class ProjectSelector extends Component {
  static contextType = ProjectGroupsContext;

  selectProject = selectedOption => {
    this.props.actions.setCurrentProject(selectedOption[0], false, false, true);
  };

  render() {
    const { projectList = [] } = this.props;
    const { projectShortName: currentProjectShortName, currentProjectGroupObject } = this.context;

    const projectSelectOptions = projectList.map(project => {
      return { label: project.name, value: project.shortName };
    });

    const index = projectList.findIndex(project => {
      return project.shortName === currentProjectShortName;
    });

    const selectedProjectOption = projectSelectOptions[index];

    return (
      <div className='project__selector'>
        <Link
          to='/'
          data-test-id='ProjectSelector.link'
          style={window.location.pathname === '/' ? { cursor: 'default' } : undefined}
        >
          <Icon name='farFolder' />{' '}
          {currentProjectGroupObject?.default || isInternal()
            ? t('ProjectGroupSelector:DefaultGroup')
            : currentProjectGroupObject?.name}
        </Link>
        <span className='project__selector-separator'>/</span>
        <JustSelect
          inputPlaceholder={t('Select project')}
          options={projectSelectOptions}
          isClearable
          messages={{
            NOT_FOUND: t('No matches'),
            EMPTY: t('No matches'),
          }}
          classNamePrefix='header-select'
          className='react-select project-select__header'
          value={selectedProjectOption?.value ?? ''}
          onChange={this.selectProject}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectList: state.ProjectsReducer.projectList,
    currentProjectShortName: state.CurrentProjectsReducer.currentProject,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ProjectsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelector);
