import { useMemo, useState, useEffect, useCallback } from 'react';
import { LogService } from './LogService';
import { LogItem, LoggerEnum, LevelEnum, TimeEnum } from '../../model/LogItem';
import { Filter } from './Filter';
import { useToggle } from '../../../Caila/utils';
import { useAppContext } from 'modules/Caila/components/AppContext';

const MAX_LOG_ITEMS = 1000;
const LOG_REQUEST_TIMEOUT = 1000;
const STREAM_REQUEST_TIMEOUT = 20000;

export const useLogs = () => {
  const [streamId, setStreamId] = useState<string>();
  const [isFetchingStreamId, setFetchingStreamId] = useState(false);
  const [isFetchLogsFailed, setFetchLogsFailed] = useState(false);

  useEffect(() => {
    if (!isFetchLogsFailed) return;

    const timeoutId = setTimeout(() => {
      setFetchingStreamId(false);
      setFetchLogsFailed(false);
    }, STREAM_REQUEST_TIMEOUT);

    return () => clearTimeout(timeoutId as NodeJS.Timeout);
  }, [isFetchLogsFailed, isFetchingStreamId]);

  const { projectShortName } = useAppContext();
  const logService = useMemo(() => new LogService(projectShortName), [projectShortName]);

  const fetchStreamId = useCallback(() => {
    if (!projectShortName) {
      setStreamId(undefined);
      return;
    }
    logService.getStreamId(projectShortName).then(setStreamId);
  }, [logService, projectShortName]);

  const onErrorFetchLogs = useCallback(() => {
    setFetchLogsFailed(true);
    if (isFetchingStreamId) return;
    setFetchingStreamId(true);
    fetchStreamId();
  }, [fetchStreamId, isFetchingStreamId]);

  const [logItems, setLogItems] = useState<LogItem[]>([]);
  const [filteredLogItems, setFilteredLogItems] = useState<LogItem[]>([]);
  const [pendingLogItems, setPendingLogItems] = useState<LogItem[]>([]);

  const [filter, setFilter] = useState(() => new Filter());

  const [isFetching, startFetching, stopFetching] = useToggle(false);
  const [isPromisePending, setPromisePending] = useState(false);

  useEffect(fetchStreamId, [fetchStreamId]);

  const fetchLogs = useCallback(() => {
    if (!streamId || isPromisePending) return;
    setPromisePending(true);
    logService
      .fetchLogs(streamId)
      .then(newLogItems => setPendingLogItems(pendingLogItems.concat(newLogItems)))
      .catch(onErrorFetchLogs)
      .finally(() => setPromisePending(false));
  }, [isPromisePending, logService, onErrorFetchLogs, pendingLogItems, streamId]);

  useEffect(() => {
    if (!isFetching) return;
    fetchLogs();
  }, [fetchLogs, isFetching, streamId]);

  useEffect(() => {
    const addLogItems = (newLogItems: LogItem[]) => {
      setLogItems(logItems.concat(newLogItems).slice(-MAX_LOG_ITEMS));
      setFilteredLogItems(filteredLogItems.concat(filter.apply(newLogItems)).slice(-MAX_LOG_ITEMS));
    };

    if (isFetching && pendingLogItems.length > 0) {
      addLogItems(pendingLogItems);
      setPendingLogItems([]);
    }
  }, [pendingLogItems, isFetching, logItems, filteredLogItems, filter]);

  useEffect(() => {
    if (isFetching && !isPromisePending) {
      const timeoutId = setTimeout(() => fetchLogs(), LOG_REQUEST_TIMEOUT);
      return () => clearTimeout(timeoutId);
    }
  }, [isPromisePending, isFetching, fetchLogs]);

  const clear = () => {
    setLogItems([]);
    setFilteredLogItems([]);
  };

  const [textFilter, setTextFilter] = useState('');
  const [selectedLoggers, selectLoggers] = useState<LoggerEnum[]>([]);
  const [selectedLevel, selectLevel] = useState<LevelEnum>();
  const [selectedTime, selectTime] = useState<TimeEnum>();

  useEffect(() => {
    const newFilter = new Filter(textFilter, selectedLoggers, selectedLevel, selectedTime);
    setFilter(newFilter);
    setFilteredLogItems(newFilter.apply(logItems));
  }, [textFilter, selectedLoggers, selectedLevel, selectedTime, logItems]);

  return {
    logItems: filteredLogItems,
    isFetching,
    startFetching,
    stopFetching,
    clear,
    textFilter,
    setTextFilter,
    selectedLevel,
    selectLevel,
    selectedLoggers,
    selectLoggers,
    selectedTime,
    selectTime,
  };
};
