export const defaultHelpUrlsPathLocalization = {
  'available channels link': {
    eng: '/docs/en/channels/channels',
    ru: '/docs/ru/channels/channels',
  },
  'Entities docs link': {
    eng: '/docs/en/platform_ux/nlu_core/entities',
    ru: '/docs/ru/platform_ux/nlu_core/entities',
  },
  'Spelling correction link': {
    eng: '/docs/en/platform_ux/nlu_core/entities/#spelling-correction',
    ru: '/docs/ru/platform_ux/nlu_core/spellcheck',
  },
  'NLUSettings help link': {
    eng: '/docs/en/NLU_core/advanced_classifer_settings',
    ru: '/docs/ru/NLU_core/advanced_classifer_settings',
  },
  'tooltip calls link': {
    eng: '/docs/en/telephony/telephony',
    ru: '/docs/ru/telephony/telephony',
  },
  'tooltip callsApi link': {
    eng: '/docs/en/telephony/calls_api/calls_api',
    ru: '/docs/ru/telephony/calls_api/calls_api',
  },
  'tooltip nluApi link': {
    eng: '/docs/en/NLU_core/NLP_direct_API/NLP_direct_API',
    ru: '/docs/ru/NLU_core/NLP_direct_API/NLP_direct_API',
  },
  'Header:GithubTokenBanner.linkHref': {
    eng: '/docs/en/security/github_access_token',
    ru: '/docs/ru/security/github_access_token',
  },
  'ProjectOverview: JAICP link': {
    eng: '/docs/en/platform_ux/results',
    ru: '/docs/ru/platform_ux/results',
  },
  'Widget:spellingPopoverLink': {
    eng: '/docs/en/platform_ux/nlu_core/entities/#spelling-correction',
    ru: '/docs/ru/platform_ux/nlu_core/spellcheck',
  },
  'ProjectOverview: noInfo client helpLink': {
    eng: '/docs/en/platform_ux/volume',
    ru: '/docs/ru/platform_ux/volume',
    cn: '/docs/en/platform_ux/volume',
  },
  'ProjectOverview: noInfo helpLink clients': {
    eng: '/docs/en/platform_ux/volumes',
    ru: '/docs/ru/platform_ux/volumes',
    cn: '/docs/en/platform_ux/volumes',
  },
  'ProjectOverview: noInfo helpLink results': {
    eng: '/docs/en/platform_ux/results',
    ru: '/docs/ru/platform_ux/results',
    cn: '/docs/en/platform_ux/results',
  },
  'ProjectOverview: tooltip results button link': {
    eng: '/docs/en/JS_API/built_in_services/analytics/setSessionResult',
    ru: '/docs/ru/JS_API/built_in_services/analytics/setSessionResult',
    cn: '/docs/en/JS_API/built_in_services/analytics/setSessionResult',
  },
  'Analytic:Experiments modal main label documentation link': {
    eng: '/docs/en/platform_ux/experiments',
    ru: '/docs/ru/platform_ux/experiments',
    cn: '/docs/en/platform_ux/experiments',
  },
  'Logs:LogsMasking.EditRule.ruleHelpLinkHref': {
    eng: '/docs/en/security/log_obfuscation',
    ru: '/docs/ru/security/log_obfuscation',
  },
  'ImportModal:helpLink-intents': {
    eng: '/docs/en/platform_ux/nlu_core/intents_export_import',
    ru: '/docs/ru/platform_ux/nlu_core/intents_export_import',
  },
  'ImportModal:helpLink-entities': {
    eng: '/docs/en/platform_ux/nlu_core/intents_export_entities',
    ru: '/docs/ru/platform_ux/nlu_core/intents_export_entities',
  },
  'ImportModal:helpLink-dictionary': {
    eng: '/docs/en/common/bot_structure/csv',
    ru: '/docs/ru/common/bot_structure/csv',
  },
  'tasks modal help link editorbe.deploy.bot': {
    eng: '/docs/en/channels/channels',
    ru: '/docs/ru/channels/channels',
  },
  'tasks modal help link editorbe.deploy.bot.info': {
    eng: '/docs/en/channels/channels',
    ru: '/docs/ru/channels/channels',
  },
  'UserTariffLimits popover btn link uniques': {
    eng: '/docs/en/account_management/plan/#unique-users',
    ru: '/docs/ru/account_management/plan#unique-users',
  },
  'UserTariffLimits popover btn link nlu': {
    eng: '/docs/en/account_management/plan/#telephony-nlu',
    ru: '/docs/ru/account_management/plan/#telephony-nlu',
    cn: '/docs/ru/account_management/plan/#telephony-nlu',
  },
  'UserTariffLimits popover btn link asr': {
    eng: '/docs/en/account_management/plan/#asr-tts',
    ru: '/docs/ru/account_management/plan/#asr-tts',
  },
  'UserTariffLimits popover btn link telephony': {
    eng: '/docs/en/telephony/telephony_setup/#creating-a-connection',
    ru: '/docs/ru/telephony/telephony_setup',
    cn: '/docs/ru/telephony/telephony_setup',
  },
  'UserTariffLimits popover btn link cailarequests': {
    eng: '/docs/en/NLU_core/NLP_direct_API/NLP_direct_API',
    ru: '/docs/ru/NLU_core/NLP_direct_API/NLP_direct_API',
    cn: '/docs/ru/NLU_core/NLP_direct_API/NLP_direct_API',
  },
  'UserTariffLimits popover btn link cailarequests_tovie': {
    eng: '/docs/en/NLU_core/CAILA_direct_API/CAILA_direct_API',
    ru: '/docs/ru/NLU_core/CAILA_direct_API/CAILA_direct_API',
    cn: '/docs/ru/NLU_core/CAILA_direct_API/CAILA_direct_API',
  },
  'CustomAsrTtsProviderSettings:token.link': {
    eng: '/docs/en/telephony/own_telephony/#how-to-connect-{{name}}-asrtts',
    ru: '/docs/ru/telephony/own_telephony/#Как-подключить-asrtts-{{name}}',
  },
  'CustomAsrTtsProviderSettings:tinkoff.token.link': {
    eng: '/docs/en/telephony/own_telephony/#how-to-connect-{{name}}-asr',
    ru: '/docs/ru/telephony/own_telephony/#Как-подключить-asr-{{name}}',
  },
  'CreateProject:tooltip-button-link': {
    eng: '/docs/en/platform_ux/create-project',
    ru: '/docs/ru/platform_ux/create-project',
  },
  'ProjectsDashboard: help links tutorial link': {
    eng: '/docs/en/common/tutorials/simple_bot/simple_bot',
    ru: '/docs/ru/common/tutorials/simple_bot/simple_bot',
  },
  'ProjectsDashboard: help links course link': {
    eng: '/docs/en/common/tutorials/video_course/video_course',
    ru: '/docs/ru/common/tutorials/video_course/video_course',
  },
  'caila project language tooltip link': {
    eng: '/docs/en/NLU_core/nlu_core',
    ru: '/docs/ru/NLU_core/nlu_core',
  },
  'ProjectEditForm helpLink': {
    eng: '/docs/en/platform_ux/create-project',
    ru: '/docs/ru/platform_ux/create-project',
  },
  'ProjectEditForm helpLink JAICF': {
    eng: '/docs/en/platform_ux/jaicf/project_jaicf',
    ru: '/docs/ru/platform_ux/jaicf/project_jaicf',
  },
  'ProjectEditForm instruction link': {
    eng: '/docs/en/platform_ux/guest_access',
    ru: '/docs/ru/platform_ux/guest_access',
  },
  'ProjectEditForm:GithubTokenWarning.linkHref': {
    eng: '/docs/en/security/github_access_token',
    ru: '/docs/ru/security/github_access_token',
    cn: '/docs/en/security/github_access_token',
  },
  'MailingList: help link': {
    eng: '/docs/en/telephony/lists_of_numbers',
    ru: '/docs/ru/telephony/lists_of_numbers',
  },
  'FAQ:activationNotification:url': {
    eng: '/docs/en/knowledge-base/faq/#connect-faq-using-global-intentgroup-tag',
    ru: '/docs/ru/knowledge-base/faq/#connect-faq-using-global-intentgroup-tag',
  },
  'AnswerModal:info_link': {
    eng: '/docs/en/channels/restrictions',
    ru: '/docs/ru/channels/restrictions',
  },
  'AnswerBlocks:question_tooltip_link': {
    eng: '/docs/en/knowledge-base/faq/#phrases',
    ru: '/docs/ru/knowledge-base/faq/#phrases',
  },
  'AnswerBlocks:answer_tooltip_link': {
    eng: '/docs/en/channels/restrictions',
    ru: '/docs/ru/channels/restrictions',
  },
  'SummaryEdit:faq_link': {
    eng: '/docs/en/knowledge-base',
    ru: '/docs/ru/knowledge-base',
  },
  'SummaryEdit:answer_link': {
    eng: '/docs/en/knowledge-base/faq/#answer',
    ru: '/docs/ru/knowledge-base/faq/#answer',
  },
  'LLTopPanel:docsLink_url': {
    eng: '/docs/en/platform_ux/nlu_core/dataset-creation/data-labeling',
    ru: '/docs/ru/platform_ux/nlu_core/dataset-creation/data-labeling',
  },
  'LogLabelingPage:spellcheck_url': {
    eng: '/docs/en/platform_ux/nlu_core/entities/#spelling-correction',
    ru: '/docs/ru/platform_ux/nlu_core/spellcheck',
  },
  'FAQ:cdqaModal:linkUrl': {
    eng: '/docs/en/knowledge-base/cdqa',
    ru: '/docs/ru/knowledge-base/cdqa',
  },
  'ProjectEditConfig:NLU:ClassificationAlgorithm:dockLink:Link': {
    eng: '/docs/en/platform_ux/nlu_core/dataset-creation/how-to-train-intents/#difference-between-classifier-algorithms',
    ru: '/docs/ru/platform_ux/nlu_core/dataset-creation/how-to-train-intents/#difference-between-classifier-algorithms',
  },
  'AnswerSettings:LinkToFormattingDocs': {
    eng: '/docs/en/visual-editor/script-development/reactions/text',
    ru: '/docs/ru/visual-editor/script-development/reactions/text',
  },
};
