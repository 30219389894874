export const howToCreateCustomTagLocalization = {
  'HowToCreateCustomTag:boldText': {
    eng: 'How to create your own action',
    ru: 'Как создать своё действие?',
  },
  'HowToCreateCustomTag:text': {
    eng: 'Find out from the <a href="$[1]" rel="noopener noreferrer" target="_blank">documentation</a>',
    ru: 'Узнайте из <a href="$[1]" rel="noopener noreferrer" target="_blank">документации</a>',
  },
  'HowToCreateCustomTag:linkToDock': {
    eng: '/docs/en/dsl/action-tags/create-own-tags',
    ru: '/docs/ru/dsl/action-tags/create-own-tags',
  },
};
